import React from "react";
import propTypes from "prop-types";
import { CONNECTION_STATES } from "constants/index";
import { ICON_TYPE, MessageWithIcon } from "components/Icon";

/**
 * SignalNotice
 *
 * @param {Object} teamData
 */
const SquadSignalNotice = ({ teamData }) => {
  const githubApplicationConnection = teamData?.organization?.githubApplicationConnection;
  const hasRepos = teamData?.gitRepositories && teamData.gitRepositories.split(",").length > 0;

  if (!githubApplicationConnection || githubApplicationConnection?.status === CONNECTION_STATES.suspended) {
    return <MessageWithIcon messageId="Squads.SquadSignalNotice.Error" iconType={ICON_TYPE.close} isError />;
  } else if (!hasRepos) {
    return <MessageWithIcon messageId="Squads.SquadSignalNotice.Warning" iconType={ICON_TYPE.checkmark} />;
  } else {
    return <MessageWithIcon messageId="Squads.SquadSignalNotice.Success" iconType={ICON_TYPE.checkmark} isSuccess />;
  }
};

SquadSignalNotice.propTypes = {
  teamData: propTypes.object.isRequired,
};

export default SquadSignalNotice;
