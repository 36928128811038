import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { NarrowContainer, Row } from "components/Containers";
import { breakpoints, colors } from "style";
import { Link } from "components/Links";
import { LINKS } from "constants/index";
import { MarketingSecondaryButton } from "components/Buttons";
import { MarketingLogo } from "components/Logo";
import Menu from "components/Menu";
import { useAuth } from "hooks";

/**
 * PublicHeader
 * 
 * @param {Array} pages
 */
const PublicHeader = ({ pages, children }) => {
  const { isAuthenticated } = useAuth();

  return (
    <div css={styles.container}>
      <NarrowContainer>
        <Row css={styles.header}>
          <Link to={LINKS.website}>
            <MarketingLogo />
          </Link>
          {children}
          {!isAuthenticated ? (
            <Row gap={`4rem`} css={styles.link_container}>
              <Link to={LINKS.signup} css={styles.join}>
                <FormattedMessage id="Missions.Roles.Public.PublicHeader.JoinLink" />            
              </Link>
              <Link to={LINKS.login}>
                <MarketingSecondaryButton>
                  <FormattedMessage id="Missions.Roles.Public.PublicHeader.LoginLink" />            
                </MarketingSecondaryButton>
              </Link>
            </Row>
          ) : (
            <Menu pages={pages} />
          )}
        </Row>
      </NarrowContainer>
    </div>
  );
}

const styles = {
  container: css`
    background: ${colors.white};
    padding: 2rem 0;    
  `,
  link_container: css`
    @media (max-width: ${breakpoints.mobile}) {
      gap: 1rem;
    }
  `,
  header: css`
    align-items:center;
    height:5rem;
    justify-content: space-between;
    position:relative;
    z-index:3;
  `,
  join: css`
    color: ${colors.grayAnatomyDark};
    font-size: 1.5rem; // for consistency with website
    font-weight:500;
  `
};

PublicHeader.propTypes = {
  pages: PropTypes.array,
  children: PropTypes.node,
};

export default PublicHeader;
