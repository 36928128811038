import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { convertToPercent, formatCurrency, formatDate, formatNumber } from "utils";
import Status from "components/Status";
import { APPLICANT_TYPES, AVAILABILITIES, CAPACITY_STATUS, MEMBER_STATUS, USER_TYPES } from "constants/index";
import { UserArchetype, UserCapacity, UserFullName, UserLocation, UserPhoto } from "components/User";
import { ProfileLink, ProfileRateRange } from "components/Profile";
import Score from "components/Score";
import { RoleLink } from "components/Missions/Roles";
import { MatchScoreDisplay, RateScoreDisplay, ScoreDisplay } from "components/Missions/Applicants";
import { ActionButton } from "components/Buttons";
import { colors, fontSize } from "style";
import { Col, Row } from "components/Containers";
import Tooltip from "components/Tooltip";
import Tags, { Tag, TAG_SIZE } from "components/Tags";
import Overlay from "components/Overlay";
import { PopoverCard } from "components/ActivityLog";

const APPLICANT_LIST_COLUMNS = {
  member: ({ showDataFormattedName }) => ({
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnMember" />,
    width: "20%",
    cell: (row) => (
      <Row noWrap>
        <UserPhoto data={row?.profile} width="3rem" height="3rem" />
        <Col>
          <ProfileLink profile={row?.profile} dataFormatted={showDataFormattedName} withOverlay />
          <UserLocation data={row?.profile} />
        </Col>
      </Row>
    ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client, USER_TYPES.freelancer],
      states: [
        null,
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  }),
  status: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnStatus" />,
    width: "10%",
    cell: (row) => (
      <Tag size={TAG_SIZE.medium} color={getTagColorForProfileStatus(row?.profile?.status)}>
        <FormattedMessage id={`Missions.MissionCandidateList.ColumnProfileStatus.${row?.profile?.status}`} />
      </Tag>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  memberWithDisciplines: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnMember" />,
    width: "25%",
    cell: (row) => (
      <Row noWrap>
        <UserPhoto data={row?.profile} width="5rem" height="5rem" showPartnerName />
        <Col>
          <ProfileLink profile={row?.profile} withOverlay />
          <Col gap="1rem">
            <Row noWrap>
              <div css={styles.archetype}>
                <UserArchetype data={row?.profileData} />
              </div>
            </Row>
            <Row noWrap>
              <Tags
                size={TAG_SIZE.small}
                data={row?.profileData?.disciplines?.map((discipline) => ({ name: discipline }))}
                limit={2}
              />
            </Row>
          </Col>
        </Col>
      </Row>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [APPLICANT_TYPES.recommended],
    },
  },
  location: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnLocation" />,
    width: "10%",
    cell: ({ profileData }) =>
      profileData?.country || profileData?.region ? (
        <Col gap="0.4rem">
          <Row noWrap>{profileData?.country}</Row>
          <Row noWrap>{profileData?.region}</Row>
        </Col>
      ) : (
        "-"
      ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [APPLICANT_TYPES.recommended],
    },
  },
  missionStats: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnMissionStats" />,
    width: "15%",
    cell: (row) => (
      <Col gap="0.4rem">
        <Row noWrap>
          {row?.missionData?.activeApplicationCount > 0 && row?.missionData?.activeApplicationRoles ? (
            <Overlay
              overlay={
                <PopoverCard paddingBottom>
                  {row?.missionData?.activeApplicationRoles?.map((role, i) => (
                    <p key={i}>{role.name}</p>
                  ))}
                </PopoverCard>
              }
              trigger={
                <FormattedMessage
                  id="Missions.MissionCandidateList.ColumnMissionStats.ActiveApplications"
                  values={{ count: row?.missionData?.activeApplicationCount }}
                />
              }
              placement="top"
            />
          ) : (
            <FormattedMessage
              id="Missions.MissionCandidateList.ColumnMissionStats.ActiveApplications"
              values={{ count: row?.missionData?.activeApplicationCount }}
            />
          )}
        </Row>
        <Row noWrap>
          <FormattedMessage
            id="Missions.MissionCandidateList.ColumnMissionStats.LifetimeMissions"
            values={{ count: row?.missionData?.missionCount }}
          />
        </Row>
        {row?.missionData?.invitationConversionRate !== null ? (
          <Row noWrap>
            <FormattedMessage
              id="Missions.MissionCandidateList.ColumnMissionStats.InvitationsAccepted"
              values={{ rate: row?.missionData?.invitationConversionRate }}
            />
          </Row>
        ) : (
          <></>
        )}
      </Col>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [APPLICANT_TYPES.recommended],
    },
  },
  mission: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnRoleMissionOrg" />,
    width: "15%",
    cell: (row) => <RoleLink mission={row?.missionRole?.mission} missionRole={row?.missionRole} withOverlay />,
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client, USER_TYPES.freelancer],
      states: [
        null,
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  profileRate: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnProfileRate" />,
    width: "15%",
    cell: (row) => (
      <Col>
        <Row gap="0.25rem" noWrap>
          <Icon type={ICON_TYPE.coin} size={ICON_SIZE.medium} color="inherit" />
          <FormattedMessage
            id={row?.profile?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
            values={{
              amount: formatCurrency(row?.profile?.rate?.amount, row?.profile?.rate?.currency),
              type: (
                <FormattedMessage id={`Profile.ProfileRateAndAvailability.Frequency${row?.profile?.rate?.frequency}`} />
              ),
            }}
          />
        </Row>
      </Col>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [null, APPLICANT_TYPES.recommended],
    },
  },
  hourlyRateRange: {
    label: <FormattedMessage id="Missions.MissionCandidateList.HourlyRate" />,
    width: "15%",
    cell: ({ profile: { sellingPriceEstimate } }) => (
      <ProfileRateRange
        includesCommission={false}
        low={sellingPriceEstimate?.hourly?.low}
        high={sellingPriceEstimate?.hourly?.high}
        currency={sellingPriceEstimate?.currency}
        assignedSellingRate={false}
      />
    ),
    restrict: {
      roles: [USER_TYPES.showcase, USER_TYPES.client],
      states: [
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  state: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnState" />,
    width: "10%",
    cell: (row) => (
      <Tag size={TAG_SIZE.medium}>
        <FormattedMessage id={`Missions.MissionCandidateList.State.${row.state}`} />
      </Tag>
    ),
  },
  applicationRate: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnApplicationRate" />,
    width: "15%",
    cell: (row) => (
      <Col>
        {row?.rate?.amount ? (
          <Row gap="0.25rem" noWrap>
            <Icon type={ICON_TYPE.checkDotted} size={ICON_SIZE.medium} color="inherit" />
            <Tooltip tooltipId={"Missions.Applicants.ApplicantList.ApplicantRate"}>
              <FormattedMessage
                id={row?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
                values={{
                  amount: formatCurrency(row?.rate?.amount, row?.rate?.currency),
                  type: <FormattedMessage id={`Profile.ProfileRateAndAvailability.Frequency${row?.rate?.frequency}`} />,
                }}
              />
            </Tooltip>
          </Row>
        ) : (
          <Row gap="0.25rem" noWrap>
            <Icon type={ICON_TYPE.coin} size={ICON_SIZE.medium} color="inherit" />
            <Tooltip tooltipId={"Missions.Applicants.ApplicantList.ProfileRate"}>
              <FormattedMessage
                id={row?.profile?.rate?.amount ? "Profile.ProfileRateAndAvailability.DataRate" : "Global.NotProvided"}
                values={{
                  amount: formatCurrency(row?.profile?.rate?.amount, row?.profile?.rate?.currency),
                  type: (
                    <FormattedMessage
                      id={`Profile.ProfileRateAndAvailability.Frequency${row?.profile?.rate?.frequency}`}
                    />
                  ),
                }}
              />
            </Tooltip>
          </Row>
        )}
      </Col>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  noticePeriod: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnNoticePeriod" />,
    width: "10%",
    cell: (row) =>
      !row.noticePeriod ? (
        <FormattedMessage id="Global.NotProvided" />
      ) : (
        AVAILABILITIES.find((e) => e.value === row.noticePeriod).label
      ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  profileAvailability: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnAvailability" />,
    width: "15%",
    cell: (row) => (
      <Status enabled={row?.profile?.capacityStatus === CAPACITY_STATUS.available}>
        <UserCapacity data={row?.profile} />
      </Status>
    ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [null, APPLICANT_TYPES.recommended],
    },
  },
  invited: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnInvitedBy" />,
    width: "15%",
    cell: (row) => (
      <Col>
        <UserFullName data={row?.invitedBy} />
        <span>{formatDate(row?.invitedAt)}</span>
      </Col>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [null],
    },
  },
  applied: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnAppliedAt" />,
    width: "10%",
    cell: (row) => (row?.appliedAt ? formatDate(row?.appliedAt) : <FormattedMessage id="Global.EmDash" />),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client, USER_TYPES.freelancer],
      states: [
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  created: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnCreatedAt" />,
    width: "10%",
    cell: (row) => formatDate(row?.createdAt),
  },
  pending: {
    label: <FormattedMessage id="Missions.MissionCandidateList.ColumnPendingSince" />,
    width: "10%",
    cell: (row) => formatDate(row?.createdAt),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [APPLICANT_TYPES.pending],
    },
  },
  matchScore: {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    width: "7%",
    cell: (row) => (
      <Tooltip tooltipId="Global.Text" values={{ text: <ScoreDisplay subScores={row?.subScores} /> }}>
        <Score score={Number(row?.matchScore) * 100}>{convertToPercent(row?.matchScore)}</Score>
      </Tooltip>
    ),
    restrict: {
      roles: [USER_TYPES.admin, USER_TYPES.showcase, USER_TYPES.client],
      states: [
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  matchScoreWithoutTooltip: {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    width: "7%",
    cell: (row) => <Score score={Number(row?.matchScore) * 100}>{convertToPercent(row?.matchScore)}</Score>,
    restrict: {
      roles: [USER_TYPES.admin],
      states: [null],
    },
  },
  matchScoreWithTooltip: {
    label: <FormattedMessage id="Missions.MissionCandidateList.MatchScore" />,
    width: "7%",
    cell: (row) => (
      <Overlay
        overlay={<MatchScoreDisplay scores={row?.scores} scoreData={row?.scoreData} />}
        trigger={<Score score={Number(row?.matchScore) * 100}>{convertToPercent(row?.matchScore)}</Score>}
        placement="top"
      />
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [APPLICANT_TYPES.recommended],
    },
  },
  rateScore: {
    label: <FormattedMessage id="Missions.MissionCandidateList.RateScore" />,
    width: "7%",
    cell: (row) => <Score score={Number(row?.rateScore) * 100}>{convertToPercent(row?.rateScore)}</Score>,
    restrict: {
      roles: [USER_TYPES.admin],
      states: [
        null,
        APPLICANT_TYPES.recommended,
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  },
  rateScoreWithTooltip: {
    label: <FormattedMessage id="Missions.MissionCandidateList.RateScore" />,
    width: "7%",
    cell: (row) => (
      <Overlay
        overlay={<RateScoreDisplay rateData={row?.hourlyRateData} currency={row?.hourlyRateData?.currency} />}
        trigger={<Score score={Number(row?.rateScore) * 100}>{convertToPercent(row?.rateScore)}</Score>}
        placement="top"
      />
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [APPLICANT_TYPES.recommended],
    },
  },
  rate: {
    label: "",
    width: "10%",
    cell: (row) => {
      const hourlyRate = formatNumber(row?.hourlyRateData?.consideredHourlyRateUsd, "", 2);
      const hourlyRateDelta = formatNumber(row?.hourlyRateData?.consideredHourlyRateUsdDelta, "", 2);

      return (
        <Col gap="0.4rem">
          <div css={styles.rate}>
            <FormattedMessage
              id="Global.HourlyRateWithCurrency"
              values={{ rate: hourlyRate, currency: row?.hourlyRateData?.currency }}
            />
          </div>
          <div css={styles.deltaRate(hourlyRateDelta > 0)}>
            <FormattedMessage
              id="Global.HourlyRateWithoutCurrency"
              values={{ rate: `${hourlyRateDelta > 0 ? "+" : ""}${hourlyRateDelta}` }}
            />
          </div>
        </Col>
      );
    },
    restrict: {
      roles: [USER_TYPES.admin],
      states: [APPLICANT_TYPES.recommended],
    },
  },
  viewButton: ({ setApplication }) => ({
    width: "5%",
    cell: (row) => (
      <ActionButton onClick={() => setApplication(row)}>
        <Icon type={ICON_TYPE.eye} size={ICON_SIZE.large} color={colors.purpleRainBase} />
      </ActionButton>
    ),
    restrict: {
      roles: [USER_TYPES.admin],
      states: [
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  }),
  navigateToApplicationButton: ({ navigateToApplication }) => ({
    width: "5%",
    cell: (row) => (
      <ActionButton onClick={() => navigateToApplication(row)}>
        <Icon type={ICON_TYPE.eye} size={ICON_SIZE.large} color={colors.purpleRainBase} />
      </ActionButton>
    ),
    restrict: {
      roles: [USER_TYPES.freelancer],
      states: [
        APPLICANT_TYPES.pending,
        APPLICANT_TYPES.applied,
        APPLICANT_TYPES.shortlisted,
        APPLICANT_TYPES.interviewed,
        APPLICANT_TYPES.selected,
        APPLICANT_TYPES.unselected,
      ],
    },
  }),
};

const PROFILE_STATUS_COLORS = {
  [MEMBER_STATUS.active]: colors.green,
  [MEMBER_STATUS.archived]: colors.red,
  [MEMBER_STATUS.inactive]: colors.red,
  [MEMBER_STATUS.rejected]: colors.red,
  [MEMBER_STATUS.assessing]: colors.grayAnatomyBase,
  [MEMBER_STATUS.reviewing]: colors.grayAnatomyBase,
  [MEMBER_STATUS.sourced]: colors.grayAnatomyBase,
};

const getTagColorForProfileStatus = (status) => PROFILE_STATUS_COLORS[status] || colors.grayAnatomyBase;

const styles = {
  rate: css`
    font-weight: 500;
  `,
  archetype: css`
    font-size: ${fontSize.xxsmall};
  `,
  deltaRate: (isPositive) => css`
    font-size: ${fontSize.xxsmall};
    color: ${isPositive ? colors.red : colors.green};
    padding-left: 2.8rem;
  `,
};

export default APPLICANT_LIST_COLUMNS;
