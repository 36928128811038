const APPLICATION_STEPS = {
  questionnaire: "questionnaire",
  application_rate: "application_rate",
  code_challenge: "code_challenge",
  video_interview: "video_interview",
  profile_completion: "profile_completion",
  role_application: "role_application",
};

export default APPLICATION_STEPS;
