import React from "react";
import { useParams } from "react-router-dom";
import App from "App";
import { Col, Grid, NarrowContainer } from "components/Containers";
import { ProfileBox } from "components/Profile";
import { PublicFooter, PublicHeader } from "components/Public";
import { themes } from "style";
import { MissionRolesPublicFooter } from "components/Missions/Roles/Public";

/**
 * PublicProfile
 */
const PublicProfile = () => {
  const { id } = useParams();

  return (
    <App theme={themes.website}>
      <PublicHeader />
      <NarrowContainer>
        <Grid topMargin bottomMargin>
          <Grid.col start={2} end={12}>
            <ProfileBox id={id} isEditable={false} isPublicProfile={true} keyName="publicProfile" />
          </Grid.col>
        </Grid>
      </NarrowContainer>
      <Col>
        <MissionRolesPublicFooter />
        <PublicFooter />
      </Col>
    </App>
  );
};

export default PublicProfile;
