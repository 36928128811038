/** @jsx jsx */
import styled from "@emotion/styled";
import { breakpoints, colors, fontSize } from "style";

/**
 * MarketingHeader
 */
const MarketingHeader = styled.span`
  font-size: ${fontSize.medium};
  color: ${({ variant }) => variant || colors.purpleRainBase};
  margin-bottom: 2.5rem;
  font-weight: 600;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSize.normal};
    margin-bottom: 1.5rem;
  }
`;

export default MarketingHeader;
