import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useAssessment, useMissionRoleApplicant } from "hooks";
import Modal, { MODAL_SIZE } from "components/Modal";
import { MessagesError } from "components/Messages";
import { ProfileBio, ProfileVideoInterviewsList } from "components/Profile";
import { Col, Grid } from "components/Containers";
import {
  ApplicantMatchScore,
  ApplicationChallenges,
  ApplicationMatchingTags,
  ApplicationPrivateNotesForm,
  ApplicationQuestionnaireResponses,
  ApplicationList,
  ApplicationRate,
  ApplicantNegotiatedRate,
  ApplicationPreviousAssignments,
  ApplicationUpdateSelect,
} from "components/Missions/Applicants";
import { COMMENTABLE_TYPES, LINKS } from "constants";
import Comments from "components/Comments";
import Icon, { ICON_SIZE, ICON_TYPE, withIcon } from "components/Icon";
import { SectionTitle } from "components/Layouts";
import Card from "components/Card";
import { PrimaryLink } from "components/Links";
import { RoleLink } from "components/Missions/Roles";
import { H2 } from "components/Text";
import { formatDate } from "utils";
import { colors, fontSize } from "style";
import { SIZE } from "components/Missions/Applicants/ApplicantMatchScore";
import { OrganizationLink } from "components/Organization";
import { MissionLink } from "components/Missions";

const DEFAULT_EXPANDABLE_STATE = {
  applicationDetails: false,
  matchingTags: false,
  rateMatchingTags: false,
  challengeResponses: false,
  questionnaireResponses: false,
  notes: false,
  otherApplications: false,
  videoInterviews: false,
  previousTeams: false,
};

/**
 * ApplicationDetailsModal
 *
 * @param {Object}    application
 * @param {Function}  setApplication
 */
const ApplicationDetailsModal = ({ application, setApplication, ...props }) => {
  const [challenges, setChallenges] = useState([]);
  const { loading, data, error, list, updateApplicant } = useMissionRoleApplicant({ id: application.id });
  const [show, setShow] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [questionCount, setQuestionCount] = useState(0);
  const [averageScore, setAverageScore] = useState(0);

  const { data: assessment, loading: assessmentLoading } = useAssessment({
    id: data?.missionRole?.assessmentId,
    resourceType: "member",
    resourceId: data?.profile?.id,
  });

  useEffect(() => {
    setQuestionCount?.((assessment?.questions?.length || 0) + 2); // 2 is for the static questions
  }, [assessment?.questions]);

  const [expandable, setExpandable] = useState(DEFAULT_EXPANDABLE_STATE);

  useEffect(() => {
    if (data) {
      setChallenges(data.profile.challengeResponses);
    }
  }, [data]);

  // Reset expandable state when a new application is set
  useEffect(() => {
    if (application) {
      setExpandable(DEFAULT_EXPANDABLE_STATE);
    }
  }, [application]);

  const handleExpand = (isExpanded, expandableId) => setExpandable((prev) => ({ ...prev, [expandableId]: isExpanded }));

  useEffect(() => {
    setAverageScore(
      calculateAverage(data?.challengesCompletedByProfile?.length ? data?.challengesCompletedByProfile : challenges)
    );
  }, [data?.challengesCompletedByProfile, challenges]);

  const handleUpdateApplicant = (params) => {
    updateApplicant(application.id, { negotiatedRate: params });
  };

  return (
    <>
      <Modal
        title={
          <div css={styles.modal_title}>
            <FormattedMessage
              id="Missions.MissionRole.ApplicationDetailsModal.Title"
              values={{
                id: <span css={styles.applicant_id}>{application.missionRole.slug}</span>,
              }}
            />
            <ApplicationUpdateSelect
              missionRoleApplicant={application}
              loading={loading}
              css={{
                width: "15rem",
              }}
            />
          </div>
        }
        info={
          <span>
            <PrimaryLink onClick={() => setShow((prev) => !prev)} disabled={show}>
              <FormattedMessage
                id="Missions.MissionRole.ApplicationDetailsModal.ShowComments"
                values={{
                  count: commentCount,
                }}
              />
              <Icon type={ICON_TYPE.arrowRight} color="inherit" />
            </PrimaryLink>
          </span>
        }
        loading={loading}
        secondaryButtonMessageId="Global.SaveClose"
        showSave={false}
        size={MODAL_SIZE.large}
        onClose={() => setApplication(null)}
        key={application.id}
        {...props}
      >
        {error && <MessagesError />}
        <Grid gap="1rem">
          <Grid.col start={1} end={13}>
            <Col gap="0.5rem">
              <H2 css={styles.title}>
                <FormattedMessage
                  id="Missions.MissionRole.ApplicationDetailsModal.Header"
                  values={{
                    role: <RoleLink mission={application.mission} missionRole={application.missionRole} withOverlay />,
                    mission: <MissionLink data={application.mission} withOverlay />,
                    org: <OrganizationLink data={application.organization} withOverlay />,
                  }}
                />
              </H2>
              <span css={styles.subtitle}>
                <FormattedMessage
                  id="Missions.MissionRole.ApplicationDetailsModal.AppliedOn"
                  values={{
                    date: data?.appliedAt ? formatDate(data.appliedAt, "MMMM Do, YYYY", false) : "—",
                  }}
                />
              </span>
            </Col>
          </Grid.col>
          <Grid.col start={1} end={13}>
            <Card>
              <ProfileBio
                loading={loading}
                data={data?.profile}
                isEditable={false}
                hideDescription={true}
                profilePicProps={{
                  width: `12rem`,
                  height: `12rem`,
                }}
              />
              <LinkProfile href={LINKS.private_profile(data?.profile?.slug)} withMoreIcon routerEnabled>
                <FormattedMessage id="Profile.ProfileBio.ViewPrivateLink" />
              </LinkProfile>
            </Card>
          </Grid.col>
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={
                <FormattedMessage
                  id="Missions.Applicants.ApplicationDetailsModal.TitleMatchingTags"
                  values={{
                    count: (
                      <ApplicantMatchScore
                        loading={loading}
                        score={data?.matchScore * 100}
                        size={SIZE.small}
                        isNull={!data?.matchScore && data?.matchScore !== 0}
                        isPercent
                      />
                    ),
                  }}
                />
              }
              expandableId="matchingTags"
              isExpanded={expandable.matchingTags}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.matchingTags === true && (
            <Grid.col start={1} end={13}>
              <Card>
                <ApplicationMatchingTags loading={loading} data={data} />
              </Card>
            </Grid.col>
          )}
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={
                <FormattedMessage
                  id="Missions.Applicants.ApplicationDetailsModal.TitleRateMatchingTags"
                  values={{
                    count: (
                      <ApplicantMatchScore
                        loading={loading}
                        score={data?.rateScore * 100}
                        size={SIZE.small}
                        isNull={!data?.rateScore && data?.rateScore !== 0}
                        isPercent
                      />
                    ),
                  }}
                />
              }
              expandableId="rateMatchingTags"
              isExpanded={expandable.rateMatchingTags}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.rateMatchingTags === true && (
            <>
              <Grid.col start={1} end={13}>
                <Card>
                  <ApplicationRate loading={loading} data={data} />
                </Card>
              </Grid.col>
              <Grid.col start={1} end={13}>
                <ApplicantNegotiatedRate
                  rate={data?.negotiatedRate}
                  loading={loading}
                  onSave={handleUpdateApplicant}
                  onRemove={() => handleUpdateApplicant({ amount: null, currency: null, frequency: null })}
                />
              </Grid.col>
            </>
          )}
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={
                <FormattedMessage
                  id="Missions.Applicants.ApplicantDetailsModal.LabelVideoInterviews"
                  values={{
                    count: (
                      <ApplicantMatchScore
                        loading={loading || list?.loading}
                        score={data?.profile?.completedInterviewsCount}
                        size={SIZE.small}
                      />
                    ),
                  }}
                />
              }
              expandableId="videoInterviews"
              isExpanded={expandable.videoInterviews}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.videoInterviews === true && (
            <Grid.col start={1} end={13}>
              <Card>
                <ProfileVideoInterviewsList profileId={data?.profile?.id} />
              </Card>
            </Grid.col>
          )}
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={
                <FormattedMessage
                  id="Missions.Applicants.ApplicationDetailsModal.TitleChallenges"
                  values={{
                    count: (
                      <ApplicantMatchScore
                        loading={loading}
                        score={averageScore}
                        size={SIZE.small}
                        isPercent={true}
                        isNull={challenges?.length === 0}
                      />
                    ),
                  }}
                />
              }
              expandableId="challengeResponses"
              isExpanded={expandable.challengeResponses}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.challengeResponses === true && (
            <Grid.col start={1} end={13}>
              <Card>
                <ApplicationChallenges
                  loading={loading}
                  challenges={challenges}
                  missionRoleChallenges={data?.challengesCompletedByProfile}
                />
              </Card>
            </Grid.col>
          )}
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={
                <FormattedMessage
                  id="Missions.Applicants.ApplicationDetailsModal.TitleQuestionnaire"
                  values={{
                    count: <ApplicantMatchScore loading={loading} score={questionCount} size={SIZE.small} />,
                  }}
                />
              }
              expandableId="questionnaireResponses"
              isExpanded={expandable.questionnaireResponses}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.questionnaireResponses === true && (
            <Grid.col start={1} end={13}>
              <Card>
                <ApplicationQuestionnaireResponses
                  loading={loading || assessmentLoading}
                  data={data}
                  assessment={assessment}
                  onComplete={setQuestionCount}
                />
              </Card>
            </Grid.col>
          )}
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={
                <FormattedMessage
                  id="Missions.Applicants.ApplicantDetailsModal.LabelOtherApplications"
                  values={{
                    count: (
                      <ApplicantMatchScore
                        loading={loading || list?.loading}
                        score={list?.data?.missionRoleApplicants?.totalCount}
                        size={SIZE.small}
                      />
                    ),
                  }}
                />
              }
              expandableId="otherApplications"
              isExpanded={expandable.otherApplications}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.otherApplications === true && (
            <Grid.col start={1} end={13}>
              <Card>
                <ApplicationList
                  profileId={data?.profile?.id}
                  excludingRoleId={data?.missionRole?.id}
                  setApplication={setApplication}
                />
              </Card>
            </Grid.col>
          )}
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={
                <FormattedMessage
                  id="Missions.Applicants.ApplicantDetailsModal.LabelPreviousTeams"
                  values={{
                    count: (
                      <ApplicantMatchScore
                        loading={loading}
                        score={data?.profile?.allTimeAssignmentsCount}
                        size={SIZE.small}
                      />
                    ),
                  }}
                />
              }
              expandableId="previousTeams"
              isExpanded={expandable.previousTeams}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.previousTeams === true && (
            <Grid.col start={1} end={13}>
              <Card>
                <ApplicationPreviousAssignments profileId={data?.profile?.id} />
              </Card>
            </Grid.col>
          )}
          <Grid.col start={1} end={13}>
            <hr />
          </Grid.col>
          <Grid.col start={1} end={13}>
            <SectionTitle
              title={<FormattedMessage id="Missions.Applicants.ApplicantDetailsModal.LabelPrivateNotes" />}
              expandableId="notes"
              isExpanded={expandable.notes}
              onExpand={handleExpand}
              isExpandable
            />
          </Grid.col>
          {expandable.notes === true && (
            <Grid.col start={1} end={13}>
              <ApplicationPrivateNotesForm loading={loading} data={data} />
            </Grid.col>
          )}
        </Grid>
      </Modal>
      <Comments
        commentableId={data?.id}
        commentableType={COMMENTABLE_TYPES.missionRoleApplicant}
        loading={loading}
        show={show}
        onShow={setShow}
        onLoaded={setCommentCount}
      />
    </>
  );
};

const calculateAverage = (challenges) => {
  const sumScores = challenges.reduce((accumulator, current) => {
    const score = current.score || 0;
    const maxScore = current.maxScore || 1; // Avoid division by zero
    return accumulator + score / maxScore;
  }, 0);

  const averageScore = (sumScores / challenges.length) * 100;
  return Number(averageScore.toFixed(2));
};

const LinkProfile = withIcon(PrimaryLink, {
  type: ICON_TYPE.eye,
  size: ICON_SIZE.medium,
  color: "inherit",
});

const styles = {
  applicant_id: css`
    color: ${colors.grayAnatomyLight1};
    font-weight: 300;
  `,
  modal_title: css`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  title: css`
    font-size: ${fontSize.xlarge};
    font-weight: 400;
  `,
  header_container: css`
    width: 100%;
    justify-content: center;
    align-items: center;
  `,
  subtitle: css`
    color: ${colors.grayAnatomyLight1};
    font-size: ${fontSize.medium};
  `,
  separator: css`
    width: 1px;
    height: 8rem;
    border-left: 1px solid ${colors.grayAnatomyLight3};
  `,
};

ApplicationDetailsModal.propTypes = {
  application: PropTypes.object,
  setApplication: PropTypes.func,
};

export default ApplicationDetailsModal;
