import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { PopoverCard } from "components/ActivityLog";
import ListTable from "components/ListTable";
import { Col, Row } from "components/Containers";
import { formatNumber } from "utils";

/**
 * RateScoreDisplay
 *
 * @params {Object}    rateData
 * @params {String}    currency
 */
const RateScoreDisplay = ({ rateData, currency }) => {
  if (!rateData || !currency) return <FormattedMessage id="Global.NA" />;

  return (
    <PopoverCard width="35rem" paddingBottom>
      <ListTable data={TABLE_DATA(rateData, currency)} />
    </PopoverCard>
  );
};

const TABLE_DATA = (
  { missionRoleTargetRateUsd, profileHourlyRateUsd, minimumApplicantHourlyRateUsd, consideredHourlyRateUsd },
  currency
) => [
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.TargetRate" />,
    value: (
      <FormattedMessage id="Global.HourlyRateWithCurrency" values={{ rate: missionRoleTargetRateUsd, currency }} />
    ),
  },
  {
    label: (
      <Row noWrap>
        <Col gap="1rem">
          <p>
            <FormattedMessage id="Missions.MissionCandidateList.RateScore.PreferredRate" />
          </p>
          <p>
            <FormattedMessage id="Missions.MissionCandidateList.RateScore.MinApplicationRate" />
          </p>
        </Col>
      </Row>
    ),
    value: (
      <Row noWrap>
        <Col gap="1rem">
          <p>
            <FormattedMessage
              id="Global.HourlyRateWithCurrency"
              values={{ rate: formatNumber(profileHourlyRateUsd, "", 2), currency }}
            />
          </p>
          <p>
            {minimumApplicantHourlyRateUsd ? (
              <FormattedMessage
                id="Global.HourlyRateWithCurrency"
                values={{ rate: formatNumber(minimumApplicantHourlyRateUsd, "", 2), currency }}
              />
            ) : (
              "-"
            )}
          </p>
        </Col>
      </Row>
    ),
  },
  {
    label: <FormattedMessage id="Missions.MissionCandidateList.RateScore.ConsideredRate" />,
    value: (
      <FormattedMessage
        id="Global.HourlyRateWithCurrency"
        values={{ rate: formatNumber(consideredHourlyRateUsd, "", 2), currency: currency }}
      />
    ),
  },
];

RateScoreDisplay.propTypes = {
  rateData: PropTypes.object.isRequired,
  currency: PropTypes.string,
};

export default RateScoreDisplay;
