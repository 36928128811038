import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors, fontSize } from "style";
import { Spinner } from "components/Loader";

/**
 * MarketingButton
 *
 * @param {Object}  children
 * @param {Boolean} loading
 */
const MarketingButton = ({ children, loading, ...props }) => (
  <div css={styles.container}>
    <button css={styles.button(loading)} disabled={loading} {...props}>
      {loading ? <Spinner size={`2rem`} /> : children}
    </button>
    <span css={styles.arrow(loading)}>
      <Icon size={ICON_SIZE.large} type={ICON_TYPE.chevronLeft} color="inherit" />
    </span>
  </div>
);

const styles = {
  container: css`
    display: flex;
    gap: 0;
    flex-wrap: nowrap;
    cursor: pointer;
    &:hover {
      > button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > span {
        transform: translateX(-2.5rem);
      }
    }
  `,
  arrow: (disabled) => css`
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background: ${disabled ? colors.grayAnatomyLight1 : colors.purpleRainBase};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    > * {
      transform: rotate(180deg);
    }
  `,
  button: (disabled) => css`
    height:5rem;
    padding: 0 2.5rem;
    rem;
    color:white;
    border:0;
    border-radius:2.5rem;
    background: ${disabled ? colors.grayAnatomyLight1 : colors.purpleRainBase};
    font-weight: 500;
    font-size: ${fontSize.small};
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
  `,
};

MarketingButton.propTypes = {
  children: PropTypes.object,
  loading: PropTypes.bool,
};

export default MarketingButton;
