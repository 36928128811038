import Tags from "./Tags";

export { default as Tag } from "./Tag";
export { EditableTag, StyledTag, Count, TAG_SIZE } from "./Tag";
export { default as TagDuration } from "./TagDuration";
export { default as TagComplete } from "./TagComplete";
export { default as TagIncomplete } from "./TagIncomplete";
export { default as TagTicker } from "./TagTicker";

export default Tags;
