import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Grid } from "components/Containers";
import { colors } from "style";
import { TertiaryButton } from "components/Buttons";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ProgressMenu
 *
 * @description A progress menu component that displays a list of items with an arrow between them
 *
 * @param {Array}     items
 * @param {Function}  onSelect
 * @param {Number}    selected
 */
const ProgressMenu = ({ items, onSelect, selected }) => (
  <Grid css={styles.navigator} cols={items.length * 3 - 1}>
    {items.map((item, index) => (
      <Fragment key={index}>
        <Grid.col colSpan={2}>
          <RenderItem index={`${index}`} onSelect={onSelect} selected={selected} {...item} />
        </Grid.col>
        {index < items.length - 1 && (
          <Grid.col>
            <Icon type={ICON_TYPE.arrowRight} size={ICON_SIZE.large} />
          </Grid.col>
        )}
      </Fragment>
    ))}
  </Grid>
);

/**
 * RenderItem
 *
 * @description A recursive item renderer to build the items for progress menu
 *
 * @param {Number}    index
 * @param {String}    label
 * @param {Array}     subItems
 * @param {Number}    selected
 * @param {Function}  onSelect
 */
const RenderItem = ({ index, label, subItems, selected, onSelect }) =>
  subItems ? (
    <Stack>
      {subItems.map((child, subIndex) => (
        <RenderItem key={subIndex} index={`${index}-${subIndex}`} selected={selected} onSelect={onSelect} {...child} />
      ))}
    </Stack>
  ) : (
    <Selector {...(selected === index && { selected: true })} onClick={() => onSelect(index)}>
      {label}
    </Selector>
  );

RenderItem.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
  subItems: PropTypes.array,
  selected: PropTypes.number,
  onSelect: PropTypes.func,
};

const Stack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Selector = styled(TertiaryButton)`
  width: 100%;

  ${(props) =>
    props.selected &&
    css`
      color: ${colors.purpleRainBase};
      box-shadow: 0 0 0 2px ${colors.purpleRainBase};
      background: ${colors.purpleRainLight5} !important;
      border: 2px #fff solid;
    `}
`;

const styles = {
  navigator: css`
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `,
};

ProgressMenu.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  selected: PropTypes.number,
};

export default ProgressMenu;
