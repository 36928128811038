/**
 * Shortens a string (ie: This is about...)
 *
 * @param {string} str the string to be shorten
 * @param {number} n shorten it to what point
 * @param {boolean} useWordBoundary use a word boundary or not
 *
 * @returns {string} the shorten string
 */
export default function shortenText(str, n, useWordBoundary = true, fromCenter = false) {
  if (!str) {
    return;
  } else if (str.length <= n) {
    return str;
  } else if (fromCenter) {
    return str.slice(0, n / 2) + "…" + str.substr(str.length - n / 2);
  } else {
    var subString = str.substr(0, n);

    return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) || subString : subString) + "…";
  }
}
