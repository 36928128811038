import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { PageWrapper } from "components/Containers";
import { Grid } from "components/Containers";
import PageTitle from "components/PageTitle";
import { ApplicantListLayout } from "components/Missions/Applicants";
import { APPLICANT_LIST_COLUMNS, APPLICANT_TYPES } from "constants/index";

/**
 * Mission Role Recommendations
 */
const MissionRoleRecommendations = () => {
  const { selectedRole } = useOutletContext();
  const intl = useIntl();

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Role.Recommendations.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle subtitle={<FormattedMessage id="Missions.Roles.Recommendations.Title" />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <ApplicantListLayout
            initialTab={1}
            defaultSelected={{
              missionId: selectedRole?.mission?.id,
              missionRoleId: selectedRole?.id,
              organizationId: selectedRole?.mission?.organization?.id,
              applicantState: APPLICANT_TYPES.recommended,
            }}
            hideTabs
            hideProgressMenu
            showAdvancedOptions
            showAdminOnlyPages
            hideOrganizationFilter
            hideMissionFilter
            hideMemberFilter
            hideFilters
            columns={[
              APPLICANT_LIST_COLUMNS.memberWithDisciplines,
              APPLICANT_LIST_COLUMNS.status,
              APPLICANT_LIST_COLUMNS.location,
              APPLICANT_LIST_COLUMNS.missionStats,
              APPLICANT_LIST_COLUMNS.matchScoreWithTooltip,
              APPLICANT_LIST_COLUMNS.rateScoreWithTooltip,
              APPLICANT_LIST_COLUMNS.rate,
            ]}
          />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default MissionRoleRecommendations;
