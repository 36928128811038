import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useMissionRoleApplicant } from "hooks";
import { PrimaryButton } from "components/Buttons";

/**
 * Candidate Submit Button
 *
 * @param {Object}  missionRoleApplicant
 * @param {Boolean} canSubmit
 */
const ApplicationSubmitButton = ({ missionRoleApplicant, canSubmit, ...props }) => {
  const { submitApplication, submitting } = useMissionRoleApplicant({});

  const handleSubmit = () => {
    submitApplication(missionRoleApplicant?.id);
  };

  return (
    <PrimaryButton
      disabled={!canSubmit || submitting}
      variant="green"
      css={styles.button}
      onClick={handleSubmit}
      {...props}
    >
      {missionRoleApplicant?.appliedAt ? (
        <FormattedMessage
          id="Missions.Applicants.ApplicationSubmitButton.Submitted"
          values={{
            date: moment(missionRoleApplicant?.appliedAt).format("MMMM Do, YYYY"),
          }}
        />
      ) : (
        <FormattedMessage id="Missions.Applicants.ApplicationSubmitButton.CTA" />
      )}
    </PrimaryButton>
  );
};

const styles = {
  button: css`
    width: 100%;
  `,
};

ApplicationSubmitButton.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
  canSubmit: PropTypes.bool.isRequired,
};

export default ApplicationSubmitButton;
