import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { breakpoints, colors, fontSize, margins } from "style";
import { getObjectFromArray } from "utils";
import { H3, SubTitle, Paragraph } from "components/Text";
import { TextLoader } from "components/Loader";
import { EditIcon } from "components/Icon";
import { EditButton } from "components/Buttons";
import { EditLink } from "components/Links";
import { Grid } from "components/Containers";

/**
 * DisplayBox
 *
 * @param {String}   title               -Title text
 * @param {String}   description         -Description text
 * @param {String}   subtitle            -Subtitle text
 * @param {String}   marginSize          -Margin size
 * @param {String}   editButtonText      -Edit button text
 * @param {data}     data                -Data object
 * @param {Object}   keys                -Object key names for title, subtitle, body
 * @param {Number}   numColumns          -Number of columns to display
 * @param {String}   rowGap              -Row gap
 * @param {String}   colGap              -Column gap
 * @param {Boolean}  loading             -Item is loading
 * @param {Boolean}  isEmpty             -Item is empty/null
 * @param {Boolean}  isEditable          -Item is a single editable entity/object
 * @param {Boolean}  canAdd              -Can add new items/entries (Entity is an array of objects)
 * @param {Function} onEdit              -Callback
 * @param {Function} onAdd               -Callback
 * @param {String}   updateId            -The parent id to update
 * @param {Object}   initialValues       -Initial values to set for edit form
 * @param {String}   parentKeyName       -Key name for parent when updating
 * @param {String}   parentKeyValue      -Key value for parent when updating
 * @param {Element}  itemRenderer        -Provide an element to render the body of item
 * @param {Any}      emptyMsg            -Message to display when item is empty
 * @param {Boolean}  hideHeader          -Hide the header
 */
const DisplayBox = ({
  title,
  description,
  subtitle,
  marginSize,
  editButtonText,
  rowGap,
  colGap,
  data,
  keys,
  numColumns,
  loading,
  isEmpty,
  isEditable,
  canAdd,
  onEdit,
  onAdd,
  updateId,
  initialValues,
  parentKeyName,
  parentKeyValue,
  itemRenderer,
  emptyMsg,
  hideHeader,
  ...props
}) => {
  const showHeaderEditIcon = !loading && isEditable && !canAdd && !isEmpty;
  const showHeaderAddLink = !loading && canAdd && !isEmpty;
  const showBodyEditButton = isEmpty && isEditable;
  const showEmptyMessage = isEmpty && !isEditable;

  return (
    <>
      {!hideHeader && (
        <Header marginSize={marginSize}>
          {showHeaderEditIcon && (
            <EditIcon
              data-header-edit-icon
              css={styles.header_edit_icon(marginSize)}
              onClick={() => onEdit(initialValues, updateId)}
            />
          )}
          {title && <H3>{title}</H3>}
          {subtitle}
          {showHeaderAddLink && (
            <EditLink data-header-edit-link onClick={() => onAdd(parentKeyName, parentKeyValue)}>
              {editButtonText}
            </EditLink>
          )}
        </Header>
      )}
      <Body marginSize={marginSize} {...props}>
        {description && <Description>{description}</Description>}
        <Grid gap={colGap} rowGap={rowGap} cols={numColumns}>
          {!isEmpty &&
            data?.map((item, index) => (
              <BodyChild
                key={item?.id || index}
                item={item}
                index={index}
                initialValues={initialValues}
                keys={keys}
                loading={loading}
                marginSize={marginSize}
                parentKeyValue={parentKeyValue}
                itemRenderer={itemRenderer}
                onEdit={onEdit}
                emptyMsg={emptyMsg}
              />
            ))}
          {!isEmpty && itemRenderer && loading && <BodyLoader itemRenderer={itemRenderer} />}
        </Grid>
        {showBodyEditButton && (
          <EditButton data-body-edit-link onClick={() => onAdd(parentKeyName, parentKeyValue)}>
            {editButtonText}
          </EditButton>
        )}
        {showEmptyMessage && <Message>{emptyMsg || <FormattedMessage id="Global.NotProvided" />}</Message>}
      </Body>
    </>
  );
};

/**
 * BodyChild
 *
 * @params {Object}   item
 * @params {Number}   index
 * @params {Function} onEdit
 * @params {Object}   initialValues
 * @params {Any}      emptyMsg
 * @params {Element}  itemRenderer
 * @params {Object}   keys
 * @params {Boolean}  loading
 * @params {String}   marginSize
 * @params {String}   parentKeyValue
 */
const BodyChild = ({
  item,
  index,
  onEdit,
  initialValues,
  emptyMsg,
  itemRenderer,
  keys,
  loading,
  marginSize,
  parentKeyValue,
}) => {
  if (itemRenderer) {
    return itemRenderer(item, index);
  }

  if (item.hide) return null;

  const values = initialValues ? getObjectFromArray(initialValues, item.id) : {};
  const { id, ...valuesWithoutId } = values;
  const showEditIcon = !loading && item.isEditable;

  const ChildBodyText = () =>
    typeof item[keys.body] === "string" ? <Paragraph data={item[keys.body]} css={styles.body} /> : item[keys.body];

  return (
    <Block>
      {item[keys.title] && (
        <Title>
          {showEditIcon && (
            <EditIcon
              data-item-edit-icon
              css={[styles.header_edit_icon(marginSize), styles.body_edit_icon]}
              onClick={() => onEdit(valuesWithoutId, id, null, parentKeyValue)}
            />
          )}
          <span>{item[keys.title]}</span>
          <SubTitle>{item[keys.subtitle]}</SubTitle>
        </Title>
      )}
      {item.hideBody ? null : loading ? (
        <TextLoader />
      ) : item[keys.body] ? (
        <ChildBodyText />
      ) : (
        <Message>{emptyMsg || <FormattedMessage id="Global.NotProvided" />}</Message>
      )}
    </Block>
  );
};

BodyChild.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onEdit: PropTypes.func,
  initialValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  emptyMsg: PropTypes.any,
  itemRenderer: PropTypes.func,
  keys: PropTypes.object,
  loading: PropTypes.bool,
  marginSize: PropTypes.string,
  parentKeyValue: PropTypes.any,
};

/**
 * BodyLoader
 *
 * @params {Element}  itemRenderer
 */
const BodyLoader = ({ itemRenderer }) =>
  [0, 1].map((item, index) => <Fragment key={index}>{itemRenderer(item, index, true)}</Fragment>);

BodyLoader.propTypes = {
  itemRenderer: PropTypes.func,
};

const Body = styled.div`
  position: relative;

  @media (max-width: ${breakpoints.portrait}) {
  }
`;

const Header = styled.div`
  margin-top: 4rem;
  margin-right: -3rem;
  padding-right: 3rem;
  border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  padding-bottom: 1rem;
  margin-bottom: ${({ marginBottom }) => marginBottom || 1}rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${fontSize.small};
  color: ${colors.grayAnatomyLight1};
  position: relative;
  border-bottom-width: ${({ noUnderline }) => (noUnderline ? 0 : 1)}px;

  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.portrait}) {
    margin-right: -2.5rem;
    padding-right: 2.5rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    margin-right: -2rem;
    padding-right: 2rem;
  }
`;

const Description = styled.div`
  font-size: ${fontSize.normal};
  margin-bottom: 2rem;
  line-height: 1.5;
  color: ${colors.purpleRainLight4};

  @media (max-width: ${breakpoints.portrait}) {
    font-size: ${fontSize.small};
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: ${fontSize.small};
  margin-bottom: 0.5rem;
  color: ${colors.purpleRainDark2};

  @media (max-width: ${breakpoints.portrait}) {
    font-size: ${fontSize.small};
    margin-bottom: 0rem;
  }
`;

const Label = styled.div`
  font-weight: 500;
  font-size: ${fontSize.xsmall};
  color: ${colors.purpleRainDark2};
  position: relative;
`;

const Message = styled.div`
  font-size: ${fontSize.normal};
  line-height: 2.4rem;
  color: ${colors.grayAnatomyLight2};
  margin-bottom: 0.5rem;

  @media (max-width: ${breakpoints.portrait}) {
    font-size: ${fontSize.small};
  }
`;

const Block = styled.div`
  position: relative;
  font-size: ${fontSize.normal};
  color: ${colors.grayAnatomyBase2};
  font-weight: 400;
  line-height: normal;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints.portrait}) {
    font-size: ${fontSize.small};
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }
`;

const styles = {
  header_edit_icon: (marginSize) => css`
    position: absolute;
    left: -2rem;
    margin-left: -${marginSize};

    @media (max-width: ${breakpoints.portrait}) {
      margin-left: -1.5rem;
      width: 3rem;
      height: 3rem;
    }
  `,
  body_edit_icon: css`
    top: -1rem;
  `,
  body: css`
    margin-top: 0;
  `,
};

const CURSOR_TYPES = {
  pointer: `pointer`,
  default: `default`,
};

DisplayBox.defaultProps = {
  numColumns: 1,
  marginSize: margins.normal,
  isEmpty: false,
  isEditable: false,
  rowGap: `2rem`,
  colGap: `3rem`,
  keys: {
    title: "title",
    subtitle: "subtitle",
    body: "body",
  },
};

DisplayBox.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  subtitle: PropTypes.any,
  marginSize: PropTypes.string,
  editButtonText: PropTypes.any,
  data: PropTypes.array,
  keys: PropTypes.object,
  numColumns: PropTypes.number,
  rowGap: PropTypes.string,
  colGap: PropTypes.string,
  loading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isEditable: PropTypes.bool,
  canAdd: PropTypes.bool,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  updateId: PropTypes.string,
  initialValues: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  parentKeyName: PropTypes.string,
  parentKeyValue: PropTypes.any,
  itemRenderer: PropTypes.func,
  emptyMsg: PropTypes.any,
  hideHeader: PropTypes.bool,
};

export { Label, Title, Description, Message, Header, Block, Body, CURSOR_TYPES };

export { default as RenderIconWithText, RENDER_SIZE } from "./Renderers/RenderIconWithText";
export { default as RenderMemberList } from "./Renderers/RenderMemberList";
export { default as RenderMemberGroupList } from "./Renderers/RenderMemberGroupList";

export default DisplayBox;
