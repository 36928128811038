import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { useParams } from "react-router";
import { css } from "@emotion/react";
import { Grid, Row } from "components/Containers";
import { colors, margins } from "style";
import { ActivityLogCell } from "components/ActivityLog";
import { RenderMemberList } from "components/DisplayBox";
import { UserPhoto } from "components/User";
import { MessagesError, MessagesNoResults } from "components/Messages";
import Overlay from "components/Overlay";
import ListTable from "components/ListTable";
import Card from "components/Card";
import { formatHours } from "utils";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { useTeam } from "hooks";

/**
 * ActivityLogBody
 *
 * @param {Object}     data
 * @param {Function}   renderer
 * @param {Number}     cols
 * @param {Number}     numRows
 * @param {Boolean}    isMembersView
 * @param {Boolean}    loading
 * @param {Object}     error
 * @param {Object}     marginSize
 * @param {Boolean}    forceAspectRadio
 * @param {Boolean}    showAll
 */
const ActivityLogBody = ({
  data,
  renderer,
  cols,
  numRows,
  isMembersView,
  loading,
  error,
  marginSize,
  forceAspectRadio,
  showAll,
}) => {
  const Component = renderer;
  const hasActivityForPeriod = (item) =>
    Object.values(item.activityLogs || item.activities).find(({ activities }) => activities?.length > 0);

  if (loading) {
    return (
      <Grid css={styles.container(isMembersView, marginSize, forceAspectRadio, cols)}>
        {Array.from({ length: numRows }, (_, i) => i).map((col) => (
          <Fragment key={`col-${col}`}>
            {isMembersView && (
              <MemberCell start={1} end={4} marginSize={marginSize}>
                <RenderMemberList loading={true} />
              </MemberCell>
            )}
            {Array.from({ length: cols }, (_, i) => i).map((col) => (
              <Grid.col key={`col-${col}`}>
                <div css={styles.placeholder_container}>
                  <UserPhoto loading={true} width={`4rem`} height={`4rem`} borderRadius="100%" />
                </div>
              </Grid.col>
            ))}
          </Fragment>
        ))}
      </Grid>
    );
  } else if (error) {
    return <MessagesError messageId="Missions.TeamActivityLogs.Error" />;
  } else if (data?.length === 0 || (!showAll && data?.filter(hasActivityForPeriod)?.length === 0)) {
    return <MessagesNoResults title={"Squads.NoActivityTitle"} description={"Squads.NoActivityDescription"} />;
  }

  return (
    <Grid css={styles.container(isMembersView, marginSize, forceAspectRadio, cols)}>
      {data?.map(
        (item, index) =>
          (showAll || hasActivityForPeriod(item)) && (
            <Fragment key={index}>
              {isMembersView && (
                <MemberCell start={1} end={4} marginSize={marginSize}>
                  <Row noWrap>
                    <Count>{index + 1}</Count>
                    <MembersList item={item} marginSize={marginSize} />
                  </Row>
                </MemberCell>
              )}
              {Object.values(item.activityLogs || item.activities).map((item, day_of_week) => (
                <DateCell key={`${day_of_week}-${index}`} isWeekend={item.activityLogs && day_of_week > 4}>
                  <Component {...item} isMembersView={isMembersView} />
                </DateCell>
              ))}
            </Fragment>
          )
      )}
    </Grid>
  );
};

const PROFILE_DATA = (activity) => activity.profile || { firstName: activity.userHandle, lastName: "" };

const TABLE_DATA = (data) => [
  {
    label: <FormattedMessage id="Dashboard.ActivityLog.Body.HoursWorked" />,
    value: formatHours(data?.minutesWorked, true),
  },
  {
    label: <FormattedMessage id="Dashboard.ActivityLog.Body.WeeklyCost" />,
    value: `${data?.weeklyCost} ${data?.currency}`,
  },
  {
    label: <FormattedMessage id="Dashboard.ActivityLog.Body.CostPerPr" />,
    value: `${data?.costPerCommit} ${data?.currency}`,
  },
];

const MembersList = ({ item }) => {
  const { id } = useParams();
  const { teamPermissions } = useTeam({ id });

  return item.activityLogs && teamPermissions?.canAccessActivityLogMeta ? (
    <Overlay
      overlay={
        <PopoverCard>
          {item.metaData ? <ListTable data={TABLE_DATA(item?.metaData)} /> : <GuestContributor />}
        </PopoverCard>
      }
      trigger={<RenderMemberList data={PROFILE_DATA(item)} disableLink={true} userHandle={item?.userHandle} />}
    />
  ) : (
    <RenderMemberList data={PROFILE_DATA(item)} disableLink={true} userHandle={item?.userHandle} />
  );
};

MembersList.propTypes = {
  item: PropTypes.object,
};

const GuestContributor = () => (
  <Guest>
    <Icon type={ICON_TYPE.users} size={ICON_SIZE.small} />
    <FormattedMessage id="Dashboard.ActivityLog.Body.GuestContributor" />
  </Guest>
);

const Guest = styled.div`
  padding: 1.5rem;
  text-align: center;

  i {
    margin-right: 10px;
  }
`;

const Count = styled.div`
  display: block;
  width: 1.5rem;
  font-weight: 400;
  color: ${colors.grayAnatomyLight1};
`;

const MemberCell = styled(Grid.col)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > * {
    margin-top: 0 !important;
  }
`;

const PopoverCard = styled(Card)`
  padding: 0 2rem;
  margin: 0;
  width: ${({ width }) => width || "30rem"};
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  ${({ paddingBottom }) => paddingBottom && "padding-bottom: 1rem;"};
`;

const DateCell = styled(Grid.col)`
  background: ${({ isWeekend }) => (isWeekend ? colors.grayAnatomyLight7 : colors.white)};
  padding: 0.1rem;
`;

const styles = {
  placeholder_container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 1rem 1rem;
    gap: 1rem;
  `,
  container: (isMembersView, marginSize, forceAspectRadio, cols) => css`
    width: 100%;
    border: 1px solid ${colors.grayAnatomyLight4};
    border-left-width: 0;
    border-right-width: 0;
    background: ${colors.grayAnatomyLight4};
    grid-auto-rows: 1fr;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    grid-template-columns: repeat(${isMembersView ? cols + 3 : cols}, minmax(0, 1fr));

    ${!isMembersView &&
    marginSize &&
    `
      width: calc(100% - ${marginSize});
      margin-left: ${marginSize};
    `}
    ${isMembersView &&
    forceAspectRadio &&
    `
      // Simple trick to make cells perfectly square
      &::before {
        content: "";
        width: 0;
        padding-bottom: 100%;
        grid-row: 1 / 1;
        grid-column: 1 / 1;
      }

      > *:first-child {
        grid-row: 1 / 1;
      }
    `}

    > * {
      background: #fff;
    }
  `,
};

ActivityLogBody.propTypes = {
  data: PropTypes.array,
  cols: PropTypes.number,
  renderer: PropTypes.func,
  numRows: PropTypes.number,
  isMembersView: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.object,
  marginSize: PropTypes.string,
  forceAspectRadio: PropTypes.bool,
  showAll: PropTypes.bool,
};

ActivityLogBody.defaultProps = {
  renderer: ActivityLogCell,
  cols: 7,
  marginSize: margins.normal,
  numRows: 3,
  isMembersView: true,
  forceAspectRadio: true,
};

export default ActivityLogBody;

export { PopoverCard };
