import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors, fontSize } from "style";
import { H1 } from "components/Text";
import { MarketingButton } from "components/Buttons";
import { Link } from "components/Links";
import { LINKS } from "constants/index";

/**
 * MissionRolesPublicFooter
 */
const MissionRolesPublicFooter = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid rowGap={`2rem`}>
        <Grid.col start={4} end={10} css={styles.item_container}>
          <H1 isPublic css={styles.title}>
            <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFooter.Title" />            
          </H1>
        </Grid.col>   
        <Grid.col start={4} end={10} css={styles.item_container}>
          <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFooter.Body" />            
        </Grid.col>        
        <Grid.col start={4} end={10} css={[styles.item_container, styles.signup]}>
          <Link to={LINKS.signup}>
            <MarketingButton>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFooter.Button" />
            </MarketingButton>
          </Link>
        </Grid.col>
      </Grid>       
    </NarrowContainer>
  </div>
);

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.white};
    padding: 8rem 0 8rem 0;
    font-size: ${fontSize.normal};
  `,
  title: css`
    margin:0;
  `,
  item_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    text-align:center;
  `, 
  end: css`
    justify-content:flex-end;

     @media (max-width: ${breakpoints.mobile}) {
      justify-content:center;
      text-align:center;
    }
  `,
  signup: css`
    margin-bottom:8rem;
  `,
  copyright: css`
    @media (max-width: ${breakpoints.mobile}) {
      text-align:center;
    }
  `
};

export default MissionRolesPublicFooter;
