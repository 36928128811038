import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { LINKS } from "constants/index";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { PrimaryButton } from "components/Buttons";
import { useAuth } from "hooks";
import { ApplyForm, ProxyApplyForm } from "components/Missions/Roles";
import { Spinner } from "components/Loader";

/**
 * Apply Button
 *
 * @params {any}        children
 * @params {Object}     role
 * @params {Boolean}    roleLoading
 * @params {Boolean}    canApplyOnBehalf
 */
const ApplyButton = ({ children, roleLoading, role, canApplyOnBehalf, ...props }) => {
  const navigate = useNavigate();
  const { isAdmin, isClient, isShowcase, profileId } = useAuth();
  const [showForm, setShowForm] = useState(false);
  const [showOnBehalfForm, setShowOnBehalfForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [missionRoleApplicant, setMissionRoleApplicant] = useState(role?.missionRoleApplicant);
  const handleLoading = (isLoading) => setLoading(isLoading);
  const handleFormOpenClose = (state = true) => (canApplyOnBehalf ? setShowOnBehalfForm : setShowForm)?.(state);
  const isDisabled = showForm || loading || roleLoading || isAdmin || isClient || isShowcase;

  const handleClick = () => {
    if (missionRoleApplicant) {
      navigate(LINKS.mission_application(role?.missionRoleApplicant?.uuid));
    } else {
      handleFormOpenClose();
    }
  };

  useEffect(() => {
    setMissionRoleApplicant(role?.missionRoleApplicant);
  }, [role?.missionRoleApplicant]);

  const FormComponent = canApplyOnBehalf ? ProxyApplyForm : ApplyForm;

  return (
    <>
      <PrimaryButton onClick={handleClick} disabled={isDisabled} {...props}>
        {showForm && loading ? (
          <Spinner size="2rem" />
        ) : missionRoleApplicant ? (
          <FormattedMessage id="Missions.MissionRole.ApplyButton.LabelApplied" />
        ) : (
          <>
            {children}
            <Icon size={ICON_SIZE.medium} type={ICON_TYPE.arrowRight} color="inherit" />
          </>
        )}
      </PrimaryButton>
      <FormComponent
        onClose={() => handleFormOpenClose(false)}
        show={canApplyOnBehalf ? showOnBehalfForm : showForm}
        role={role}
        profileId={profileId}
        onLoading={handleLoading}
      />
    </>
  );
};

ApplyButton.propTypes = {
  children: PropTypes.any,
  role: PropTypes.object,
  roleLoading: PropTypes.bool,
  canApplyOnBehalf: PropTypes.bool,
};

export default ApplyButton;
