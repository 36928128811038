import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "components/Image";
import { TextLoader } from "components/Loader";
import { css } from "@emotion/react";
import { colors } from "style";
import { getFullName } from "utils/Format";
import OpenToHireAsset from "assets/images/overlay_open_to_hire.png";
import VideoInterviewsBadge from "assets/images/badge_video_interviews.svg";
import PartnerBadge from "assets/images/badge_partner.svg";
import Tooltip from "components/Tooltip";
import { useAuth } from "hooks";

/**
 * UserPhoto
 *
 * @param {Object}   data
 * @param {Boolean}  loading
 * @param {Number}   width
 * @param {Number}   height
 * @param {Boolean}  showStatus
 * @param {String}   borderRadius
 * @param {Boolean}  showInterviews
 * @param {Boolean}  showPartnerName
 */
const UserPhoto = ({
  data,
  loading,
  width,
  height,
  showStatus,
  borderRadius,
  showInterviews,
  showPartnerName,
  ...props
}) => {
  const [hasError, setHasError] = useState(false);
  const { isAdmin } = useAuth();

  if (loading || !data) {
    return <PlaceHolder width={width} height={height} />;
  }

  return (
    <div css={styles.container} {...props}>
      <div css={styles.image_container(width, height, borderRadius)}>
        {data?.avatarUrl && !hasError ? (
          <Image
            url={data?.avatarUrl}
            css={styles.image}
            onError={() => setHasError(true)}
            onLoad={() => setHasError(false)}
            alt={getFullName(data)}
            width="100%"
            height="100%"
          />
        ) : (
          <div className="initials" css={styles.initials(width, data?.firstName)}>
            {`${data?.firstName?.charAt(0)}${data?.lastName?.charAt(0)}`}
          </div>
        )}
      </div>
      {showStatus && <div css={styles.indicator(data.isOnline)} />}
      {data?.openToEmployment && (
        <div css={styles.employment_container}>
          <Tooltip tooltipId="User.UserPhoto.OpenToHire" hideIcon>
            <Image css={styles.open_to_hire} url={OpenToHireAsset} />
          </Tooltip>
        </div>
      )}
      {showInterviews && data?.canShowInterviews && data?.completedInterviewsCount > 0 && (
        <div css={styles.video_container}>
          <div>
            <Tooltip tooltipId="User.UserPhoto.HasVideos" hideIcon>
              <Image url={VideoInterviewsBadge} width="100%" height="100%" />
            </Tooltip>
          </div>
        </div>
      )}
      {isAdmin && data?.partner?.id && (
        <div css={[styles.video_container, styles.partner_container]}>
          <Tooltip tooltipId={showPartnerName ? data?.partner?.name : "User.UserPhoto.IsPartnerProfile"} hideIcon>
            <Image url={PartnerBadge} width="100%" height="100%" />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

/**
 * Placeholder
 *
 * @params {Number}   width
 * @params {Number}   height
 * @params {String}   borderRadius
 */
const PlaceHolder = ({ width, height, borderRadius }) => (
  <TextLoader css={styles.image_container(width, height, borderRadius)} />
);

const styles = {
  container: css`
    position: relative;
  `,
  employment_container: css`
    position: absolute;
    width: calc(100% + 15%);
    height: calc(100% + 15%);
    top: -7.5%;
    left: -7.5%;
  `,
  video_container: css`
    position: absolute;
    width: 30%;
    height: 30%;
    min-width: 2.25rem;
    min-height: 2.25rem;
    max-width: 5rem;
    max-height: 5rem;
    bottom: -0.5em;
    right: -0.5em;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  partner_container: css`
    left: -0.5em;
    right: auto;
  `,
  image_container: (width, height, borderRadius) => css`
    width: ${width};
    height: ${height};
    position: relative;
    border-radius: ${borderRadius};
    overflow: hidden;
  `,
  image: css`
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
  `,
  initials: (size, firstName) => css`
    width: 100%;
    height: 100%;
    background: ${getColor(firstName)};
    border-radius: 0.6rem;
    color: ${colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${parseInt(size) / 4}rem;
    font-weight: 500;
    text-transform: uppercase;
  `,
  indicator: (isOnline) => css`
    position: absolute;
    border: 0.2rem #fff solid;
    width: 20%;
    height: 20%;
    border-radius: 100%;
    background: ${isOnline ? colors.green : colors.warning};
    top: 0.25rem;
    right: 0.25rem;
  `,
  open_to_hire: css`
    width: 100%;
    height: 100%;
  `,
};

function getColor(firstName) {
  const firstChar = firstName?.charAt(0);

  if (!firstChar) {
    return colors.purpleRainBase;
  }

  if (firstChar.match(/[a-f]/i)) {
    return colors.pinkPantherBase;
  } else if (firstChar.match(/[g-p]/i)) {
    return colors.orangeCountyBase;
  } else if (firstChar.match(/[q-v]/i)) {
    return colors.blueVelvetBase;
  } else {
    return colors.purpleRainBase;
  }
}

UserPhoto.defaultProps = {
  width: `18rem`,
  height: `18rem`,
  borderRadius: `100%`,
  showStatus: false,
  showInterviews: false,
  showPartnerName: false,
};

UserPhoto.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  showStatus: PropTypes.bool,
  showInterviews: PropTypes.bool,
  showPartnerName: PropTypes.bool,
};

PlaceHolder.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
};

export default UserPhoto;
