const SORT = {
  asc: "asc",
  desc: "desc",
};

const APPLICANT_LIST_SORTING_OPTIONS = [
  {
    label: "Relevancy",
    value: "relevancy",
  },
  {
    label: "Profile Name",
    value: "profileName",
  },
  {
    label: "Date",
    value: "date",
  },
];

export default SORT;

export { APPLICANT_LIST_SORTING_OPTIONS };
