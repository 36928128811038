import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors, fontSize } from "style";
import { LINKS } from "constants";

/**
 * PublicFooter
 */
const PublicFooter = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid rowGap={`2rem`}>
        <Grid.col start={1} end={5} css={styles.copyright}>
          <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFooter.Copyright" />              
        </Grid.col>
        <Grid.col start={5} end={9} css={styles.item_container}>
          <a href={LINKS.privacy} target="_blank">
            <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFooter.PrivacyPolicy" />    
          </a>
        </Grid.col>
        <Grid.col start={9} end={13} css={[styles.item_container, styles.end]}>
          <a href={LINKS.terms_and_conditions} target="_blank">
            <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFooter.Terms" />                   
          </a>
        </Grid.col>
      </Grid>
    </NarrowContainer>
  </div>
);

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.white};
    padding: 8rem 0 8rem 0;
    font-size: ${fontSize.normal};
  `,
  title: css`
    margin:0;
  `,
  item_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    text-align:center;
  `, 
  end: css`
    justify-content:flex-end;

     @media (max-width: ${breakpoints.mobile}) {
      justify-content:center;
      text-align:center;
    }
  `,
  signup: css`
    margin-bottom:8rem;
  `,
  copyright: css`
    @media (max-width: ${breakpoints.mobile}) {
      text-align:center;
    }
  `
};

export default PublicFooter;
  ;
