import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";
import { StatusIcon, ICON_TYPE, ICON_SIZE } from "components/Icon";
import { PrimaryLink } from "components/Links";
import { TextLoader } from "components/Loader";

/**
 * RenderIconWithText
 *
 * @param {Element}    icon
 * @param {Object}     text
 * @param {String}     size
 * @param {Boolean}    isComplete
 * @param {Object}     status
 * @param {Boolean}    hasLink
 * @param {Function}   onClick
 * @param {Function}   loading
 *
 * Example Usage
 *
     <RenderIconWithText
       key={index}
       size={RENDER_SIZE.large}
       icon={
         <Icon
           ...
         />
       }
       text={{
         title: (
           <FormattedMessage
             ...
           />
         ),
         description: (
           <FormattedMessage
             ...
           />
         ),
         link: (
           <FormattedMessage
             ...
           />
         ),
         message: (
           <FormattedMessage
             ...
           />
         ),
       }}
       onClick={() => ...}
       status={{
         showIcon: true,
         success: false,
         fail: true
       }}
     />
 */
const RenderIconWithText = ({
  icon,
  text: { title, description, link, message, details },
  size,
  status,
  hasLink,
  onClick,
  loading,
  ...props
}) => {
  if (loading) {
    return <Placeholder size={size} icon={icon} {...props} />;
  }

  return (
    <div css={styles.container(hasLink)} onClick={(...args) => hasLink && onClick?.(...args)} {...props}>
      <div>
        <IconContainer icon={icon} size={size} status={status} />
      </div>
      <div css={styles.text_container} className="text-container">
        <Text title={title} description={description} size={size} />
        {status?.success === true || status?.fail === true ? (
          <Message message={message} status={status} hasLink={hasLink} />
        ) : onClick && hasLink ? (
          <MoreLink link={link} onClick={(...args) => hasLink && onClick?.(...args)} />
        ) : null}
      </div>
      {details && (
        <div css={styles.details} className="text-container">
          {details}
        </div>
      )}
    </div>
  );
};

/**
 * Placeholder
 *
 * @param {String}     size
 */
const Placeholder = ({ size, ...props }) => (
  <div css={styles.container(false)} {...props}>
    <div>
      <IconContainer size={size} />
    </div>
    <div css={styles.text_container} className="text-container">
      <div css={styles.text(size)}>
        <TextLoader
          css={css`
            width: 80%;
          `}
        />
      </div>
    </div>
  </div>
);

Placeholder.propTypes = {
  size: PropTypes.string,
};

/**
 * MoreLink
 *
 * @param {Any}       link
 * @param {Function}  onClick
 */
const MoreLink = ({ link, onClick }) => (
  <div css={styles.end_container(link)}>
    <div css={styles.end_text_container}>
      {link && (
        <PrimaryLink onClick={onClick} withMoreIcon>
          {link}
        </PrimaryLink>
      )}
    </div>
  </div>
);

MoreLink.propTypes = {
  link: PropTypes.any,
  onClick: PropTypes.func,
};

/**
 * Text
 *
 * @param {Any}      title
 * @param {Any}      description
 * @param {String}   size
 */
const Text = ({ title, description, size }) => (
  <div css={styles.text(size)}>
    <div css={styles.title}>{title}</div>
    {description && <div css={styles.description}>{description}</div>}
  </div>
);

Text.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  size: PropTypes.string,
};

/**
 * Message
 *
 * @param {Any}        message
 * @param {Object}     status
 * @param {Boolexan}    hasLink
 */
const Message = ({ message, status, hasLink }) => (
  <div css={styles.end_container(false)}>
    <div css={styles.end_text_container}>
      <span css={status?.success === true ? styles.text_success : styles.text_fail}>
        {hasLink ? <MoreLink link={message} /> : message}
      </span>
    </div>
  </div>
);

Message.propTypes = {
  message: PropTypes.any,
  status: PropTypes.object,
  hasLink: PropTypes.bool,
};

/**
 * IconContainer
 *
 * @param {Element}    icon
 * @param {String}     size
 * @param {Object}     status
 */
const IconContainer = ({ icon, size, status }) => (
  <div css={styles.icon_container(size)}>
    {icon}
    {status?.showIcon && (
      <div css={styles.status_icon_container}>
        <StatusIcon
          type={ICON_TYPE.checkmark}
          size={ICON_SIZE.medium}
          color={colors.grayAnatomyLight2}
          isComplete={status?.success === true}
          isError={status?.fail === true}
        />
      </div>
    )}
  </div>
);

IconContainer.propTypes = {
  icon: PropTypes.element,
  size: PropTypes.string,
  status: PropTypes.object,
};

const styles = {
  container: (hasLink) => css`
    display: flex;
    align-items: center;
    width: 100%;
    color: ${colors.purpleRainDark2};
    transition: all 0.3s ease;
    cursor: ${hasLink ? `pointer` : `inherit`};

    &:hover {
      color: ${hasLink && colors.purpleRainBase};
    }

    &:last-of-type .text-container {
      border-bottom-width: 0;
    }
  `,
  icon_container: (size) => css`
    width: ${size};
    height: ${size};
    background: ${colors.grayAnatomyLight4};
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `,
  text_container: css`
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
    width: 100%;
    height: 100%;
    margin-left: 1.5rem;
    display: flex;
  `,
  details: css`
    margin-left: auto;
    text-align: right;
    margin-left: auto;
    line-height: 1.4;
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
    height: 100%;
    padding: 1.25rem 0 1.25rem 1rem;
    font-size: ${fontSize.small};
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
  `,
  text: (size) => css`
    width: 100%;
    min-height: ${size};
    padding: 1rem 0;
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
  title: css`
    font-size: ${fontSize.small};
    font-family: Matter;
    font-weight: 600;
    line-height: 1.25;
  `,
  description: css`
    color: ${colors.purpleRainLight4};
    font-weight: 400;
    font-size: ${fontSize.xsmall};
    line-height: normal;
  `,
  text_success: css`
    color: ${colors.green};
  `,
  text_fail: css`
    color: ${colors.red};
  `,
  end_text_container: css`
    white-space: nowrap;
    padding-right: 0.5rem;
    font-size: ${fontSize.small};
    font-weight: 600;
    padding-left: 1rem;
  `,
  end_container: (hasLink) => css`
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${hasLink ? colors.purpleRainBase : colors.grayAnatomyLight2};
    cursor: ${hasLink ? `pointer` : `auto`};
    font-size: ${fontSize.small};
  `,
  status_icon_container: css`
    width: 3rem;
    height: 3rem;
    border: 3px #fff solid;
    position: absolute;
    bottom: -3px;
    right: -3px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
};

const RENDER_SIZE = {
  xsmall: `2.4rem`,
  small: `3.2rem`,
  large: `6rem`,
  xlarge: `9.5rem`,
};

RenderIconWithText.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.object.isRequired,
  size: PropTypes.string,
  status: PropTypes.object,
  hasLink: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

RenderIconWithText.defaultProps = {
  height: RENDER_SIZE.small,
  hasLink: true,
  text: {},
};

export { RENDER_SIZE };

export default RenderIconWithText;
