import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useApplicants, useMissionRoleApplicant } from "hooks";
import { Select } from "components/Form";

/**
 * ApplicationUpdateSelect
 *
 * @param {Object}  missionRoleApplicant
 * @param {Boolean} loading
 */
const ApplicationUpdateSelect = ({ missionRoleApplicant, loading, ...props }) => {
  const [state, setState] = useState(missionRoleApplicant?.state);
  const { getUpdateOptionsForApplicant } = useApplicants({ skipQuery: true });
  const { updateApplicantState, updatingState } = useMissionRoleApplicant({});

  useEffect(() => {
    setState(missionRoleApplicant?.state);
  }, [missionRoleApplicant]);

  const handleStateChange = (_name, val) => {
    setState(val);
    updateApplicantState(missionRoleApplicant?.id, val);
  };

  return (
    <Select
      value={state}
      loading={loading || updatingState}
      placeholder="State"
      options={getUpdateOptionsForApplicant(state).map((option) => ({
        value: option,
        label: <FormattedMessage id={`Missions.MissionCandidateList.State.${option}`} />,
      }))}
      onChange={handleStateChange}
      valueKey="value"
      labelKey="label"
      {...props}
    />
  );
};

ApplicationUpdateSelect.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ApplicationUpdateSelect;
