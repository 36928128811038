import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { colors } from "style";

/**
 * MarketingButton
 *
 * @param {Object} children
 */
const MarketingButton = ({ children, ...props }) => (
  <button css={styles.button} {...props}>
    {children}
  </button>
);

const styles = {
  button: css`
    height: 5rem;
    padding: 0 2.5rem;
    color: white;
    border: 0;
    border-radius: 2.5rem;
    color: ${colors.grayAnatomyDark};
    font-size: 1.5rem; // for consistency with website
    font-weight: 500;
    background: rgb(242, 243, 249);
    transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    position: relative;
    overflow: hidden;

    &:after {
      content: "";
      background-color: rgba(0, 0, 0, 0.02);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition-duration: 0.3s;
      transition-property: transform;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      width: 100%;
      transform: translateY(100%);
    }

    &:hover:after {
      transform: translateY(0);
    }
  `,
};

MarketingButton.propTypes = {
  children: PropTypes.object,
};

export default MarketingButton;
