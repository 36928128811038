import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { APPLICATION_STEPS } from "constants/index";
import Card from "components/Card";
import DisplayBox from "components/DisplayBox";
import { Col, Grid, Row } from "components/Containers";
import { RoleEarningPotential } from "components/Missions/Roles";
import { PreferredRateTag } from "components/Profile";
import { useAuth, useMissionRoleRecommendation } from "hooks";
import { TAG_SIZE, TagComplete, TagIncomplete } from "components/Tags";
import ApplicationEditForm from "./ApplicationRateForm";
import { css } from "@emotion/react";
import { colors } from "style";

/**
 * ApplicationEditableRate
 *
 * @param {Object}    missionRoleApplicant
 * @param {Boolean}   rateEditing
 * @param {Function}  setRateEditing
 */
const ApplicationEditableRate = ({ missionRoleApplicant, setRateEditing, rateEditing, ...props }) => {
  const sectionCompleted = missionRoleApplicant?.applicationChecklist?.find(
    (step) => step.name === APPLICATION_STEPS.application_rate
  )?.fulfilled;
  const { profileId, canViewEditRate } = useAuth();
  const {
    rate,
    currency,
    averageExperience,
    applicantsCount,
    loading: recommendationLoading,
  } = useMissionRoleRecommendation({
    profileId,
    missionRoleId: missionRoleApplicant?.missionRole?.id,
    skip: !canViewEditRate,
  });

  return (
    <>
      <Grid.col start={1} end={13}>
        <Card>
          <DisplayBox
            title={<FormattedMessage id="Missions.Applications.ApplicationEditableRate.Title" />}
            editButtonText={<FormattedMessage id="Missions.Applications.ApplicationEditableRate.Button" />}
            isEmpty={!missionRoleApplicant?.rate?.amount}
            description={
              <Col gap="1rem">
                <FormattedMessage id="Missions.Applications.ApplicationEditableRate.Description" />
                <RoleEarningPotential
                  {...{
                    rate,
                    currency,
                    averageExperience,
                    applicantsCount,
                    targetHours: missionRoleApplicant?.missionRole?.engagementTime,
                    parentComponent: EarningsContainer,
                  }}
                />
              </Col>
            }
            subtitle={!sectionCompleted ? <TagIncomplete /> : <TagComplete />}
            numColumns={1}
            data={[
              {
                title: <FormattedMessage id="Missions.Applications.ApplicationEditableRate.Label" />,
                body: <PreferredRateTag rate={missionRoleApplicant?.rate} size={TAG_SIZE.medium} />,
              },
            ]}
            loading={recommendationLoading}
            onEdit={() => setRateEditing(true)}
            onAdd={() => setRateEditing(true)}
            {...props}
          />
        </Card>
      </Grid.col>

      {rateEditing && (
        <ApplicationEditForm
          missionRoleApplicant={missionRoleApplicant}
          show={rateEditing}
          onClose={() => setRateEditing(false)}
        />
      )}
    </>
  );
};

const EarningsContainer = ({ children }) => <Row css={styles.earnings_container}>{children}</Row>;

const styles = {
  earnings_container: css`
    background: ${colors.grayAnatomyLight5};
    padding: 1rem;
    border-radius: 0.6rem;
  `,
};

EarningsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

ApplicationEditableRate.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
  rateEditing: PropTypes.bool,
  setRateEditing: PropTypes.func,
};

export default ApplicationEditableRate;
