import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useAuth, useProfile } from "hooks";
import { MEMBER_STATUS } from "constants/index";
import { ProfileBox, ProfileRateAndAvailability, ProfileAvailability } from "components/Profile";
import { Grid } from "components/Containers";
import Card from "components/Card";
import { CandidateTitle } from "components/Candidate";

/**
 * Profile Page
 */
const Profile = () => {
  const { profileId, canViewEditRate } = useAuth();
  const intl = useIntl();
  const { data, loading } = useProfile({ profileId });
  const profileStatus = data?.profile?.status;
  const canEditProfile = profileStatus !== MEMBER_STATUS.reviewing;
  const keyName = "profile";

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Candidate.Profile.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <CandidateTitle title="Candidate.Profile.Title" description="Candidate.Profile.Body" profileId={profileId} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <ProfileBox id={profileId} isEditable={canEditProfile} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            {canViewEditRate ? (
              <ProfileRateAndAvailability data={data && data[keyName]} loading={loading} isEditable={canEditProfile} />
            ) : (
              <ProfileAvailability data={data && data[keyName]} loading={loading} isEditable={canEditProfile} />
            )}
          </Card>
        </Grid.col>
      </Grid>
    </>
  );
};

export default Profile;
