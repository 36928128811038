import React, { useState, memo } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { DatePicker } from "components/Form";
import { MemberFeedbackTable, PulseTable, SquadDynamicsTable } from "components/Squads/Pulse";
import { DATE_FORMAT_TYPE } from "constants/index";
import moment from "moment";

/**
 * Pulse Page
 */
const Pulse = () => {
  const intl = useIntl();
  const { id: teamId } = useParams();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Squads.Pulse.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <PulseSection teamId={teamId} />
        <SquadDynamicsSection teamId={teamId} />
        <MemberFeedbackSection teamId={teamId} />
      </PageWrapper>
    </>
  );
};

const PulseSection = memo(({ teamId }) => {
  const currentDate = moment();
  const startDate = currentDate.clone().subtract(1, "year").startOf("month").format("YYYY-MM-DD");
  const endDate = currentDate.clone().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
  const [pulseDateRange, setPulseDateRange] = useState([startDate, endDate]);

  return (
    <Grid>
      <Grid.col start={1} end={13}>
        <PageTitle
          subtitle={<FormattedMessage id="Squads.Pulse.Title" />}
          actionBar={
            <DatePicker
              value={pulseDateRange}
              onChange={(_, range) => setPulseDateRange(range)}
              maxDate={new Date(endDate)}
              enableMonthRangePicker
              enableDateCycle
            />
          }
        />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <PulseTable dateRange={pulseDateRange} teamId={teamId} />
      </Grid.col>
    </Grid>
  );
});

PulseSection.displayName = "PulseSection";

PulseSection.propTypes = {
  teamId: PropTypes.string,
};

const SquadDynamicsSection = memo(({ teamId }) => {
  const previousMonthDate = moment().subtract(1, "months").format("YYYY-MM-01");
  const [squadDynamicsDate, setSquadDynamicsDate] = useState(previousMonthDate);

  return (
    <Grid>
      <Grid.col start={1} end={13}>
        <PageTitle
          subtitle={<FormattedMessage id="Squads.Pulse.SquadDynamics" />}
          actionBar={
            <DatePicker
              value={squadDynamicsDate}
              onChange={(_, date) => setSquadDynamicsDate(date)}
              maxDate={new Date()}
              format={DATE_FORMAT_TYPE.MMyyyy}
              enableMonthPicker
              enableDateCycle
              showMonthYearPicker
            />
          }
        />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <SquadDynamicsTable date={squadDynamicsDate} teamId={teamId} />
      </Grid.col>
    </Grid>
  );
});

SquadDynamicsSection.displayName = "SquadDynamicsSection";

SquadDynamicsSection.propTypes = {
  teamId: PropTypes.string,
};

const MemberFeedbackSection = memo(({ teamId }) => {
  return (
    <Grid>
      <Grid.col start={1} end={13}>
        <PageTitle subtitle={<FormattedMessage id="Squads.Pulse.MemberFeedback" />} />
      </Grid.col>
      <Grid.col start={1} end={13}>
        <MemberFeedbackTable teamId={teamId} />
      </Grid.col>
    </Grid>
  );
});

MemberFeedbackSection.displayName = "MemberFeedbackSection";

MemberFeedbackSection.propTypes = {
  teamId: PropTypes.string.isRequired,
};

export default Pulse;
