import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Row } from "components/Containers";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { colors, fontSize } from "style";
import { formatDate } from "utils";

/**
 * OnboardedOffboardedDates
 *
 * @param {String} onboardedDate
 * @param {String} offboardedDate
 */
const OnboardedOffboardedDates = ({ onboardedDate, offboardedDate }) => {
  return (
    <Row css={styles.container}>
      {onboardedDate && (
        <Row css={styles.item}>
          <Row css={styles.icon_container(colors.green)}>
            <Icon type={ICON_TYPE.timerStart} size={ICON_SIZE.small} color="#fff" />
          </Row>
          <span>{formatDate(onboardedDate)}</span>
        </Row>
      )}
      {offboardedDate && (
        <Row css={styles.item}>
          <Row css={styles.icon_container(colors.red)}>
            <Icon type={ICON_TYPE.timerStop} size={ICON_SIZE.small} color="#fff" />
          </Row>
          <span>{formatDate(offboardedDate)}</span>
        </Row>
      )}
    </Row>
  );
};

const styles = {
  container: css`
    font-size: ${fontSize.xsmall};
    font-weight: 500;
  `,
  icon_container: (color) => css`
    width: 1.5rem;
    height: 1.5rem;
    align-items: center;
    justify-content: center;
    background: ${color};
    border-radius: 100%;
  `,
  item: css`
    gap: 0.5rem;
  `,
};

OnboardedOffboardedDates.propTypes = {
  onboardedDate: PropTypes.string,
  offboardedDate: PropTypes.string,
};

export default OnboardedOffboardedDates;
