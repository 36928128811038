import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors,  } from "style";
import { H1, MarketingHeader } from "components/Text";
import CheckList from "components/CheckList";
import { Link } from "components/Links";
import { MarketingButton } from "components/Buttons";
import { BANNER_TYPE, LINKS, MISSION_ROLE_STATES } from "constants/index";
import { TextLoader } from "components/Loader";
import Background from "assets/images/main-hero-bg.webp";
import { UserLocation } from "components/User";
import { Banner } from "components/Banner";

/**
 * MissionRolesPublicHeader
 * 
 * @param {object} role
 * @param {boolean} loading
 */
const MissionRolesPublicHeader = ({ role, loading }) => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid>       
        <Grid.col start={1} end={7}>
          <Col gap="0rem">
            <MarketingHeader>
              <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHeader.Header" />
            </MarketingHeader>
            <H1 isPublic css={styles.title} loading={loading}>
              {role?.name}
            </H1>
            <CheckList data={!loading ? [
              ...formatList(role?.disciplines, 'Missions.Roles.Public.MissionsRolesPublicHeader.Disciplines'),
              ...formatList(role?.primarySkills, 'Missions.Roles.Public.MissionsRolesPublicHeader.Skills'),
              ...formatList(role?.secondarySkills, 'Missions.Roles.Public.MissionsRolesPublicHeader.SecondarySkills'),
              ...formatList(role?.locations, 'Missions.Roles.Public.MissionsRolesPublicHeader.Region'),
              role?.location ? <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicHeader.Location" values={{ data: <UserLocation data={role} /> }} /> : null,
              role?.engagementType ? <FormattedMessage id={`Missions.Roles.RoleDetails.EngagementType.${role?.engagementType}`} /> : null,
              <FormattedMessage
                id="Missions.Roles.Public.MissionsRolesPublicHeader.WorkArrangement"
                values={{
                  data: <FormattedMessage id={`Missions.MissionRole.WorkArrangement.${role?.workArrangement}`} />
                }}
              />,
              ].filter(Boolean) : [
                <TextLoader width="20rem" />,
                <TextLoader width="20rem" />,
                <TextLoader width="20rem" />,
                <TextLoader width="20rem" />,
              ]} 
            />
            {role?.state === MISSION_ROLE_STATES.published || loading ? <div css={styles.button_container}>
              <Link to={`${LINKS.signup}?role=${role?.slug}`}>
                <MarketingButton loading={loading}>
                  <FormattedMessage id="Missions.Apply" />
                </MarketingButton>
              </Link>
            </div> : (
              <Banner type={BANNER_TYPE.warning} isClosable={false}>
                <FormattedMessage
                  id="Missions.Roles.Public.MissionsRolesPublicHeader.BannerClosed"
                  values={{
                    link: <Link to={LINKS.signup} routerEnabled>here</Link>,
                  }}
                />
              </Banner>
            )}
          </Col>
        </Grid.col>
        <Grid.col start={7} end={13}>
        </Grid.col>
      </Grid>
    </NarrowContainer>
  </div>
);

const formatList = (data, messageId) => {
  if (!data || data.length === 0) {
    return []
  } else {
    return [
      <FormattedMessage 
        id={messageId}
        values={{ 
          data: data?.map(({ name }) => name)?.join(', ')
        }}
      /> 
    ]
  }
}

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.white};
    padding: 21rem 0 12rem 0;
    background: ${colors.white} url(${Background}) no-repeat center center;
    background-size: 50%;
    background-position: bottom right;
    margin-top:-9rem;
    z-index:2;
    
    @media (max-width: ${breakpoints.mobile}) {
      padding: 4rem 0;
      background:#fff;
      margin-top:0;
    }
  `,
  title: css`
    margin-bottom:2rem; 
  `,
  button_container: css`
    margin-top:2rem;
  `,
  frame: css`
    width: 100%;
    height: 40rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-top: 3rem;
      height: 17rem;
    }
  `,
};

MissionRolesPublicHeader.propTypes = {
  role: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default MissionRolesPublicHeader;
