import React, { useState } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useProfile, useProfileCodeChallenge } from "hooks";
import DisplayBox from "components/DisplayBox";
import RenderIconWithText, { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { colors, margins } from "style";
import { CHEATING_TYPES, CODE_CHALLENGE_STATUS, MEMBER_STATUS } from "constants/index";
import { ShowMore } from "components/Pagination";
import { convertToPercent } from "utils";
import { CodeChallengeDetailsModal, CodeChallengesPicker } from "components/CodeChallenges";
import { Col } from "components/Containers";

/**
 * ProfileCodeChallenge
 *
 * @params {String}   profileId
 * @params {Boolean}  canView
 */
const ProfileCodeChallenge = ({ profileId, canView, ...props }) => {
  const [selectedCodeChallenge, setSelectedCodeChallenge] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const { data: profileData, profileCompletion, loading: profileLoading } = useProfile({ profileId });
  const hasSkills = !profileLoading && profileCompletion?.skills?.isComplete;
  const { hasNextPage, handleNextPage, loading, data, handleAddChallengeResponse } = useProfileCodeChallenge({
    profileId: profileData?.profile?.id,
  });

  const noResults = !loading && (!data || data?.length === 0);
  const isRejected = !canView && profileData?.profile?.status === MEMBER_STATUS.rejected;
  const isEmpty = (!canView && !hasSkills) || noResults || isRejected;

  return (
    <>
      <DisplayBox
        id="CodeChallenge"
        title={<FormattedMessage id="Candidate.CandidateCodeChallenge.Title" />}
        emptyMsg={<FormattedMessage id="Candidate.CandidateCodeChallenge.Empty" />}
        editButtonText={<FormattedMessage id="Profile.CodeChallenges.SendCodeChallengeToMember.Button" />}
        onAdd={() => setShowForm(true)}
        rowGap={0}
        loading={loading}
        isEmpty={isEmpty}
        parentKeyName="profileId"
        parentKeyValue={profileId}
        numColumns={1}
        marginSize={margins.normal}
        data={data}
        itemRenderer={(
          { maxScore, score, cheatingFlag, challenge, status, reportUrl, type, ...props },
          _index,
          loading
        ) => {
          const challengePassed = status === CODE_CHALLENGE_STATUS.passed;
          const challengeFailed = status === CODE_CHALLENGE_STATUS.failed;
          const challengeCompleted = status === CODE_CHALLENGE_STATUS.completed;
          const isChallengeAttempted = challengePassed || challengeFailed || challengeCompleted;
          const obtainedScore = score / maxScore;
          const itemIcon = ICON_TYPE[challenge?.icon] || ICON_TYPE.code;
          const CODERBYTE = "Coderbyte";

          return (
            <>
              <RenderIconWithText
                key={challenge?.name}
                loading={loading}
                size={RENDER_SIZE.large}
                icon={<Icon type={itemIcon} size={ICON_SIZE.xxlarge} color={colors.purpleRainDark2} {...props} />}
                text={{
                  title: challenge?.name,
                  description: (
                    <Col>
                      {obtainedScore ? <span>{convertToPercent(obtainedScore)}</span> : null}
                      {cheatingFlag && (
                        <span css={styles.cheating(cheatingFlag)}>
                          <FormattedMessage
                            id={
                              cheatingFlag === CHEATING_TYPES.none
                                ? `CodeChallenges.CodeChallengeDetailsModal.ShortCheatingNone`
                                : cheatingFlag === CHEATING_TYPES.likely
                                ? `CodeChallenges.CodeChallengeDetailsModal.ShortCheatingLikely`
                                : `CodeChallenges.CodeChallengeDetailsModal.ShortCheatingDetected`
                            }
                          />
                        </span>
                      )}
                    </Col>
                  ),
                  link: (
                    <FormattedMessage
                      id={
                        challengeCompleted
                          ? `Candidate.CandidateCodeChallenge.AdminCompletedLink`
                          : `Candidate.CandidateCodeChallenge.AdminIncompleteLink`
                      }
                    />
                  ),
                }}
                hasLink={canView || !challengeCompleted}
                onClick={() => {
                  if (type === CODERBYTE && canView && isChallengeAttempted) {
                    setSelectedCodeChallenge({
                      maxScore,
                      score,
                      challenge,
                      status,
                      reportUrl,
                      type,
                      cheatingFlag,
                      ...props,
                    });
                  } else if (reportUrl) {
                    window.open(reportUrl, "_blank");
                  }
                }}
                status={{
                  showIcon: isChallengeAttempted,
                  success: challengePassed,
                  fail: challengeFailed,
                }}
              />
            </>
          );
        }}
        {...props}
      />
      <ShowMore
        loading={loading}
        hasNextPage={!isEmpty && !loading && hasNextPage}
        onNextPage={handleNextPage}
        text={<FormattedMessage id="Candidate.CandidateCodeChallenge.ShowMore" />}
      />
      {selectedCodeChallenge && (
        <CodeChallengeDetailsModal
          challengeResponse={selectedCodeChallenge}
          show={!!selectedCodeChallenge}
          onClose={() => setSelectedCodeChallenge(null)}
        />
      )}
      {showForm && (
        <CodeChallengesPicker
          show={showForm}
          onClose={() => setShowForm(false)}
          title={<FormattedMessage id="Candidate.CandidateCodeChallenge.CodeChallengesPicker.Title" />}
          info={<FormattedMessage id="Candidate.CandidateCodeChallenge.CodeChallengesPicker.Description" />}
          onItemClick={(challenge) => {
            handleAddChallengeResponse(
              { params: { challengeId: challenge.id }, profileId: profileId, forceCreate: true },
              challenge.name
            );
            setShowForm(false);
          }}
        />
      )}
    </>
  );
};

const styles = {
  cheating: (cheatingFlag) => css`
    color: ${cheatingFlag === CHEATING_TYPES.none
      ? colors.green
      : cheatingFlag === CHEATING_TYPES.likely
      ? colors.orange
      : colors.red};
  `,
};

ProfileCodeChallenge.propTypes = {
  profileId: PropTypes.string,
  canView: PropTypes.bool,
};

export default ProfileCodeChallenge;
