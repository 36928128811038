import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { formatDate } from "utils";
import { APPLICANT_TYPES, BANNER_TYPE } from "constants/index";
import { Banner } from "components/Banner";
import { Grid } from "components/Containers";

/**
 * ApplicationStateBanner
 *
 * @param {Object} application
 */
const ApplicationStateBanner = ({ application }) => {
  const { state, appliedAt } = application;

  return (
    <Grid.col start={1} end={13}>
      <Banner type={state === APPLICANT_TYPES.pending ? BANNER_TYPE.warning : BANNER_TYPE.success} isClosable={false}>
        <FormattedMessage
          id={`Missions.Applicants.ApplicantStateBanner.${state}`}
          values={{
            date: formatDate(appliedAt),
          }}
        />
      </Banner>
    </Grid.col>
  );
};

ApplicationStateBanner.propTypes = {
  application: PropTypes.shape({
    state: PropTypes.string.isRequired,
    appliedAt: PropTypes.string.isRequired,
  }).isRequired,
};

export default ApplicationStateBanner;
