import { createContext } from "react";
import { css } from "@emotion/react";

/**
 * This theme is designed to provide style overrides when required
 */
const themes = {
  app: {
    isPublic: false,
  },
  website: {
    isPublic: true,
    h1: css``,
    h2: css``,
    h3: css``,
    secondary: css``,
  },
};

const ThemeContext = createContext(themes.app);

export { themes, ThemeContext };
