import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Row from "components/Containers/Row";
import { colors, fontSize } from "style";
import Icon, { ICON_SIZE } from "components/Icon";

/**
 * MessageWithIcon
 *
 * @param {String} messageId
 * @param {String} iconType
 */
const MessageWithIcon = ({ messageId, iconType, ...props }) => (
  <Message>
    <IconContainer {...props}>
      <Icon type={iconType} size={ICON_SIZE.small} color="inherit" />
    </IconContainer>
    <FormattedMessage id={messageId} />
  </Message>
);

const Message = styled(Row)`
  color: ${colors.grayAnatomyBase};
  font-size: ${fontSize.xsmall};
`;

const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isSuccess, isError }) =>
    isSuccess ? colors.lightGreen : isError ? colors.lightRed : colors.lightYellow};
  color: ${({ isSuccess, isError }) => (isSuccess ? colors.green : isError ? colors.red : colors.darkYellow)};
`;

MessageWithIcon.propTypes = {
  messageId: PropTypes.string.isRequired,
  iconType: PropTypes.string.isRequired,
};

export default MessageWithIcon;
