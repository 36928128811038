import { gql } from "@apollo/client";
import {
  APPLICANT_RATE_FIELDS,
  MINIMAL_PROFILE_FIELDS,
  MINIMAL_MISSION_ROLE_FIELDS,
  CORE_MISSION_FIELDS,
  ORGANIZATION_FIELDS,
} from "graphql/fragments";

const GET_MISSION_ROLE_APPLICANTS = gql`
  ${MINIMAL_PROFILE_FIELDS}
  ${APPLICANT_RATE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  ${CORE_MISSION_FIELDS}
  ${ORGANIZATION_FIELDS}
  query missionRoleApplicants(
    $filters: MissionRoleApplicantFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: MissionRoleApplicantOrderBy
    $paginationInfo: PaginationInfo
    $includeSellingPriceEstimates: Boolean = false
    $useDataService: Boolean = true
  ) {
    missionRoleApplicants(
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      paginationInfo: $paginationInfo
      useDataService: $useDataService
    ) {
      totalCount
      shortlistedCount
      pendingCount
      appliedCount
      interviewingCount
      unselectedCount
      selectedCount
      invitedCount
      currentPage
      perPage
      nodes {
        id
        uuid
        state
        rateScore
        matchScore
        subScores
        createdAt
        appliedAt
        noticePeriod
        availablePartTimeDuringNotice
        organization {
          id
          slug
          name
        }
        profile {
          ...MinimalProfileFields
          status
          sellingPriceEstimate @include(if: $includeSellingPriceEstimates)
        }
        mission {
          ...MissionFields
        }
        organization {
          ...OrganizationDataFields
        }
        missionRole {
          ...MinimalMissionRoleFields
        }
        ...ApplicantRateFields
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

const GET_MISSION_ROLE_APPLICANTS_COUNT = gql`
  query missionRoleApplicants($filters: MissionRoleApplicantFilter) {
    missionRoleApplicants(filters: $filters) {
      totalCount
    }
  }
`;

const GET_MISSION_ROLE_INVITATIONS = gql`
  ${MINIMAL_PROFILE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  query missionRoleInvitations(
    $filters: MissionRoleInvitationFilter
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: MissionRoleInvitationOrderBy
    $paginationInfo: PaginationInfo
  ) {
    missionRoleInvitations(
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      paginationInfo: $paginationInfo
    ) {
      totalCount
      shortlistedCount
      pendingCount
      appliedCount
      interviewingCount
      unselectedCount
      selectedCount
      invitedCount
      currentPage
      perPage
      nodes {
        id
        rateScore
        matchScore
        subScores
        invitedAt
        acceptedAt
        invitedBy {
          firstName
          lastName
        }
        profile {
          ...MinimalProfileFields
          status
        }
        missionRole {
          ...MinimalMissionRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
        }
      }
    }
  }
`;

const GET_MISSION_ROLE_ELIGIBILITY = gql`
  query missionApplicantEligibilityCheck($profileId: ID!, $missionRoleId: ID!) {
    missionApplicantEligibilityCheck(profileId: $profileId, missionRoleId: $missionRoleId) {
      eligible
      unfulfilledPolicies
    }
  }
`;

const GET_MISSION_ROLE_RECOMMENDATION = gql`
  query missionRoleApplicantRecommendation($profileId: ID, $missionRoleId: ID!) {
    missionRoleApplicantRecommendation(profileId: $profileId, missionRoleId: $missionRoleId) {
      recommendedRate
      recommendedRateCurrency
      averageExperience
      applicantsCount
    }
  }
`;

const GET_MISSION_ROLE_RECOMMENDATIONS = gql`
  ${MINIMAL_PROFILE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  query missionRoleRecommendations(
    $filters: MissionRoleRecommendationFilter!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $paginationInfo: PaginationInfo
  ) {
    missionRoleRecommendations(
      filters: $filters
      first: $first
      last: $last
      after: $after
      before: $before
      paginationInfo: $paginationInfo
    ) {
      totalCount
      currentPage
      perPage
      nodes {
        rateScore
        matchScore
        subScores
        profileData {
          archetype
          disciplines
          country
          region
        }
        missionData {
          activeApplicationCount
          activeApplicationRoles {
            ...MinimalMissionRoleFields
          }
          invitationConversionRate
          missionCount
        }
        profile {
          ...MinimalProfileFields
          status
          partner {
            id
            name
          }
        }
        missionRole {
          ...MinimalMissionRoleFields
        }
        scoreData {
          activity {
            consideredDaysSinceLastActivity
          }
          experience {
            consideredExperienceYears
            rawExperienceYears
          }
          skills {
            matchingSkillCount
            matchingSkills
          }
          timeZone {
            consideredTimeZoneOffset
            rawTimeZoneOffset
          }
        }
        scores {
          matchActivityScore
          matchExperienceScore
          matchSkillsScore
          matchTimeZoneScore
        }
        hourlyRateData {
          consideredHourlyRateUsd
          consideredHourlyRateUsdDelta
          minimumApplicantHourlyRateUsd
          missionRoleTargetRateUsd
          profileHourlyRateUsd
          currency
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          profile {
            id
          }
        }
      }
    }
  }
`;

export {
  GET_MISSION_ROLE_APPLICANTS,
  GET_MISSION_ROLE_INVITATIONS,
  GET_MISSION_ROLE_RECOMMENDATIONS,
  GET_MISSION_ROLE_ELIGIBILITY,
  GET_MISSION_ROLE_RECOMMENDATION,
  GET_MISSION_ROLE_APPLICANTS_COUNT,
};
