import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useOutletContext } from "react-router-dom";
import { PageWrapper } from "components/Containers";
import { Grid } from "components/Containers";
import PageTitle from "components/PageTitle";
import { ApplicantListLayout } from "components/Missions/Applicants";
import { APPLICANT_LIST_COLUMNS } from "constants/index";
import { Spinner } from "components/Loader";

/**
 * Mission Role Applications
 */
const MissionRoleApplications = () => {
  const { selectedRole, loading } = useOutletContext();
  const intl = useIntl();

  if (loading) {
    return <Spinner />;
  }

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Role.Applications.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle subtitle={<FormattedMessage id="Missions.Roles.Applications.Title" />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          <ApplicantListLayout
            defaultSelected={{
              missionId: selectedRole?.mission?.id,
              missionRoleId: selectedRole?.id,
              organizationId: selectedRole?.mission?.organization?.id,
            }}
            showAdvancedOptions={true}
            showAdminOnlyPages={true}
            hideOrganizationFilter={true}
            hideMissionFilter={true}
            hideFilters={true}
            hideTabs={true}
            hideSorts={false}
            columns={[
              APPLICANT_LIST_COLUMNS.member,
              APPLICANT_LIST_COLUMNS.status,
              APPLICANT_LIST_COLUMNS.mission,
              APPLICANT_LIST_COLUMNS.profileRate,
              APPLICANT_LIST_COLUMNS.hourlyRateRange,
              APPLICANT_LIST_COLUMNS.applicationRate,
              APPLICANT_LIST_COLUMNS.noticePeriod,
              APPLICANT_LIST_COLUMNS.profileAvailability,
              APPLICANT_LIST_COLUMNS.invited,
              APPLICANT_LIST_COLUMNS.applied,
              APPLICANT_LIST_COLUMNS.matchScore,
              APPLICANT_LIST_COLUMNS.matchScoreWithoutTooltip,
              APPLICANT_LIST_COLUMNS.rateScore,
              APPLICANT_LIST_COLUMNS.viewButton,
            ]}
          />
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default MissionRoleApplications;
