import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer, Row } from "components/Containers";
import { breakpoints, colors, fontSize } from "style";
import Rating from "assets/images/rating.svg";
import Image from "components/Image";
import Thumb1 from "assets/images/testimonial_thumb_xavier.webp";
import Thumb2 from "assets/images/testimonial_thumb_siamak.webp";
import { H1, MarketingHeader } from "components/Text";

/**
 * MissionRolesPublicTestimonials
 */
const MissionRolesPublicTestimonials = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <div css={styles.inner_container}>
        <Grid.col start={4} end={10}>
          <Col gap="0" css={styles.title_container}>
            <MarketingHeader>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicTestimonials.Header" />
            </MarketingHeader>
            <H1 isPublic css={styles.title}>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicTestimonials.Title" />
            </H1>           
          </Col>
        </Grid.col> 
        <Grid cols={2} gap={`5rem`}>
          {TESTIMONIAL_DATA.map((testimonial, index) => (
            <Grid.col key={index}>
              <Col gap="2rem" css={styles.item_container}>
                <Row gap="0.25rem">
                  <Image url={Rating} alt="Mission" css={styles.image} />
                  <Image url={Rating} alt="Mission" css={styles.image} />
                  <Image url={Rating} alt="Mission" css={styles.image} />
                  <Image url={Rating} alt="Mission" css={styles.image} />
                  <Image url={Rating} alt="Mission" css={styles.image} />
                </Row>
                <span>
                  <FormattedMessage id={testimonial.quote} />
                </span>
                <Row css={styles.author}>
                  <div>
                    <div css={styles.thumb(testimonial.thumb)} />
                  </div>
                  <Col>
                    <span css={styles.name}>
                      <FormattedMessage id={testimonial.name} />
                    </span>
                    <span>
                      <FormattedMessage id={testimonial.location} />
                    </span>
                  </Col>
                </Row>
              </Col>
            </Grid.col>
          ))}       
        </Grid>
      </div>
    </NarrowContainer>
  </div>
);

const TESTIMONIAL_DATA = [
  {
    quote: "Missions.Roles.Public.MissionRolesPublicTestimonials.Quote1",
    name: "Missions.Roles.Public.MissionRolesPublicTestimonials.Name1",
    location: "Missions.Roles.Public.MissionRolesPublicTestimonials.Location1",
    thumb: Thumb1,
  },
  {
    quote: "Missions.Roles.Public.MissionRolesPublicTestimonials.Quote2",
    name: "Missions.Roles.Public.MissionRolesPublicTestimonials.Name2",
    location: "Missions.Roles.Public.MissionRolesPublicTestimonials.Location2",
    thumb: Thumb2,
  },
];

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.white};
    padding: 12rem 0 12rem 0;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 3rem 0;
    }
  `,
  inner_container: css`
    background: ${colors.grayAnatomyLight8};
    border-radius: 3.5rem;
    padding: 8rem;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 4rem 2rem;
    }
  `,
  item_container: css`
    font-size: ${fontSize.medium};
    line-height: 1.5;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSize.normal};
      margin-top: 3rem;
    }
  `,
  subtitle: css`
    font-weight:500;
  `,
  title_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    width:100%;
    margin-bottom: 8rem;
    text-align:center;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom: 0;
    }
  `,
  title: css`
    margin-bottom:4rem;
  `,
  image: css`
    width:1.75rem;
    height:1.75rem;
  `,
  author: css`
    margin-top: 3rem;
    flex-wrap: nowrap;

    @media (max-width: ${breakpoints.mobile}) {
      margin-top: 1rem;
    }
  `,
  name: css`
    font-weight: 600;
  `,
  thumb: (url) => css`
    background-image: url(${url});
    background-size: cover;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  `,
};

export default MissionRolesPublicTestimonials;
