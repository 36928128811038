import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer, Row } from "components/Containers";
import { breakpoints, colors, fontSize } from "style";
import { H1, MarketingHeader } from "components/Text";
import Icon, { ICON_TYPE } from "components/Icon";

/**
 * MissionRolesFaq
 */
const MissionRolesFaq = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid rowGap={`2rem`}>
        <Grid.col start={4} end={10} css={styles.item_container}>
          <Col gap="0" css={styles.title_container}>
            <MarketingHeader>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFaq.Header" />
            </MarketingHeader>
            <H1 isPublic css={styles.title}>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFaq.Title1" /><br />
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicFaq.Title2" />
            </H1>           
          </Col>
        </Grid.col>   
        <Grid.col start={3} end={11}>
          {[1,2,3,4,5,6,7,8,9,10].map((item, index) => <Question key={index} count={item} /> )}
        </Grid.col>
      </Grid>
    </NarrowContainer>
  </div>
);

/**
 * Question
 *  
 * @param {Number} count 
 */
const Question = ({ count }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={styles.question_container}>
      <Row css={styles.question} onClick={() => setIsOpen(!isOpen)}>
        <FormattedMessage id={`Missions.Roles.Public.MissionRolesPublicFaq.Question${count}`} />
        <div>
          <div css={styles.icon_container}>
            <Icon type={ICON_TYPE.chevronDown} />
          </div>
        </div>
      </Row>
      <div css={styles.answer(isOpen)}>
        <div>
          <FormattedMessage id={`Missions.Roles.Public.MissionRolesPublicFaq.Answer${count}`} />
        </div>
      </div>
    </div>
  )
}

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.white};
    padding: 14rem 0 14rem 0;
    font-size: ${fontSize.normal};

    @media (max-width: ${breakpoints.mobile}) {
      padding: 4rem 0;
    }
  `,
  title_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    width:100%;
    margin-bottom: 8rem;
    text-align:center;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom: -2rem;
    }
  `,
  title: css`
    margin-bottom:4rem;
  `,
  item_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
  `, 
  icon_container: css`
    width:3rem;
    height:3rem;
    border-radius:50%;
    background:${colors.grayAnatomyLight4};
    display:flex;
    justify-content:center;
    align-items:center;
  `,
  question_container:css `    
    border-bottom: 1px solid ${colors.grayAnatomyLight3};
    cursor:pointer;
  `,
  question: css`
    padding: 3rem 0;
    font-size: ${fontSize.xlarge};
    justify-content: space-between;
    user-select: none;
    flex-wrap: nowrap;

    @media (max-width: ${breakpoints.mobile}) {
      font-size: ${fontSize.medium};     
      line-height:normal;  
    }
  `,
  answer: isOpen => css`    
    line-height:2.4rem;   
    max-height: ${isOpen ? `500px` : `0px`};
    transition: max-height 0.8s ease;
    overflow:hidden;

    > div {
      max-width:60%;
      padding: 2.5rem 0 10rem 0;
    }

    @media (max-width: ${breakpoints.mobile}) {
      > div {
        max-width:100%;
        padding: 1rem 0 3rem 0;
        line-height:normal;
      }  
    }
  `
};


Question.propTypes = {
  count: PropTypes.number.isRequired
}

export default MissionRolesFaq;
