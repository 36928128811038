import React from "react";
import PropTypes from "prop-types";
import { colors, fontSize } from "style";
import { css } from "@emotion/react";
import { formatNumber } from "utils";
import { Col } from "components/Containers";

/**
 * ApplicantMatchScore
 *
 * @param {Number} score
 * @param {String} name
 * @param {String} size
 * @param {Boolean} isPercent
 * @param {Boolean} isNull
 * @param {Boolean} loading
 */
const ApplicantMatchScore = ({ score, name, size, isNull, isPercent, loading, ...props }) => {
  return (
    <Col css={styles.container(score, size, loading)} {...props}>
      {!loading && (
        <>
          <span>
            <span>
              {`${isNull ? `-` : formatNumber(Math.round(score))}`}
              <span css={styles.percent(size)}>{`${isPercent && !isNull ? `%` : ``}`}</span>
            </span>
          </span>
          {name && <span css={styles.score_sub(size)}>{name}</span>}
        </>
      )}
    </Col>
  );
};

const styles = {
  container: (score, size, loading) => css`
    width: ${size === SIZE.small ? `6rem` : `9rem`};
    height: ${size === SIZE.small ? `6rem` : `9rem`};
    background: ${loading
      ? colors.grayAnatomyLight5
      : !score
      ? colors.grayAnatomyBase
      : score < 60
      ? colors.grayAnatomyBase
      : colors.green};
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    font-size: ${size === SIZE.small ? fontSize.medium : fontSize.xxlarge};
    align-items: center;
    justify-content: center;
    transform: scale(${loading ? `0.8` : `1`});
    transition: all 0.5s ease;
  `,
  percent: (size) => css`
    font-size: ${size === SIZE.small ? fontSize.xsmall : fontSize.medium};
    position: relative;
    top: ${size === SIZE.small ? `-0.3rem` : `-1rem`};
    margin-left: 0.15rem;
  `,
  score_sub: (size) => css`
    font-size: ${size === SIZE.small ? fontSize.xxxsmall : fontSize.xsmall};
    color: #fff;
    opacity: 0.8;
    font-weight: 400;
  `,
};

const SIZE = {
  small: "small",
  medium: "medium",
};

ApplicantMatchScore.propTypes = {
  score: PropTypes.object,
  name: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SIZE)),
  isPercent: PropTypes.bool,
  isNull: PropTypes.bool,
  loading: PropTypes.bool,
};

export { SIZE };

export default ApplicantMatchScore;
