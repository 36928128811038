import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors } from "style";
import { H1, MarketingHeader } from "components/Text";

/**
 * MissionRolesPublicTalentSuccess
 */
const MissionRolesPublicTalentSuccess = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid gap={`5rem`} rowGap={`5rem`} css={styles.inner_container}>
        <Grid.col start={4} end={10}>
          <Col gap="0" css={styles.title_container}>
            <MarketingHeader variant={colors.pinkPantherBase}>
              <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicTalentSuccess.Header" />     
            </MarketingHeader>
            <H1 isPublic css={styles.title}>
              <FormattedMessage id="Missions.Roles.Public.MissionsRolesPublicTalentSuccess.Title" />            
            </H1>         
          </Col>
        </Grid.col>  
        <Grid.col start={3} end={11}>
          <iframe 
            css={styles.frame}
            src="https://www.youtube.com/embed/o7zNiwBULdY" 
            title="Mission&#39;s Talent Acquisition Team - Video 1: An Introduction" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin" 
            allowfullscreen
          />
        </Grid.col>
      </Grid>
    </NarrowContainer>
  </div>
);

const styles = {
  container: css`
    color: ${colors.white};
    background: ${colors.purpleRainDark2};
    padding: 14rem 0 14rem 0;
    border-radius: 3.5rem;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 4rem 0;
    }
  `,
  inner_container: css`
    @media (max-width: ${breakpoints.mobile}) {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
  `,
  title_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    width:100%;
    text-align:center;
  `,
  title: css`
    color: ${colors.white};
  `,
  frame: css`
    background: #000;
    margin-bottom: 4rem;
    width: 100%;
    height:40rem;

    @media (max-width: ${breakpoints.mobile}) {
      height:17rem;
      margin-bottom: 2rem;
    }
  `,
};

export default MissionRolesPublicTalentSuccess;
