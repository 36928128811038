/* eslint-disable no-undef */
import React, { useState } from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import { styles } from "./Select";
import { COUNTRIES } from "constants/index.js";
import { withIcon, ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import { Select, Label } from "components/Form";

/**
 * GeoLocator
 *
 * @params {String}   label
 * @params {String}   value
 * @params {String}   name
 * @params {Function} onChange
 * @params {Boolean}  hasIcon
 * @params {Boolean}  disabled
 * @params {Boolean}  localityOnly
 * @params {Boolean}  isClearable
 */
const GeoLocator = ({ label, value, name, onChange, hasIcon, disabled, localityOnly, isClearable }) => {
  const [focus, setFocus] = useState(false);
  const LOCALITY_DEFAULTS = {
    state: null,
    city: null,
    latitude: null,
    longitude: null,
    utc_offset: null,
    label: null,
    place_id: null,
  };

  /**
   * When a user selects a locality
   *
   * @param {Number} val
   */
  const handleAutocompleteChange = async (val) => {
    if (!val) {
      onChange(name, null);
      return;
    }

    const { place_id, description } = val.value;
    const service = new google.maps.places.PlacesService(document.getElementById("geo-map"));

    service.getDetails({ placeId: place_id }, (place, status) => {
      // If the Google API request fails then save only place_id + label
      if (status !== "OK") {
        onChange(name, {
          ...value,
          ...LOCALITY_DEFAULTS,
          label: description,
          place_id,
        });

        return;
      }

      const { address_components, geometry, utc_offset } = place;
      const state = address_components?.filter((item) => item.types.includes("administrative_area_level_1"));
      const city = address_components?.filter((item) => item.types.includes("locality"));
      const latitude = geometry?.location?.lat();
      const longitude = geometry?.location?.lng();

      onChange(name, {
        ...value,
        state: state && state.length > 0 ? state[0].long_name : null,
        city: city && city.length > 0 ? city[0].long_name : null,
        latitude,
        longitude,
        utc_offset,
        label: description,
        place_id,
      });
    });
  };

  /**
   * Search for results from string using Google Places AutocompleteService
   *
   * @param {String} input
   */
  const findResults = (input) =>
    new Promise((resolve) => {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input,
          types: ["(cities)"],
          ...(value?.country_code &&
            !localityOnly && {
              componentRestrictions: {
                country: String(value?.country_code).toLowerCase(),
              },
            }),
        },
        (results) => {
          const resultsArr = [];

          results?.forEach((item) => {
            resultsArr.push({
              label: item.description,
              value: item,
            });
          });

          return resolve(resultsArr);
        }
      );
    });

  const SelectWithIcon = withIcon(Select, {
    type: ICON_TYPE.pin,
    size: ICON_SIZE.medium,
    position: ICON_POSITION.overlay,
  });

  return (
    <div
      css={css`
        ${container_styles.location_container};
        ${localityOnly && "grid-template-columns: 1fr;"}
      `}
    >
      {!localityOnly && (
        <div css={[container_styles.location_item, container_styles.country]}>
          <SelectWithIcon
            options={COUNTRIES}
            name="country"
            placeholder="Select country..."
            value={value?.country_code}
            onChange={(_countryFieldName, country_code) => {
              const country = COUNTRIES.filter(
                (c) => String(c.value).toLowerCase() === String(country_code).toLowerCase()
              );

              onChange(name, {
                ...LOCALITY_DEFAULTS,
                country: country && country.length > 0 && country[0].label,
                country_flag: country && country.length > 0 && country[0].flag,
                country_code,
              });
            }}
          />
          <Label htmlFor={"country"}>Country</Label>
        </div>
      )}
      <div
        css={css`
          ${container_styles.location_item};
          ${container_styles.locality};
          ${localityOnly && "grid-column: span 4;"}
        `}
      >
        <AsyncSelect
          cacheOptions
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          key={value?.country_code} /* Resets cache when country changes */
          loadOptions={findResults}
          styles={styles(hasIcon, focus)}
          name={name}
          onChange={handleAutocompleteChange}
          value={value?.state ? value : ""} /* Only set value if a label exists */
          isDisabled={disabled} /* Enable regardless of country presence */
          placeholder={"Start typing here..."}
          isClearable={isClearable}
        />
        <Label htmlFor={name}>{label}</Label>
      </div>
      <div id="geo-map" />
    </div>
  );
};

const container_styles = {
  location_container: css`
    width: 100%;
    position: relative;
    order: 1;
    display: grid;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(4, 1fr);
    gap: 0 1.6rem;
  `,
  location_item: css`
    display: flex;
    flex-direction: column;
    position: relative;
  `,
  country: css`
    grid-column-start: span 2;
  `,
  locality: css`
    grid-column-start: span 2;
  `,
};

GeoLocator.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  hasIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  localityOnly: PropTypes.bool,
  isClearable: PropTypes.bool,
};

GeoLocator.defaultProps = {
  label: "Locality",
  hasIcon: false,
  localityOnly: false,
};

export default GeoLocator;
