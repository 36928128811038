import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import Icon, { ICON_TYPE } from "components/Icon";
import { colors } from "style";
import { StyledTag } from "components/Tags";

/**
 * TagIncomplete
 */
const TagIncomplete = (props) => (
  <StyledTag css={styles.container}>
    <Icon type={ICON_TYPE.clock} color={`#fff`} {...props} />
    <FormattedMessage id="Global.Incomplete" />
  </StyledTag>
);

const styles = {
  container: css`
    background: ${colors.warning};
    gap: 0.5rem;
  `,
};

export default TagIncomplete;
