import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { useAuth, useMissionRole, useMissionRoleRecommendation } from "hooks";
import Card, { CardMenu } from "components/Card";
import { fontSize, colors } from "style";
import { ApplyButton, RoleAdvancedOptions } from "components/Missions/Roles";
import Tags from "components/Tags";
import { shortenText } from "utils";
import { TextLoader } from "components/Loader";
import { LINKS } from "constants/index";
import { PrimaryLink } from "components/Links";
import { MissionLink } from "components/Missions";
import { Col, Div } from "components/Containers";
import { withAdminStyles } from "hocs";
import { OrganizationLink } from "components/Organization";

/**
 * Mission Role Card
 *
 * @param {Object}  data
 * @param {Boolean} loading
 * @param {Boolean} hideApplyButton
 */
const RoleCard = ({ data, loading, hideApplyButton }) => {
  const { isShowcase, canApplyForRolesOnBehalf, isAdmin, isRecruiter, ...me } = useAuth();
  const { data: roleData, loading: roleLoading } = useMissionRole({ id: data?.slug, skip: data?.id });
  const { mission, ...role } = roleData || data || {};
  const {
    rate,
    currency,
    loading: recommendationLoading,
  } = useMissionRoleRecommendation({ profileId: me?.profileId, missionRoleId: role?.uuid });

  if (loading || roleLoading || recommendationLoading) {
    return <PlaceHolder />;
  }

  return (
    <Card css={styles.container} fixedHeight>
      <CardMenu>
        <RoleAdvancedOptions roleId={role?.id} data={role} />
      </CardMenu>
      <div css={styles.company}>
        <OrganizationLink
          data={{ ...mission?.organization, isConfidential: mission?.isOrganizationConfidential }}
          withOverlay
        />
      </div>
      <div css={styles.title}>{role?.name}</div>
      <div css={styles.body}>
        {shortenText(role?.description, 200)}
        <div css={styles.read_more_link}>
          <PrimaryLink to={LINKS.mission_role(mission?.uuid, role?.slug)} routerEnabled withMoreIcon>
            <FormattedMessage id="Global.ReadMore" />
          </PrimaryLink>
        </div>
      </div>
      {role?.primarySkills && role?.primarySkills.length > 0 && (
        <div css={styles.tags}>
          <Tags data={role?.primarySkills} />
        </div>
      )}
      <Col gap="2rem" css={styles.data_outer_container}>
        <div css={styles.data_container(hideApplyButton)}>
          <div css={styles.label}>
            <FormattedMessage id="Missions.MissionCard.MissionName" />
            <div css={styles.item}>
              {isShowcase ? (
                <PrimaryLink to={LINKS.showcase_mission(mission?.uuid, me?.uuid)} routerEnabled>
                  {mission?.name}
                </PrimaryLink>
              ) : (
                <MissionLink data={mission} withOverlay placement="auto" />
              )}
            </div>
          </div>
          <div css={styles.label}>
            <FormattedMessage id="Missions.MissionCard.LabelTimezone" />
            <div css={styles.item}>{role?.timezone?.name || mission?.timezone?.name}</div>
          </div>
          <div css={styles.label}>
            <FormattedMessage id="Missions.MissionCard.LabelStartDate" />
            <div css={styles.item}>{moment(role?.startDate || mission?.startDate).format("MMMM Do, YYYY")}</div>
          </div>
          {(isAdmin || isRecruiter) && (
            <AdminStyledDiv css={styles.label}>
              <FormattedMessage id="Missions.MissionCard.LabelSuggestedRate" />
              <div css={styles.item}>
                <FormattedMessage id="Missions.MissionCard.ValueSuggestedRate" values={{ rate, currency }} />
              </div>
            </AdminStyledDiv>
          )}
        </div>
        {!hideApplyButton && (
          <ApplyButton
            css={styles.button}
            role={{ ...role, mission }}
            roleLoading={loading}
            canApplyOnBehalf={canApplyForRolesOnBehalf}
          >
            <FormattedMessage id={`Missions.Apply${canApplyForRolesOnBehalf ? ".OnBehalf" : ""}`} />
          </ApplyButton>
        )}
      </Col>
    </Card>
  );
};

/**
 * Placeholder
 */
const PlaceHolder = () => (
  <Card css={styles.container} fixedHeight>
    <div css={styles.company}>
      <TextLoader width="30%" />
    </div>
    <div css={styles.title}>
      <TextLoader width="80%" />
    </div>
    <div css={styles.body}>
      {[1, 2, 3, 4].map((item) => (
        <TextLoader key={item} width="100%" />
      ))}
      <TextLoader width="60%" />
    </div>
  </Card>
);

const styles = {
  container: css`
    margin-bottom: 0;
  `,
  company: css`
    font-size: ${fontSize.xsmall};
    color: ${colors.grayAnatomyLight1};
    font-weight: 500;
    padding-right: 3rem;
  `,
  title: css`
    font-size: ${fontSize.large};
    font-weight: 600;
    margin-top: 0.5rem;
    line-height: 1.25;
    padding-right: 3rem;
  `,
  body: css`
    font-size: ${fontSize.small};
    margin-top: 2rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  `,
  tags: css`
    font-size: ${fontSize.small};
    margin-top: 2rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  `,
  label: css`
    font-size: ${fontSize.small};
    font-weight: 500;
    border-top: 1px ${colors.grayAnatomyLight4} solid;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  data_outer_container: css`
    margin-top: auto;
  `,
  data_container: (applyButtonHidden) => css`
    ${applyButtonHidden &&
    `
      border-bottom: 1px ${colors.grayAnatomyLight4} solid;
    `}
  `,
  item: css`
    font-weight: 400;
    text-align: left;
    color: ${colors.grayAnatomyLight1};
    line-height: normal;
  `,
  button: css`
    width: 100%;
  `,
  read_more_link: css`
    margin-top: 0.5rem;
  `,
};

RoleCard.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  hideApplyButton: PropTypes.bool,
};

const AdminStyledDiv = withAdminStyles(Div);

export default RoleCard;
