import { gql } from "@apollo/client";

const coreProfileFields = `
  archetype
  avatarUrl
  resumeUrl
  description
  descriptionJson
  firstName
  lastName
  memberSince
  capacity
  name
  slug
  yearsExperience
  bachelorDegree
  location
  timezone {
    name
    value
  }
`;

const corePrivateProfileFields = `
  id
  idWithName
  flaggedCommentsCount
  email
  phoneNumber
  candidate
  status
  slug
  capacityStatus
  teamsCount
  rateLastUpdatedAt
  availabilityLastUpdatedAt
  eligibleForVetting
  missionEmail
  source {
    id
    name
  }
  channel
  deelContractId
  openToEmployment
  canShowInterviews
  codeChallengeRequired
  vettingConfiguration {
    name
    required
  }
  missionRole {
    name
    published
    mission {
      name
    }
  }
  currentTime
  isOnline
  nextAvailability
  partner {
    id 
    trusted
  }
`;

const candidateProgressFields = `
  archivedAt
  rejectedAt
  submittedAt
  rejectedDescription
  rejectedReason
  archivedReason
  archivedDescription
  archivedBy {
    firstName
    lastName
  }
  rejectedBy {
    firstName
    lastName
  }
  approvedBy {
    firstName
    lastName
    name
  }
  assignedTo {
    id
    name
  }
  challengesCount
  passedChallengesCount
  completedInterviewsCount
  interviewsCount
`;

const disciplineFields = `
  category
  enabled
  name
  id
`;

const languageFields = `
  category
  enabled
  name
  id
`;

const educationFields = `
  degreeName
  endDate
  fieldOfStudy
  id
  schoolName
  startDate
`;

const externalLinkFields = `
  name
  type
  url
  uuid
`;

const oldLocationFields = `
  city
  country
  countryFlag
  state
`;

const locationFields = `
  location
  oldLocation {
    ${oldLocationFields}
  }
`;

const rateFields = `
  amount
  currency
  frequency
  symbol
`;

const minimalProfileFields = `
  uuid
  slug
  firstName
  lastName
  avatarUrl
  capacityStatus
  description
  descriptionJson
  ${locationFields}
  rate {
    ${rateFields}
  }
`;

const yearlyRateFields = `
  yearlyAmount
  yearlyRateEnabled
`;

const skillFields = `
  category
  enabled
  name
  id
`;

const tagFields = `
  category
  enabled
  name
  id
`;

const workExperienceFields = `
  companyName
  currentJob
  description
  descriptionJson
  endDate
  id
  role
  skills{
    id
    name
  }
  industries {
    id
    name
  }
  startDate
  organizationSize
`;

const workScheduleFields = `
  dayEndsAt
  dayStartsAt
  id
  working
  weekday
`;

const vacationFields = `
  id
  startDate
  endDate
`;

const interestsFields = `
  name
  id
  uuid
`;

const badgesFields = `
  name
  id
  uuid
`;

const engineeringLevelFields = `
  name
  id
  uuid
  description
`;

const userFields = `
  id
  email
  firstName
  lastName
  preferences
`;

const profileIdFields = `
  id
  uuid
  slug
`;

const profileBasicFields = `
  email
  firstName
  lastName
  name
  description
  status
  capacityStatus
  archetype
  avatarUrl
`;

const profileAvailabilityFields = `
  isOnline
  currentTime
  nextAvailability
`;

const timestampFields = `
  createdAt
`;

const CORE_PROFILE_FIELDS = gql`
  fragment ProfileFields on Profile {
    ${corePrivateProfileFields}
    ${coreProfileFields}
  }
`;

const MINIMAL_PROFILE_FIELDS = gql`
  fragment MinimalProfileFields on Profile {
    ${minimalProfileFields}
  }
`;

const DISCIPLINE_FIELDS = gql`
  fragment DisciplineFields on Profile {
    disciplines {
      ${disciplineFields}
    }
  }
`;

const CANDIDATE_PROGRESS_FIELDS = gql`
  fragment CandidateProgressFields on Profile {
    ${candidateProgressFields}
  }
`;

const LANGUAGE_FIELDS = gql`
  fragment LanguageFields on Profile {
    languages {
      ${languageFields}
    }
  }
`;

const INTERESTS_FIELDS = gql`
  fragment InterestsFields on Profile {
    interests {
      ${interestsFields}
    }
  }
`;

const BADGES_FIELDS = gql`
  fragment BadgesFields on Profile {
    badges {
      ${badgesFields}
    }
  }
`;

const ENGINEERING_LEVEL_FIELDS = gql`
  fragment EngineeringLevelFields on Profile {
    engineeringLevelWithoutCode
    engineeringLevel {
      ${engineeringLevelFields}
    }
  }
`;

const EDUCATION_FIELDS = gql`
  fragment EducationFields on Profile {
    education {
      ${educationFields}
    }
  }
`;

const EXTERNAL_LINKS_FIELDS = gql`
  fragment ExternalLinksFields on Profile {
    externalLinks {
      ${externalLinkFields}
    }
  }
`;

const OLD_LOCATION_FIELDS = gql`
  fragment OldLocationFields on Profile {
    oldLocation {
      ${oldLocationFields}
    }
  }
`;

const LOCATION_FIELDS = gql`
  fragment LocationFields on Profile {
    ${locationFields}
  }
`;

const RATE_FIELDS = gql`
  fragment RateFields on Profile {
    rate {
      ${rateFields}
    }
  }
`;

const YEARLY_RATE_FIELDS = gql`
  fragment YearlyRateFields on Profile {
    yearlyRate {
      ${yearlyRateFields}
    }
  }
`;

const SKILLS_FIELDS = gql`
  fragment SkillsFields on Profile {
    skills {
      ${skillFields}
    }
  }
`;

const TAGS_FIELDS = gql`
  fragment TagsFields on Profile {
    tags {
      ${tagFields}
    }
  }
`;

const WORK_EXPERIENCES_FIELDS = gql`
  fragment WorkExperiencesFields on Profile {
    workExperiences {
      ${workExperienceFields}
    }
  }
`;

const WORK_SCHEDULE_FIELDS = gql`
  fragment WorkScheduleFields on Profile {
    workSchedule {
      ${workScheduleFields}
    }
  }
`;

const VACATIONS_FIELDS = gql`
  fragment VacationsFields on Profile {
    vacations {
      ${vacationFields}
    }
  }
`;

const USER_FIELDS = gql`
  fragment UserFields on Profile {
    user {
      ${userFields}
    }
  }
`;

const PROFILE_AVAILABILITY_FIELDS = gql`
  fragment ProfileAvailabilityFields on Profile { 
    ${profileAvailabilityFields}
  }
`;

const PROFILE_ID_FIELDS = gql`
  fragment ProfileIdFields on Profile {
    ${profileIdFields}
  }
`;

const PROFILE_BASIC_FIELDS = gql`
  fragment ProfileBasicFields on Profile {
    ${profileBasicFields}
  }
`;

/**
 * Public fields
 */
const PUBLIC_CORE_PROFILE_FIELDS = gql`
  fragment PublicProfileFields on PublicProfile {
    ${coreProfileFields}
  }
`;

const PUBLIC_DISCIPLINE_FIELDS = gql`
  fragment PublicDisciplineFields on PublicProfile {
    disciplines {
      ${disciplineFields}
    }
  }
`;

const PUBLIC_LANGUAGE_FIELDS = gql`
  fragment PublicLanguageFields on PublicProfile {
    languages {
      ${languageFields}
    }
  }
`;

const PUBLIC_EDUCATION_FIELDS = gql`
  fragment PublicEducationFields on PublicProfile {
    education {
      ${educationFields}
    }
  }
`;

const PUBLIC_INTERESTS_FIELDS = gql`
  fragment PublicInterestsFields on PublicProfile {
    interests {
      ${interestsFields}
    }
  }
`;

const PUBLIC_OLD_LOCATION_FIELDS = gql`
  fragment PublicOldLocationFields on PublicProfile {
    oldLocation {
      ${oldLocationFields}
    }
  }
`;

const PUBLIC_SKILLS_FIELDS = gql`
  fragment PublicSkillsFields on PublicProfile {
    skills {
      ${skillFields}
    }
  }
`;

const PUBLIC_TAGS_FIELDS = gql`
  fragment PublicTagsFields on PublicProfile {
    tags {
      ${tagFields}
    }
  }
`;

const PUBLIC_WORK_EXPERIENCES_FIELDS = gql`
  fragment PublicWorkExperiencesFields on PublicProfile {
    workExperiences {
      ${workExperienceFields}
    }
  }
`;

const PUBLIC_WORK_SCHEDULE_FIELDS = gql`
  fragment PublicWorkScheduleFields on PublicProfile {
    workSchedule {
      ${workScheduleFields}
    }
  }
`;

const PUBLIC_BADGES_FIELDS = gql`
  fragment PublicBadgesFields on PublicProfile {
    badges {
      ${badgesFields}
    }
  }
`;

const PUBLIC_ENGINEERING_LEVEL_FIELDS = gql`
  fragment PublicEngineeringLevelFields on PublicProfile {
    engineeringLevelWithoutCode
    engineeringLevel {
      ${engineeringLevelFields}
    }
  }
`;

const TIMESTAMP_FIELDS = gql`
  fragment TimestampFields on Profile {
    ${timestampFields}
  }
`;

export {
  CORE_PROFILE_FIELDS,
  MINIMAL_PROFILE_FIELDS,
  CANDIDATE_PROGRESS_FIELDS,
  DISCIPLINE_FIELDS,
  LANGUAGE_FIELDS,
  EDUCATION_FIELDS,
  INTERESTS_FIELDS,
  BADGES_FIELDS,
  EXTERNAL_LINKS_FIELDS,
  OLD_LOCATION_FIELDS,
  RATE_FIELDS,
  YEARLY_RATE_FIELDS,
  SKILLS_FIELDS,
  TAGS_FIELDS,
  WORK_EXPERIENCES_FIELDS,
  WORK_SCHEDULE_FIELDS,
  VACATIONS_FIELDS,
  USER_FIELDS,
  PUBLIC_CORE_PROFILE_FIELDS,
  PUBLIC_DISCIPLINE_FIELDS,
  PUBLIC_LANGUAGE_FIELDS,
  PUBLIC_EDUCATION_FIELDS,
  PUBLIC_OLD_LOCATION_FIELDS,
  PUBLIC_SKILLS_FIELDS,
  PUBLIC_TAGS_FIELDS,
  PUBLIC_WORK_EXPERIENCES_FIELDS,
  PUBLIC_WORK_SCHEDULE_FIELDS,
  PUBLIC_INTERESTS_FIELDS,
  PUBLIC_BADGES_FIELDS,
  PUBLIC_ENGINEERING_LEVEL_FIELDS,
  PROFILE_ID_FIELDS,
  PROFILE_BASIC_FIELDS,
  LOCATION_FIELDS,
  PROFILE_AVAILABILITY_FIELDS,
  ENGINEERING_LEVEL_FIELDS,
  TIMESTAMP_FIELDS,
};
