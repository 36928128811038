import { gql } from "@apollo/client";
import { CORE_PROFILE_FIELDS, PROFILE_ID_FIELDS } from "graphql/fragments";

const GET_ME = gql`
  ${PROFILE_ID_FIELDS}
  ${CORE_PROFILE_FIELDS}
  query me {
    me {
      id
      uuid
      uid
      profileId
      profileUuid
      profileSlug
      email
      firstName
      lastName
      fullName
      hasPartner
      memberSince
      isPrimaryContact
      isImpersonating
      preferences
      originatedMissionRoleApplicant {
        id
      }
      partner {
        profiles {
          id
        }
      }
      trueUser {
        id
        firstName
        lastName
        name
      }
      notificationSettings {
        name
        enabled
      }
      permissions {
        action
        canPerformAction
      }
      profile {
        ...ProfileIdFields
        ...ProfileFields
      }
      featureFlags {
        name
        enabled
      }
      needsToSignTerms
      needsToSignPrivacyPolicy
      organization {
        slug
        name
        logoUrl
      }
      teams {
        name
        slug
        organization {
          slug
        }
      }
      roles {
        name
      }
    }
  }
`;

export { GET_ME };
