import React, { useMemo, useCallback, useRef, useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { Node, Editor, Transforms, Range, createEditor } from "slate";
import { Slate, Editable, ReactEditor, withReact } from "slate-react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { colors } from "style";
import { withMentions, MentionTag, MentionList, insertMention } from "./Plugins/Mentions";
import FormattingBar from "./FormattingBar";

/**
 * TextEditor
 *
 * @param {Array}    value
 * @param {String}   name
 * @param {Function} onChange
 * @param {Function} onFocus
 * @param {Function} readOnly
 * @param {Function} placeholder
 * @param {Function} showFormattingOptions
 */
// eslint-disable-next-line
const TextEditor = forwardRef(
  ({ value, name, onChange, onFocus, readOnly, placeholder, showFormattingOptions, ...props }, ref) => {
    const container = useRef(null);
    const [target, setTarget] = useState();
    const [isFocused, setFocus] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState("");
    const renderElement = useCallback((props) => <Element {...props} />, []);
    const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
    const editor = useMemo(() => withMentions(withReact(createEditor())), []);

    const handleChange = useCallback(
      (value) => {
        const { selection } = editor;

        if (typeof onChange === "function") {
          onChange(name, value, editor);
        }

        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const wordBefore = Editor.before(editor, start, { unit: "word" });
          const before = wordBefore && Editor.before(editor, wordBefore);
          const beforeRange = before && Editor.range(editor, before, start);
          const beforeText = beforeRange && Editor.string(editor, beforeRange);
          const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
          const after = Editor.after(editor, start);
          const afterRange = Editor.range(editor, start, after);
          const afterText = Editor.string(editor, afterRange);
          const afterMatch = afterText.match(/^(\s|$)/);

          if (beforeMatch && afterMatch) {
            setTarget(beforeRange);
            setSearch(beforeMatch[1]);
            setIndex(0);
            return;
          }
        }

        setTarget(null);
      },
      [editor]
    );

    const handleFocus = useCallback(
      (e) => {
        if (e.target === container.current) {
          ReactEditor.focus(editor);
          e.preventDefault();
        }
      },
      [editor]
    );

    useEffect(() => {
      onFocus?.(isFocused);
    }, [isFocused]);

    // Expose some methods to parent
    useImperativeHandle(
      ref,
      () => {
        return {
          focus(moveToEnd) {
            ReactEditor.focus(editor);

            if (moveToEnd) {
              Transforms.select(editor, Editor.end(editor, []));
            }
          },
          addMention(item) {
            ReactEditor.focus(editor);
            Transforms.select(editor, Editor.end(editor, []));
            insertMention(editor, item);
          },
          reset() {
            const point = { path: [0, 0], offset: 0 };

            editor.selection = { anchor: point, focus: point };
            editor.history = { redos: [], undos: [] };
            editor.children = value;
          },
        };
      },
      [editor]
    );

    // Slate's value cannot be updated with value prop
    // The following logic resolves this issue
    // Issue: https://github.com/ianstormtaylor/slate/pull/4540
    useEffect(() => {
      if (Array.isArray(value)) {
        editor.children = value;
        // Triggers a re-render to reflect the updated children
        editor.onChange();
      }
    }, [editor, value]);

    return (
      <Container ref={container} isFocused={isFocused} onMouseDown={handleFocus} {...props}>
        {!readOnly && showFormattingOptions && <FormattingBar editor={editor} />}
        <Slate editor={editor} value={value || defaultValue} onChange={handleChange}>
          <Editable
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            readOnly={readOnly}
          />
          <MentionList
            index={index}
            editor={editor}
            target={target}
            search={search}
            onSelect={(i, item) => {
              Transforms.select(editor, target);
              setIndex(i);
              insertMention(editor, item);
              setTarget(null);
              ReactEditor.focus(editor);
            }}
          />
        </Slate>
      </Container>
    );
  }
);

const getPlainText = (value) => value?.map((n) => Node.string(n)).join("\n");

const convertFromPlainText = (value) => {
  return value?.split("\n")?.map((line) => ({
    type: "paragraph",
    children: [{ text: line }],
  }));
};

const Container = styled.div`
  order: 1;
  border-radius: 0.6rem;
  background-color: none;
  padding: 1rem 1rem;
  line-height: 1.5;
  border: 1px solid ${colors.grayAnatomyLight3};
  outline: none;
  transition: all 0.3s ease;

  &:hover {
    border-color: ${colors.purpleRainBase};
  }

  p {
    margin-top: 0;
  }

  ol,
  ul {
    margin: 1rem 1rem;
  }

  ${(props) =>
    props.isFocused &&
    `
    border-color: ${colors.purpleRainBase};
    box-shadow: 0px 0px 0px 3px ${colors.purpleRainLight3};

    & + label {
      color: ${colors.purpleRainBase};
    }
  `}
`;

/**
 * Leaf
 *
 * @param {Object}  attributes
 * @param {Object}  children
 * @param {Object}  leaf
 */
const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

/**
 * Element
 */
const Element = (props) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "mention":
      return <MentionTag {...props} />;

    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;

    case "list-item":
      return <li {...attributes}>{children}</li>;

    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;

    default:
      return <p {...attributes}>{children}</p>;
  }
};

const defaultValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];

TextEditor.defaultProps = {
  value: defaultValue,
  showFormattingOptions: false,
};

TextEditor.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  showFormattingOptions: PropTypes.bool,
};

Leaf.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  leaf: PropTypes.object,
};

Element.propTypes = {
  attributes: PropTypes.object,
  children: PropTypes.object,
  element: PropTypes.object,
};

export { defaultValue, getPlainText, convertFromPlainText };

export default TextEditor;
