import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { H1 } from "components/Text";
import { useProfile } from "hooks";
import { breakpoints, fontSize, margins } from "style";
import { TextLoader } from "components/Loader";
import { Col } from "components/Containers";

/**
 * CandidateTitle
 *
 * @param {String} title
 * @param {String} description
 * @param {Object} values
 * @param {String} profileId
 */
const CandidateTitle = ({ title, description, values, profileId }) => {
  const { data, loading } = useProfile({ profileId });

  return (
    <Col css={styles.header}>
      <H1 isPublic loading={loading}>
        <FormattedMessage
          id={title}
          values={{
            firstName: data?.profile?.firstName,
          }}
        />
      </H1>
      {loading ? <TextLoader /> : null}
      {!loading && (
        <span>
          <FormattedMessage id={description} values={values} />
        </span>
      )}
    </Col>
  );
};

const styles = {
  header: css`
    gap: 2rem;
    font-size: ${fontSize.medium};
    padding: 4rem 16rem 4rem ${margins.normal};
    line-height: 1.5;

    @media (max-width: ${breakpoints.portrait}) {
      padding: 4rem ${margins.small};
    }

    @media (max-width: ${breakpoints.mobile}) {
      padding-left: 2rem ${margins.xsmall};
    }
  `,
};

CandidateTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  values: PropTypes.object,
  profileId: PropTypes.string.isRequired,
};

export default CandidateTitle;
