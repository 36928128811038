import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { breakpoints, colors, fontSize } from "style";

/**
 * TableCell
 *
 * @param {Boolean} loading
 * @param {Boolean} alignMiddle
 * @param {Boolean} alignRight
 * @param {Boolean} expander
 * @param {Boolean} selected
 */
const TableCell = ({ loading, alignMiddle, alignRight, expander, selected, ...props }) => {
  return (
    <TableCellElement
      $loading={loading}
      $alignMiddle={alignMiddle}
      $alignRight={alignRight}
      $expander={expander}
      $selected={selected}
      {...props}
    />
  );
};

const TableCellElement = styled.td`
  color: ${colors.purpleRainDark2};
  font-size: ${fontSize.xsmall};
  vertical-align: middle;
  padding: 0.5rem 1.2rem;
  border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  line-height: 1.2;
  height: 4.7rem;

  ${(props) =>
    props.$selected &&
    `
    background: ${colors.purpleRainBase} !important;
    border-bottom-color: rgba(255,255,255,0.1) !important;
    color:#fff;

    a {
      color: ${colors.blueVelvetLight2};
    }
  `}

  &:first-of-type {
    padding-left: 3rem;
  }

  &:last-of-type {
    padding-right: 3rem;
  }

  > span {
    display: block;
  }

  textarea {
    vertical-align: middle;
    transform: translate(-1px);
    resize: none;
  }

  ${(props) =>
    props.$loading &&
    `
    > * {
      opacity:0.5;
    }
  `}

  ${(props) =>
    props.$alignMiddle &&
    `
    vertical-align:middle;
  `}

  ${(props) =>
    props.$alignRight &&
    `
    text-align:right;
  `}

  @media (max-width: ${breakpoints.portrait}) {
    &:first-of-type {
      padding-left: 2.5rem;
    }

    &:last-of-type {
      padding-right: 2.5rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: none;

    &:first-of-type {
      padding-left: 2rem;
      display: table-cell;
    }

    &:nth-of-type(2) {
      display: table-cell;
    }

    &:nth-of-type(3) {
      display: table-cell;
    }

    &:last-of-type {
      padding-right: 2rem;
      display: table-cell;
    }
  }

  ${(props) => props.$expander && `padding:0;`}
  ${(props) => props.themeStyles}
`;

TableCell.propTypes = {
  loading: PropTypes.bool,
  alignMiddle: PropTypes.bool,
  alignRight: PropTypes.bool,
  expander: PropTypes.bool,
  selected: PropTypes.bool,
};

export default TableCell;
