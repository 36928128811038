import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { APPLICATION_STEPS } from "constants/index";
import {
  ApplicantProgress,
  ApplicationEditableQuestionnaire,
  ApplicationEditableRate,
  ApplicationStateBanner,
} from "components/Missions/Applicants";
import { Grid } from "components/Containers";
import Card from "components/Card";
import { RoleDetails } from "components/Missions/Roles";
import { CandidateCodeChallenge } from "components/Candidate";
import { useAuth } from "hooks";
import DisplayBox from "components/DisplayBox";
import { MissionAboutClient } from "components/Missions";
import { Spinner } from "components/Loader";
import { TagComplete, TagDuration } from "components/Tags";

/**
 * ApplicationForm
 *
 * @param {Object}  selected
 * @param {Boolean} loading
 * @param {Boolean} hideProgress
 * @param {Boolean} hideRequirements
 * @param {Boolean} hideDetails
 */
const ApplicationForm = ({ selected, loading, hideProgress, hideRequirements, hideDetails }) => {
  const { profileId, canViewEditRate, isMember } = useAuth();
  const [rateEditing, setRateEditing] = useState(false);
  const [questionnaireEditing, setQuestionnaireEditing] = useState(false);

  const roleChallenges = selected?.missionRole?.challenges;
  const codeChallengeRequirement = selected?.applicationChecklist?.find(
    (step) => step.name === APPLICATION_STEPS.code_challenge
  );
  const codeChallengeCompleted = codeChallengeRequirement?.fulfilled;

  return (
    <Grid topMargin>
      {loading && (
        <Grid.col start={1} end={13}>
          <CenteredSpinner>
            <Spinner />
          </CenteredSpinner>
        </Grid.col>
      )}
      {selected && (
        <>
          {isMember && <ApplicationStateBanner application={selected} />}
          <Grid.col start={1} end={hideProgress ? 13 : 9}>
            <Grid>
              {!hideRequirements && (
                <Grid.col start={1} end={13}>
                  <Card>
                    <DisplayBox
                      title={<FormattedMessage id="Requirements" />}
                      description={
                        <p>
                          <FormattedMessage id="MissionApplications.Application.Requirements.Text" />
                        </p>
                      }
                    />
                  </Card>
                </Grid.col>
              )}
              {canViewEditRate && (
                <ApplicationEditableRate
                  missionRoleApplicant={selected}
                  rateEditing={rateEditing}
                  setRateEditing={setRateEditing}
                  isEditable={!selected.appliedAt}
                />
              )}
              <ApplicationEditableQuestionnaire
                missionRoleApplicant={selected}
                questionnaireEditing={questionnaireEditing}
                setQuestionnaireEditing={setQuestionnaireEditing}
                isEditable={!selected.appliedAt}
              />
              {codeChallengeRequirement && (
                <Grid.col start={1} end={13}>
                  <Card>
                    <CandidateCodeChallenge
                      profileId={profileId}
                      {...(roleChallenges?.length > 0 && {
                        showSkills: false,
                        challengeIds: roleChallenges.map((c) => c.id),
                        resultsPerPage: roleChallenges.length,
                        subtitle: !codeChallengeCompleted ? <TagDuration text={"~2 hours"} /> : <TagComplete />,
                      })}
                    />
                  </Card>
                </Grid.col>
              )}
              {!hideDetails && (
                <>
                  <Grid.col start={1} end={13}>
                    <Card>
                      <RoleDetails role={selected?.missionRole} mission={selected?.missionRole?.mission} />
                    </Card>
                  </Grid.col>
                  <Grid.col start={1} end={13}>
                    <Card>
                      <MissionAboutClient mission={selected?.missionRole?.mission} />
                    </Card>
                  </Grid.col>
                </>
              )}
            </Grid>
          </Grid.col>
          {!hideProgress && (
            <Grid.col start={9} end={13}>
              <Card>
                <ApplicantProgress
                  missionRoleApplicant={selected}
                  setRateEditing={!selected.appliedAt ? setRateEditing : () => {}}
                  setQuestionnaireEditing={!selected.appliedAt ? setQuestionnaireEditing : () => {}}
                />
              </Card>
            </Grid.col>
          )}
        </>
      )}
    </Grid>
  );
};

ApplicationForm.propTypes = {
  selected: PropTypes.object,
  loading: PropTypes.bool,
  hideProgress: PropTypes.bool,
  hideRequirements: PropTypes.bool,
  hideDetails: PropTypes.bool,
};

const CenteredSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default ApplicationForm;
