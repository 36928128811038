import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors, fontSize  } from "style";
import {  H1, MarketingHeader } from "components/Text";
import { Editor } from "components/Form";
import { TextBlock } from "components/Layouts";

/**
 * MissionRolesPublicHeader
 * 
 * @param {object} role
 * @param {boolean} loading
 */
const MissionRolesPublicHeader = ({ role, loading }) => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid gap={`6rem`}>
        <Grid.col start={1} end={5}>
          <Col gap="0">          
            <MarketingHeader variant={colors.pinkPantherBase}>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicHeader.Header" />
            </MarketingHeader>
            <H1 isPublic css={styles.title}>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicHeader.Title" />
            </H1>          
          </Col>
        </Grid.col>       
        <Grid.col start={5} end={13}>
          <Col gap="0" css={styles.description}>          
            {(role?.descriptionJson && !loading) ? (
              <Editor value={role?.descriptionJson} css={styles.body} readOnly={true} />
            ) : (
              <TextBlock body={role?.description} isLoading={loading} css={styles.text} />
            )}
          </Col>
        </Grid.col>      
        <Grid.col start={1} end={13} css={styles.divider} />
        <Grid.col start={1} end={7}>
          <Col gap="0">
            <MarketingHeader variant={colors.pinkPantherBase}>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicHeader.Header2" />
            </MarketingHeader>
            {(role?.mission?.descriptionJson && !loading) ? (
              <Editor value={role?.mission?.descriptionJson} css={styles.body} readOnly={true} />
            ) : (
              <TextBlock body={role?.mission?.description} isLoading={loading} css={styles.text} />
            )}
          </Col>
        </Grid.col>  
        <Grid.col start={7} end={13} css={styles.company_container}>
          <Col gap="0">
            <MarketingHeader variant={colors.pinkPantherBase}>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicHeader.Header3" />
            </MarketingHeader>
            {role?.mission?.companyDescriptionJson ? (
              <Editor value={role?.mission?.companyDescriptionJson} css={styles.body} readOnly={true} />
            ) : (
              <TextBlock body={role?.mission?.companyDescription} isLoading={loading} css={styles.text} />
            )}
          </Col>
        </Grid.col>  
      </Grid>
    </NarrowContainer>
  </div>
)

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark}; 
    font-size: ${fontSize.medium};
    background: ${colors.grayAnatomyLight8};
    border-radius: 3.4rem;
    padding: 14rem 0;
    z-index:1;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 4rem 0;
    }
  `,
  divider: css`
   border-top: 1px ${colors.purpleRainLight7} solid;
   margin-bottom:4rem;
  `,
  description: css`
    margin-bottom:4rem;
    border-left: 1px ${colors.purpleRainLight7} solid;
    padding-left: 6rem;
    padding-bottom: 6rem;

    b, strong {
      color: ${colors.grayAnatomyDark};
    }

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom:3rem;
    }
  `,
  text: css`
   color: ${colors.grayAnatomyDark}; 
  `,
  title: css`
    color: ${colors.grayAnatomyDark};
    margin-bottom:4rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom:2rem;
    }
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
    color: ${colors.grayAnatomyDark};
  `,
  company_container: css`
    @media (max-width: ${breakpoints.mobile}) {
      margin-top:3rem;
    }
  `,
};

MissionRolesPublicHeader.propTypes = {
  role: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default MissionRolesPublicHeader;
