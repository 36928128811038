import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { useAuth, useMissionRoleApplicant, useProfile } from "hooks";
import { APPLICATION_STEPS, LINKS } from "constants/index";
import { Col } from "components/Containers";
import ApplicationSubmitButton from "./ApplicationSubmitButton";
import Progress from "components/Progress";

/**
 * ApplicantProgress
 *
 * @param {Object} missionRoleApplicant
 * @param {Function} setRateEditing
 * @param {Function} setQuestionnaireEditing
 * @param {Boolean} hideSubmitButton
 * @param {Boolean} hideProgress
 */
const ApplicantProgress = ({
  missionRoleApplicant,
  setRateEditing,
  setQuestionnaireEditing,
  hideSubmitButton,
  hideProgress,
  ...props
}) => {
  const { isPartner, isCandidate } = useAuth();
  const { getApplicationSteps } = useMissionRoleApplicant({});
  const { isProfileComplete } = useProfile({ profileId: missionRoleApplicant?.profileId, skip: true });
  const navigate = useNavigate();

  const canSubmit =
    missionRoleApplicant?.applicationChecklist?.every((step) => step.fulfilled) && !missionRoleApplicant?.appliedAt;

  const handleStepClick = (step) => {
    switch (step) {
      case APPLICATION_STEPS.application_rate:
        return () => setRateEditing?.(true);
      case APPLICATION_STEPS.questionnaire:
        return () => setQuestionnaireEditing?.(true);
      case APPLICATION_STEPS.video_interview:
        return () =>
          isPartner || isCandidate ? {} : navigate(LINKS.profile_video_interviews(missionRoleApplicant?.profile?.slug));
      case APPLICATION_STEPS.profile_completion:
        return () =>
          isPartner || isCandidate ? {} : navigate(LINKS.private_profile(missionRoleApplicant?.profile?.slug));
      default:
        return () => {};
    }
  };

  const getSteps = (checklist) => {
    return getApplicationSteps(checklist)?.map((step) => ({
      name: step.name,
      label: `Missions.Applicants.ApplicantProgress.Step.${step.name}`,
      isComplete: step.name === APPLICATION_STEPS.profile_completion ? isProfileComplete : step.fulfilled,
      onClick: handleStepClick(step.name),
    }));
  };

  const steps = getSteps(missionRoleApplicant?.applicationChecklist);

  return (
    <Col gap="1rem">
      {!hideProgress && (
        <Progress title="Missions.Applicants.ApplicantProgress.Progress.Title" steps={steps} {...props} />
      )}
      {!hideSubmitButton && (
        <ApplicationSubmitButton missionRoleApplicant={missionRoleApplicant} canSubmit={canSubmit} />
      )}
    </Col>
  );
};

ApplicantProgress.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
  setRateEditing: PropTypes.func.isRequired,
  setQuestionnaireEditing: PropTypes.func.isRequired,
  hideSubmitButton: PropTypes.bool,
  hideProgress: PropTypes.bool,
};

export default ApplicantProgress;
