import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors } from "style";
import { H1, MarketingHeader } from "components/Text";
import CheckList from "components/CheckList";
import Image from "components/Image";
import Hero from "assets/images/hero-device-1300x1898-c-center.png";
import Background from "assets/images/main-hero-bg.webp";

/**
 * MissionRolesPublicMemberExperience
 */
const MissionRolesPublicMemberExperience = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid gap={`10rem`} css={styles.inner_container}>
        <Grid.col start={4} end={10} css={styles.title_container}>
          <H1 isPublic>
            <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Title" />            
          </H1>
        </Grid.col>
        <Grid.col start={1} end={6}>
          <Col gap="1rem">            
            <CheckList css={styles.checklist} data={[
              <Col css={styles.item_container}>
                <MarketingHeader>
                  <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point1Title" />
                </MarketingHeader>
                <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point1Description" />
              </Col>,             
              <Col css={styles.item_container}>
                <MarketingHeader>
                  <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point2Title" />
                </MarketingHeader>
                <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point2Description" />
              </Col>,
              <Col css={styles.item_container}>
                <MarketingHeader>
                  <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point3Title" />
                </MarketingHeader>
                <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point3Description" />
              </Col>,
              <Col css={styles.item_container}>
                <MarketingHeader>
                  <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point4Title" />
                </MarketingHeader>
                <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point4Description" />
              </Col>,
              <Col css={styles.item_container}>
                <MarketingHeader>
                  <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point5Title" />
                </MarketingHeader>
                <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicMemberExperience.Point5Description" />
              </Col>,
            ]}
            />           
          </Col>
        </Grid.col>
        <Grid.col start={6} end={13} css={styles.image_container}>
          <Image url={Hero} alt="Mission" css={styles.image} />
        </Grid.col>
      </Grid>
    </NarrowContainer>
  </div>
);

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.white} url(${Background}) no-repeat center center;
    padding: 14rem 0 14rem 0;
    background-size: 50%;
    background-position: right;

   @media (max-width: ${breakpoints.mobile}) {
      padding: 6rem 0 0rem 0;
      background:#fff; 
    }
  `,
  inner_container: css`
    @media (max-width: ${breakpoints.mobile}) {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
  `,
  checklist: css`
    color: ${colors.purpleRainBase};
  `,
  item_container: css`
    margin-bottom: 2rem;
    line-height: 1.5;
    margin-top: -0.4rem;
    color: ${colors.grayAnatomyBase};
  `,
  subtitle: css`
    font-weight:500;
  `,
  title_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    text-align:center;
    margin-bottom:8rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom:0rem;
    }
  `,
  image_container:css`
    max-width: 100%;
    overflow: hidden;
  `,
  image: css`
    width:100%;
    border-radius: 3rem;

     @media (max-width: ${breakpoints.mobile}) {
      transform: scale(1.6);
      margin-bottom: -10rem;
    }
  `,
};

export default MissionRolesPublicMemberExperience;
