import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { formatDecimal } from "utils";
import { MUTATION_TYPE, useToast } from "hooks";
import { UPDATE_APPLICANT } from "graphql/mutations";
import { CURRENCIES, RATE_FREQUENCIES } from "constants/index";
import { ModalForm } from "components/Modal";
import { DECORATORS, SIZE, TYPE } from "components/Form";
import { ICON_POSITION, ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * ApplicationEditForm
 *
 * @param {Object}    missionRoleApplicant
 * @param {Boolean}   show
 * @param {Function}  onClose
 */
const ApplicationEditForm = ({ missionRoleApplicant, show, onClose, ...props }) => {
  const { toast } = useToast();
  const rate = missionRoleApplicant?.rate;

  const handleComplete = () => {
    toast.success(<FormattedMessage id="MissionRoleApplicant.Update.Toast.Success" />);
  };

  return (
    <ModalForm
      title={<FormattedMessage id="Missions.Applicants.ApplicationRateForm.ModalForm.Title" />}
      data={FORM_DATA}
      initialValues={{ rate: { amount: rate?.amount, currency: rate?.currency, frequency: RATE_FREQUENCIES.hourly } }}
      updateId={missionRoleApplicant.id}
      mutationType={MUTATION_TYPE.update}
      mutationData={{
        mutationName: {
          update: UPDATE_APPLICANT,
        },
      }}
      show={show}
      onClose={onClose}
      onCompleted={handleComplete}
      {...props}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Hourly Rate",
        type: TYPE.input,
        size: SIZE.medium,
        parentObjectKey: "rate",
        valueFunc: (val) => formatDecimal(val),
        properties: {
          name: "amount",
          type: "number",
          required: true,
          min: 1,
          step: 0.01,
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.dollarBill,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        label: "Currency",
        type: TYPE.select,
        size: SIZE.medium,
        parentObjectKey: "rate",
        properties: {
          name: "currency",
          required: true,
          options: CURRENCIES,
        },
        decorator: {
          type: DECORATORS.icon,
          props: {
            type: ICON_TYPE.coin,
            size: ICON_SIZE.medium,
            position: ICON_POSITION.overlay,
          },
        },
      },
      {
        type: TYPE.hidden,
        properties: {
          name: "id",
        },
      },
    ],
  },
];

ApplicationEditForm.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ApplicationEditForm;
