import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { MUTATION_TYPE, useToast } from "hooks";
import { css } from "@emotion/react";
import { UPDATE_APPLICANT } from "graphql/mutations";
import { AVAILABILITIES } from "constants/index";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { SIZE, TYPE } from "components/Form";

/**
 * ApplicationQuestionnaireForm
 *
 * @param {Object}    missionRoleApplicant
 * @param {Object}    questionnaire
 * @param {Function}  refetch
 * @param {Object}    answers
 * @param {Boolean}   show
 * @param {Function}  onClose
 */
const ApplicationQuestionnaireForm = ({
  questionnaire,
  answers,
  refetch,
  missionRoleApplicant,
  show,
  onClose,
  ...props
}) => {
  const { toast } = useToast();

  const handleComplete = () => {
    refetch();
    toast.success(<FormattedMessage id="MissionRoleApplicant.Update.Toast.Success" />);
  };

  return (
    <ModalForm
      title={<FormattedMessage id="Missions.Applicants.ApplicationQuestionnaireForm.ModalForm.Title" />}
      data={FORM_DATA(questionnaire[0]?.items || [])}
      onCompleted={handleComplete}
      initialValues={{
        questions: answers,
        noticePeriod: missionRoleApplicant?.noticePeriod,
        availablePartTimeDuringNotice: missionRoleApplicant?.availablePartTimeDuringNotice,
      }}
      updateId={missionRoleApplicant.id}
      mutationType={MUTATION_TYPE.update}
      mutationData={{
        mutationName: {
          update: UPDATE_APPLICANT,
        },
      }}
      size={MODAL_SIZE.large}
      show={show}
      onClose={onClose}
      {...props}
    />
  );
};

const FORM_DATA = (questionnaire) => [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: <FormattedMessage id="Missions.MissionRole.ApplyForm.TimeBeforeStarting" />,
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "noticePeriod",
          required: true,
          options: AVAILABILITIES,
        },
      },
      {
        label: <FormattedMessage id="Missions.MissionRole.ApplyForm.AvailablePartTimeDuringNotice" />,
        type: TYPE.select,
        size: SIZE.xlarge,
        properties: {
          name: "availablePartTimeDuringNotice",
          required: true,
          options: [
            { value: true, label: "Yes" },
            { value: false, label: "No" },
          ],
        },
      },
      ...questionnaire.map((field, index) => ({ ...field, label: `Q${index + 3}: ${field.label}` })),
    ],
  },
];

ApplicationQuestionnaireForm.propTypes = {
  questionnaire: PropTypes.array.isRequired,
  missionRoleApplicant: PropTypes.object.isRequired,
  answers: PropTypes.object.isRequired,
  refetch: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ApplicationQuestionnaireForm;
