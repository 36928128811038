import React, { useContext } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ThemeContext, fontSize, colors, breakpoints } from "style";
import { TextLoader } from "components/Loader";

/**
 * Heading 1
 */
const StyledH1 = styled.h1`
  font-size: ${({ isPublic }) => (isPublic ? fontSize.xxxxxlarge : fontSize.xxlarge)};
  font-weight: 500;
  ${({ isPublic }) => isPublic && `letter-spacing: -.22rem;`}
  margin: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  ${(props) => props.themeStyle}

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fontSize.xlarge};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: ${fontSize.xlarge};
    ${({ isPublic }) =>
      isPublic &&
      `
      font-size: ${fontSize.xxlarge};
      letter-spacing: -.1rem;
    `}
  }
`;

const H1 = ({ loading, ...props }) => {
  const theme = useContext(ThemeContext);

  if (loading) {
    return (
      <StyledH1 themeStyle={theme.h1} loading={loading} {...props}>
        <TextLoader css={styles.loader} />
      </StyledH1>
    );
  } else {
    return <StyledH1 themeStyle={theme.h1} {...props} />;
  }
};

H1.propTypes = {
  loading: PropTypes.bool,
};

const styles = {
  loader: css`
    width: 50rem;
    mac-width: 100%;
    height: 2.5rem;
    background: ${colors.grayAnatomyLight4};
  `,
};

export default H1;
