import React from "react";
import { FormattedMessage } from "react-intl";
import { css } from "@emotion/react";
import { Col, Grid, NarrowContainer } from "components/Containers";
import { breakpoints, colors, fontSize,  } from "style";
import { H1, MarketingHeader } from "components/Text";
import Asad from "assets/images/asadullah_closeup.jpg";
import Image from "components/Image";
import { MarketingButton } from "components/Buttons";
import { Link } from "components/Links";


/**
 * MissionRolesPublicStory
 */
const MissionRolesPublicStory = () => (
  <div css={styles.container}>
    <NarrowContainer>
      <Grid cols={12} gap={`5rem`} css={styles.inner_container}>
        <Grid.col start={4} end={10}>
          <Col gap="0" css={styles.title_container}>
            <H1 isPublic css={styles.title}>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicStory.Title" />      
            </H1>           
          </Col>
        </Grid.col>   
        <Grid.col start={1} end={5}>
          <Col css={[styles.item_container, styles.container_left]}>
            <MarketingHeader>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicStory.Point1Title" />
            </MarketingHeader>
            <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicStory.Point1Description" />
          </Col>
        </Grid.col>
        <Grid.col start={5} end={9}>
          <Image url={Asad} alt="Asad Ullah" css={styles.image} />          
        </Grid.col>
        <Grid.col start={9} end={13}>
          <Col css={[styles.item_container]}>
            <MarketingHeader>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicStory.Point2Title" />
            </MarketingHeader>
            <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicStory.Point2Description" />
          </Col>
        </Grid.col>
        <Grid.col start={5} end={9} css={styles.title_container}>
          <Link to="https://mission.dev/blog/a-journey-of-passion-and-growth-spotlight-on-asad-ullah" target="_blank">
            <MarketingButton>
              <FormattedMessage id="Missions.Roles.Public.MissionRolesPublicStory.Button" />
            </MarketingButton>
          </Link>
        </Grid.col>
      </Grid>
    </NarrowContainer>
  </div>
);

const styles = {
  container: css`
    color: ${colors.grayAnatomyDark};
    background: ${colors.grayAnatomyLight5};
    padding: 14rem 0 14rem 0;

    @media (max-width: ${breakpoints.mobile}) {
      padding: 6rem 0 4rem 0;
    }
  `,
  inner_container: css`
    @media (max-width: ${breakpoints.mobile}) {
      grid-column-gap: 2rem;
      grid-row-gap: 2rem;
    }
  `,
  item_container: css`
    height:100%;
    justify-content: center;
    font-size: ${fontSize.medium};
    line-height:1.5;

    @media (max-width: ${breakpoints.mobile}) {
      text-align: center;
    }
  `,
  container_left: css`
    align-items: flex-end;
    text-align: right;

    @media (max-width: ${breakpoints.mobile}) {
      align-items: center;
      text-align: center;
    }
  `,
  title_container: css`
    display:flex;
    align-items:center; 
    justify-content:center;
    margin-bottom:4rem;
    text-align:center;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom:2rem;
    }
  `,
  subtitle: css`
    font-weight:600;
  `,
  image: css`
    width: 100%;
    border-radius: 2rem;
    margin-bottom:4rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-bottom:0rem;
    }
  `,
};

export default MissionRolesPublicStory;
