/** @jsx jsx */
import styled from "@emotion/styled";
import { breakpoints, colors, fontSize } from "style";

/**
 * TableHeaderCell
 */
const TableHeaderCell = styled.th`
  position: sticky;
  top: 0rem;
  font-size: ${fontSize.xsmall};
  line-height: 1.7rem;
  font-weight: 500;
  padding: 0;
  height: 4.2rem;
  vertical-align: middle;
  background: #fff;
  box-shadow: inset 0 -1px 0 ${colors.grayAnatomyLight4};
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  position: sticky;
  padding: 0rem 1.2rem;

  ${(props) =>
    props.bgcolor &&
    `
    background: ${props.bgcolor};
  `}

  &:first-of-type {
    padding-left: 3rem;
  }

  &:last-of-type {
    padding-right: 3rem;
  }

  @media (max-width: ${breakpoints.portrait}) {
    &:first-of-type {
      padding-left: 2.5rem;
    }

    &:last-of-type {
      padding-right: 2.5rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    display: none;

    &:first-of-type {
      padding-left: 2rem;
      display: table-cell;
    }

    &:nth-of-type(2) {
      display: table-cell;
    }

    &:nth-of-type(3) {
      display: table-cell;
    }

    &:last-of-type {
      padding-right: 2rem;
      display: table-cell;
    }
  }

  ${(props) => props.expander && `padding:0;`}
`;

export default TableHeaderCell;
