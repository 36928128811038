import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { Toggle } from "components/Form";
import { Row } from "components/Containers";
import { fontSize, colors } from "style";
import { useMissionRoles, useMissions } from "hooks";
import { TextLoader } from "components/Loader";
import { MISSION_ROLE_STATES } from "constants/index";

/**
 * MissionPublishButton
 *
 * @params {Object}   data
 * @params {Boolean}  loading
 */
const MissionPublishButton = ({ data, loading }) => {
  const { handlePublishUnpublish, updating } = useMissions({});
  const { data: missionRoles, loading: loadingRoles } = useMissionRoles({ missionId: data?.uuid });
  const [isPublished, setIsPublished] = useState(data?.published);
  const isRolePresent = missionRoles?.filter((item) => item.state === MISSION_ROLE_STATES.published)?.length > 0;
  const [hasRoles, setHasRoles] = useState(isRolePresent);

  useEffect(() => {
    setIsPublished(data?.published);
  }, [data?.published]);

  useEffect(() => {
    setHasRoles(isRolePresent);
  }, [missionRoles]);

  useEffect(() => {
    if (data?.published !== isPublished) {
      handlePublishUnpublish({
        id: data?.uuid,
        publish: isPublished,
        setIsPublished,
      });
    }
  }, [isPublished]);

  if (loading) {
    return (
      <Row>
        <TextLoader />
      </Row>
    );
  }

  return (
    <Row>
      <span css={styles.label}>
        {!loading && !loadingRoles && (
          <FormattedMessage
            id={hasRoles ? "Missions.MissionPublishButton.Label" : "Missions.MissionPublishButton.NoRoles"}
          />
        )}
      </span>
      {hasRoles && (
        <Toggle value={isPublished} onChange={() => setIsPublished(!isPublished)} disabled={loading || updating} />
      )}
    </Row>
  );
};

const styles = {
  label: css`
    font-size: ${fontSize.small};
    color: ${colors.grayAnatomyLight6};
    font-weight: 500;
  `,
};

MissionPublishButton.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool,
};

export default MissionPublishButton;
