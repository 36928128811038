import { gql } from "@apollo/client";

const minimalMissionRoleFields = `
  slug
  name
  mission {
    uuid
    name
  }
`;

const commonMissionRoleFields = `
  uuid
  name
  assessmentId
  description
  descriptionJson
  disciplines {
    id
    name
  }
  primarySkills {
    id
    name
  }
  secondarySkills {
    id
    name
  }
  locations {
    id
    name
  }
  challenges {
    id
    name
  }
`;

const publicViewSettingsFields = `
  publicViewSettings {
    videoLink
    videoLinkEnabled
  }
`;

const coreMissionRoleFields = `
  id
  archetype
  slug
  workArrangement
  location
  startDate
  engagementType
  engagementTime
  applicantApplied
  targetRate
  targetRateCurrency
  state
  published
  remoteAnywhere
  applicantsCount
  applicantsProfileIds
  assignedTo {
    id
    name
  }
  talentOwner {
    id
    name
  }
  timezone {
    name
    value
  }
`;

const CORE_MISSION_ROLE_FIELDS = gql`
  fragment MissionRoleFields on MissionRole {
    ${coreMissionRoleFields}
    ${commonMissionRoleFields}
    ${publicViewSettingsFields}
  }
`;

const PUBLIC_MISSION_ROLE_FIELDS = gql`
  fragment PublicMissionRoleFields on PublicMissionRole {
    uuid
    slug
    name
    workArrangement
    engagementType
    location
    state
    description
    descriptionJson
    disciplines {
      id
      name
    }
    primarySkills {
      id
      name
    }
    secondarySkills {
      id
      name
    }
    locations {
      id
      name
    }
    challenges {
      id
      name
    }
  }
`;

const MINIMAL_MISSION_ROLE_FIELDS = gql`
  fragment MinimalMissionRoleFields on MissionRole {
    ${minimalMissionRoleFields}
  }
`;

export { CORE_MISSION_ROLE_FIELDS, PUBLIC_MISSION_ROLE_FIELDS, MINIMAL_MISSION_ROLE_FIELDS };
