import React from "react";
import { Helmet } from "react-helmet";
import { useAuth } from "hooks";
import { FormattedMessage, useIntl } from "react-intl";
import { ApplicantListLayout } from "components/Missions/Applicants";
import PageTitle from "components/PageTitle";
import { Grid, PageWrapper } from "components/Containers";
import { APPLICANT_LIST_COLUMNS } from "constants/index";

/**
 * MissionApplications
 */
const MissionApplications = () => {
  const intl = useIntl();
  const { isFreelancer, profileId } = useAuth();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Applications.Title" })}</title>
      </Helmet>
      <PageWrapper>
        <PageTitle title={<FormattedMessage id="Profile.Applications.Title" />} />
        <Grid topMargin>
          <Grid.col start={1} end={13}>
            <ApplicantListLayout
              showAdvancedOptions={false}
              showAdminOnlyPages={false}
              hideOrganizationFilter={true}
              hideMissionFilter={true}
              hideFilters={true}
              hideTabs={true}
              hideProgressMenu={true}
              filtersProp={isFreelancer ? { profileId } : null}
              columns={[
                APPLICANT_LIST_COLUMNS.mission,
                APPLICANT_LIST_COLUMNS.state,
                APPLICANT_LIST_COLUMNS.applied,
                APPLICANT_LIST_COLUMNS.created,
                APPLICANT_LIST_COLUMNS.navigateToApplicationButton,
              ]}
              useDataService={false}
            />
          </Grid.col>
        </Grid>
      </PageWrapper>
    </>
  );
};

export default MissionApplications;
