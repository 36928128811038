import React from "react";
import PropTypes from "prop-types";
import { useAuth, useProfile } from "hooks";
import Progress from "components/Progress";

/**
 * ProfileProgress
 *
 * @param {String}    id
 * @param {String}    title
 */
const ProfileProgress = ({ id, title, ...props }) => {
  const { profileCompletion } = useProfile({ profileId: id });
  const { canViewEditRate } = useAuth();

  return (
    <Progress
      title={title}
      steps={Object.entries(profileCompletion).map((item) => ({
        isComplete: item[1]?.isComplete,
        label: getMessageId(item[0], item[1]?.titleMessageId, { canViewEditRate }),
      }))}
      {...props}
    />
  );
};

/**
 * getMessageId
 *
 * @param {String}  key
 * @param {String}  titleId
 * @param {Object}  settings
 * @returns
 */
function getMessageId(key, titleId, settings) {
  switch (key) {
    case "rate":
      // If user doesn't have the permission to view/edit rate, then show the title "Profile.ProfileAvailability.Title"
      return settings?.canViewEditRate ? titleId : "Profile.ProfileAvailability.Title";
    default:
      return titleId;
  }
}

ProfileProgress.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string
};

ProfileProgress.defaultProps = {
  title: "Profile.ProfileProgress.Title"
};

export default ProfileProgress;
