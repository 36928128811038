import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { useQueryData, useProfile, useModalConfirmation } from "hooks";
import { GET_CHALLENGES } from "graphql/queries";
import { CHALLENGE_CATEGORIES } from "constants/index";
import { PAGINATION_TYPE } from "components/Pagination";
import { ADD_CHALLENGE_RESPONSE } from "graphql/mutations";
import { FormattedMessage } from "react-intl";
import { PrimaryLink } from "components/Links";
import { Col } from "components/Containers";

/**
 * useCandidateProfileInterview
 *
 * @param {String}   profileId
 * @param {Array}    challengeIds
 * @param {Boolean}  isAdmin
 * @param {Number}   resultsPerPage
 */
export default function useCandidateCodeChallenge({ profileId, challengeIds, isAdmin, resultsPerPage = 2 }) {
  const { show } = useModalConfirmation();
  const STATUS_PASSED = "passed";
  const STATUS_FAILED = "failed";
  const [filteredData, setFilteredData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [disciplines, setDisciplines] = useState([]);
  const { data: profileData, loading: profileLoading } = useProfile({ profileId });
  const [getChallengeUrl, { data: challengeData, loading: loadingChallengeUrl }] = useMutation(ADD_CHALLENGE_RESPONSE);
  const { hasNextPage, handleNextPage, loading, data, refetch } = useQueryData({
    queryName: GET_CHALLENGES,
    keyName: "challenges",
    resultsPerPage,
    paginationType: PAGINATION_TYPE.append,
    variables: {
      profileId,
      filters: challengeIds?.length
        ? { ids: challengeIds }
        : {
            skills: skills,
            disciplines: disciplines,
            types: CHALLENGE_CATEGORIES.vetting,
          },
    },
  });

  const unfilteredData = data?.challenges?.nodes;
  const hasChallenge = unfilteredData?.some((item) => item.challengeResponse);

  useEffect(() => {
    setSkills(profileData?.profile?.skills?.map((item) => item.name));
    setDisciplines(profileData?.profile?.disciplines?.map((item) => item.name));
  }, [profileData?.profile]);

  useEffect(() => {
    if (!challengeIds?.length) {
      refetch({ skills, disciplines });
    }
  }, [skills, disciplines, challengeIds]);

  const handleCodeChallengeClick = useCallback(
    async (challengeId, challengeResponse) => {
      const publicUrl = challengeData?.addChallengeResponse?.challengeResponse?.testUrl;

      if (isAdmin) {
        // eslint-disable-next-line no-undef
        window.open(challengeResponse?.reportUrl, "_blank");
      } else if (challengeResponse?.publicUrl) {
        window.open(challengeResponse?.publicUrl, "_blank");
      } else {
        if (publicUrl) {
          window.open(publicUrl, "_blank");
        } else {
          await getChallengeUrl({
            variables: {
              input: {
                profileId,
                params: {
                  challengeId,
                },
              },
            },
          }).then(async (d) => {
            await show(
              <FormattedMessage id="useCandidateCodeChallenge.Title" />,
              <Col gap="1rem">
                <b>
                  <FormattedMessage id="useCandidateCodeChallenge.Body1" />
                </b>
                <FormattedMessage
                  id="useCandidateCodeChallenge.Body2"
                  values={{
                    link: (
                      <PrimaryLink
                        to={d?.data?.addChallengeResponse?.challengeResponse?.testUrl}
                        target="_blank"
                        withMoreIcon
                      >
                        <FormattedMessage id="useCandidateCodeChallenge.TestUrl" />
                      </PrimaryLink>
                    ),
                  }}
                />
              </Col>,
              {
                secondaryButtonMessageId: "Global.Close",
                showSave: false,
              }
            );
          });
        }
      }
    },
    [challengeData, profileId, isAdmin, show, getChallengeUrl]
  );

  // For Admins, only show data that has a response
  useEffect(() => {
    setFilteredData(
      isAdmin
        ? unfilteredData?.filter(
            (challenge) =>
              challenge?.challengeResponse &&
              (challenge?.challengeResponse?.status === STATUS_PASSED ||
                challenge?.challengeResponse?.status === STATUS_FAILED)
          )
        : hasChallenge && !challengeIds?.length
        ? unfilteredData?.filter((challenge) => challenge?.challengeResponse)
        : unfilteredData
    );
  }, [unfilteredData, isAdmin, hasChallenge]);

  return {
    hasNextPage: isAdmin ? hasNextPage : hasChallenge ? false : hasNextPage,
    handleNextPage,
    refetch,
    loading: profileLoading || loading,
    loadingChallengeUrl,
    data: filteredData,
    handleCodeChallengeClick,
    STATUS_PASSED,
    STATUS_FAILED,
  };
}
