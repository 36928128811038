/** @jsx jsx */
import styled from "@emotion/styled";
import { breakpoints } from "style";

/**
 * Page Wrapper
 */
const PageWrapper = styled.div`
  width:100%;
  padding: 0 8rem;
  max-width: 1820px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.wide}) {
    padding: 0 16rem;
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 4rem;
  }
`;

export default PageWrapper;
