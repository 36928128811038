import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "@apollo/client";
import { useModalConfirmation, useQueryData, useToast } from "hooks";
import { GET_INTERVIEWS } from "graphql/queries";
import { HIREFLIX_INVITE_CANDIDATE } from "graphql/mutations";
import { Col } from "components/Containers";
import { PrimaryLink } from "components/Links";

/**
 * useCandidateProfileInterview
 *
 * @params {String}   profileId
 * @params {Boolean}  isAdmin
 */
export default function useCandidateProfileInterview({ profileId, isAdmin }) {
  const { show } = useModalConfirmation();
  const { toast } = useToast();
  const STATUS_COMPLETE = "completed";
  const [filteredData, setFilteredData] = useState();
  const [isComplete, setIsComplete] = useState(false);
  const [getInterviewUrl, { data: interviewData, loading: loadingInterviewUrl }] =
    useMutation(HIREFLIX_INVITE_CANDIDATE);
  const { data, loading } = useQueryData({
    queryName: GET_INTERVIEWS,
    keyName: "interviews",
    variables: {
      profileId,
    },
  });
  const unfilteredData = data?.interviews?.nodes;

  useEffect(() => {
    const completedInterview = unfilteredData?.filter((item) => item?.interviewResponse?.completed);
    const isComplete = completedInterview && completedInterview.length > 0;

    setIsComplete(isComplete);
  }, [unfilteredData]);

  // For Admins, only show data that has a response
  useEffect(() => {
    setFilteredData(
      isAdmin
        ? unfilteredData?.filter(
            (interview) => interview?.interviewResponse && interview?.interviewResponse?.status === STATUS_COMPLETE
          )
        : unfilteredData
    );
  }, [unfilteredData, isAdmin]);

  const handleInterviewLink = useCallback(
    async (item) => {
      const { id: interviewId, interviewResponse } = item;
      const publicUrl = interviewData?.addInterviewResponse?.interviewResponse?.publicUrl;

      if (isAdmin) {
        // eslint-disable-next-line no-undef
        window.location = interviewResponse?.privateUrl;
      } else if (interviewResponse?.publicUrl) {
        window.open(interviewResponse?.publicUrl, "_blank");
      } else {
        if (publicUrl) {
          window.open(publicUrl, "_blank");
        } else {
          await getInterviewUrl({
            variables: {
              input: {
                profileId,
                enableNotifications: false,
                params: {
                  interviewId,
                },
              },
            },
            onError: () => {
              toast.error(<FormattedMessage id={"Candidate.CandidateInterview.Error"} />);
            },
          }).then(async (d) => {
            if (d?.data?.addInterviewResponse?.interviewResponse?.publicUrl) {
              await show(
                <FormattedMessage id="useCandidateProfileInterview.Title" />,
                <Col gap="1rem">
                  <b>
                    <FormattedMessage id="useCandidateProfileInterview.Body1" />
                  </b>
                  <FormattedMessage
                    id="useCandidateProfileInterview.Body2"
                    values={{
                      link: (
                        <PrimaryLink
                          to={d?.data?.addInterviewResponse?.interviewResponse?.publicUrl}
                          target="_blank"
                          withMoreIcon
                        >
                          <FormattedMessage id="useCandidateProfileInterview.TestUrl" />
                        </PrimaryLink>
                      ),
                    }}
                  />
                </Col>,
                {
                  secondaryButtonMessageId: "Global.Close",
                  showSave: false,
                }
              );
            }
          });
        }
      }
    },
    [interviewData, profileId, isAdmin, show, getInterviewUrl]
  );

  return {
    data: filteredData,
    loading,
    isComplete,
    handleInterviewLink,
    loadingInterviewUrl,
    STATUS_COMPLETE,
  };
}
