import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import Card from "components/Card";
import { Label, Select } from "components/Form";
import { Grid } from "components/Containers";
import { APPLICANT_LIST_SORTING_OPTIONS, DIRECTION } from "constants/index";
import { toUcFirst } from "utils";

/**
 * ApplicantListSorts
 * @param {Object}    selected
 * @param {Function}  setSelected
 */
const ApplicantListSorts = ({ selected, setSelected }) => (
  <Card>
    <Grid>
      <Grid.col colSpan={3}>
        <Label>
          <FormattedMessage id="Global.SortBy" />
        </Label>
        <Select
          name="sortKey"
          value={selected?.sortKey}
          options={APPLICANT_LIST_SORTING_OPTIONS}
          onChange={(name, val) => setSelected((prev) => ({ ...prev, [name]: val }))}
          isClearable={false}
        />
      </Grid.col>
      <Grid.col colSpan={3}>
        <Label>
          <FormattedMessage id="Global.SortDirection" />
        </Label>
        <Select
          name="sortDirection"
          value={selected?.sortDirection}
          options={Object.entries(DIRECTION).map(([label, value]) => ({ label: toUcFirst(label), value }))}
          onChange={(name, val) => setSelected((prev) => ({ ...prev, [name]: val }))}
          isClearable={false}
        />
      </Grid.col>
    </Grid>
  </Card>
);

ApplicantListSorts.propTypes = {
  selected: PropTypes.object,
  setSelected: PropTypes.func,
};

export default ApplicantListSorts;
