import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { ApplicationDetailsModal } from "components/Missions/Applicants";
import { APPLICANT_TYPES, LINKS } from "constants/index";
import { DataTableProvider } from "components/DataTable";
import { useAuth } from "hooks";

let timer;

/**
 * ApplicantList
 *
 * @param {Object} data
 * @param {Boolean} showAdvancedOptions
 * @param {Function} getAdvancedOptions
 * @param {Number} resultsPerPage
 * @param {Object} roles
 * @param {Object} selectedState
 * @param {Array} columns
 * @param {Function} handleNextPage
 * @param {Function} handlePreviousPage
 * @param {Boolean} hasNextPage
 * @param {Boolean} hasPreviousPage
 * @param {Number} currentPage
 * @param {Object} pageInfo
 * @param {Boolean} loading
 * @param {Function} handlePageChange
 * @param {Number} totalCount
 */
const ApplicantList = ({
  data: dataProp,
  showAdvancedOptions,
  getAdvancedOptions,
  resultsPerPage,
  roles,
  selectedState,
  columns,
  handleNextPage,
  handlePreviousPage,
  hasNextPage,
  hasPreviousPage,
  currentPage,
  pageInfo,
  loading: loadingProp,
  handlePageChange,
  totalCount,
  ...props
}) => {
  const navigate = useNavigate();
  const { isAdmin } = useAuth();
  const [application, setApplication] = useState();
  const [selected, setSelected] = useState([]);
  const [selectable, setSelectable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const navigateToApplication = (application) => navigate(LINKS.mission_application(application?.uuid));

  /**
   * @description Sync `loading` with `loadingProp` or `applicantState` state changes.
   */
  useEffect(() => {
    timer = setTimeout(() => setLoading(loadingProp), loadingProp ? 500 : 0);

    return () => clearTimeout(timer);
  }, [loadingProp, selectedState?.applicantState]);

  /**
   * @description Sync `data` with `dataProp` or `loadingProp` state changes.
   */
  useEffect(() => {
    if (!loadingProp) {
      setData(dataProp);
    }
  }, [loadingProp, dataProp]);

  /**
   * @description Set selectable based on state and advanced options.
   */
  useEffect(() => {
    setSelectable(showAdvancedOptions && !!selectedState?.applicantState);
  }, [showAdvancedOptions, selectedState?.applicantState]);

  /**
   * @description Memoizes the filtered columns after building the context.
   */
  const filteredColumns = useMemo(() => {
    const context = { setApplication, navigateToApplication, showDataFormattedName: isAdmin };
    const buildColumnsContext = (cols, context) => cols?.map((col) => (typeof col === "function" ? col(context) : col));
    const contextColumns = buildColumnsContext(columns, context);
    const roleNames = roles?.map((role) => role.name);

    const filterColumns = (cols, currentState) =>
      cols.filter(
        ({ restrict }) =>
          !restrict ||
          (restrict.roles?.some((allowed) => roleNames.includes(allowed)) && restrict?.states?.includes(currentState))
      );

    return filterColumns(contextColumns, selectedState?.applicantState);
  }, [columns, roles, selectedState?.applicantState, setApplication]);

  return (
    <>
      <DataTableProvider
        data={data}
        selected={selected}
        selectable={selectable}
        onSelect={setSelected}
        columns={filteredColumns}
        selectionInitialState={selected}
        resultsPerPage={resultsPerPage}
        clearable={false}
        clearCacheValue={selectedState?.applicantState}
        loading={loadingProp || loading}
        rowOptions={
          selectable ? ({ id, ...rest }) => getAdvancedOptions({ id: id || rest.profile?.uuid, ...rest }) : null
        }
        headerOptions={
          selectable
            ? (selection) => getAdvancedOptions({ missionRole: { slug: data?.[0]?.missionRole?.slug } }, selection)
            : null
        }
        selectionKeyFunc={({ id, profile }) =>
          APPLICANT_TYPES.recommended === selectedState?.applicantState ? profile?.uuid : id
        }
        paginationProps={{
          countMessage: getCountMsgId(selectedState?.applicantState),
          onNextPage: handleNextPage,
          onPreviousPage: handlePreviousPage,
          hasNextPage: !loading && hasNextPage,
          hasPreviousPage: !loading && hasPreviousPage,
          resultsPerPage,
          currentPage,
          pageInfo,
          loading: loading,
          handlePageChange,
          totalCount,
        }}
        {...props}
      />
      {application && (
        <ApplicationDetailsModal application={application} show={!!application} setApplication={setApplication} />
      )}
    </>
  );
};

const getCountMsgId = (state) => {
  switch (state) {
    case APPLICANT_TYPES.pending:
    case APPLICANT_TYPES.applied:
    case APPLICANT_TYPES.shortlisted:
    case APPLICANT_TYPES.interviewed:
    case APPLICANT_TYPES.selected:
    case APPLICANT_TYPES.unselected:
      return "Global.Applications.Count";
    case APPLICANT_TYPES.recommended:
      return "Global.Recommendations.Count";
    default:
      return "Global.Invitations.Count";
  }
};

ApplicantList.defaultProps = {
  showAdvancedOptions: false,
  columns: [],
};

ApplicantList.propTypes = {
  data: PropTypes.array,
  showAdvancedOptions: PropTypes.bool,
  getAdvancedOptions: PropTypes.func,
  resultsPerPage: PropTypes.number,
  roles: PropTypes.object,
  selectedState: PropTypes.object,
  columns: PropTypes.array,
  handleNextPage: PropTypes.func,
  handlePreviousPage: PropTypes.func,
  hasNextPage: PropTypes.bool,
  hasPreviousPage: PropTypes.bool,
  currentPage: PropTypes.number,
  pageInfo: PropTypes.object,
  loading: PropTypes.bool,
  handlePageChange: PropTypes.func,
  totalCount: PropTypes.number,
};

export default ApplicantList;
