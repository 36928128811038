import React from "react";
import PropTypes from "prop-types";
import App from "App";
import { useParams } from "react-router-dom";
import { css } from "@emotion/react";
import { Col } from "components/Containers";
import { colors, themes } from "style";
import { useMissionRole } from "hooks";
import { 
  MissionRolesPublicHeader, 
  MissionRolesPublicDetails, 
  MissionRolesPublicHowItWorks, 
  MissionRolesPublicMemberExperience, 
  MissionRolesPublicTestimonials,
  MissionRolesPublicTalentSuccess,
  MissionRolesPublicStory,
  MissionRolesPublicFooter,
  MissionRolesPublicFaq
} from "components/Missions/Roles/Public";
import { PublicFooter, PublicHeader } from "components/Public";

/**
 * PublicMissionRole
 */
const PublicMissionRole = () => {
  const { id } = useParams();
  const { data: role, loading } = useMissionRole({ id, isPublicRole: true });

  return (
    <App theme={themes.website}>
      <>
        <Col>
          <PublicHeader />
          <MissionRolesPublicHeader role={role} loading={loading} />                       
          <MissionRolesPublicDetails role={role} loading={loading} />            
          <MissionRolesPublicHowItWorks />     
          <MissionRolesPublicMemberExperience />     
          <MissionRolesPublicTestimonials />
          <MissionRolesPublicTalentSuccess />
          <MissionRolesPublicStory />
          <MissionRolesPublicFaq />
          <MissionRolesPublicFooter />
          <PublicFooter />
        </Col>
      </>
    </App>
  );
};

const styles = {
  title: css`
    color: ${colors.white};
  `,
};

PublicMissionRole.propTypes = {
  id: PropTypes.number,
};

export default PublicMissionRole;
