import React from "react";
import { css } from "@emotion/react";
import { colors } from "style";
import MissionIcon from "assets/images/MissionIconBlack.svg";
import MissionGradient from "assets/images/gradient_medium.webp";

/**
 * MarketingLogo
 */
const MarketingLogo = (props) => <div css={styles.logo} {...props} />;

const styles = {
  logo: css`
    width: 5rem;
    flex-shrink: 0;
    height: 2.2rem;
    mask-image: url(${MissionIcon});
    overflow: hidden;
    position: relative;
    width: 4.8rem;

    &:hover:after {
      opacity: 1;
    }

    &:before,
    &:after {
      bottom: 1px;
      content: "";
      left: 1px;
      position: absolute;
      right: 1px;
      top: 1px;
    }

    &:before {
      background-color: ${colors.black};
    }

    &:after {
      background-image: url(${MissionGradient});
      background-size: cover;
      opacity: 0;
      transition-duration: 0.3s;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
    }
  `,
};

export default MarketingLogo;
