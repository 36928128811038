import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Row } from "components/Containers";
import { colors, fontSize } from "style";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";

/**
 * CheckList
 *
 * @param {Array} data
 */
const CheckList = ({ data, ...props }) => (
  <ul css={styles.container} {...props}>
    {data.map((item, index) => (
      <li key={index}>
        <Row css={styles.item_container}>
          <div css={styles.icon_container}>
            <Icon type={ICON_TYPE.checkFilled} size={ICON_SIZE.xxlarge} color="inherit" />
          </div>
          <span>{item}</span>
        </Row>
      </li>
    ))}
  </ul>
);

const styles = {
  container: css`
    margin: 0;
    padding: 0;

    > li {
      list-style: none;
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 2rem 0;
      font-size: ${fontSize.medium};
    }
  `,
  item_container: css`
    flex-wrap: initial;
    align-items: flex-start;
  `,
  icon_container: css`
    margin-top: -0.6rem;
  `,
  title: css`
    color: ${colors.white};
  `,
};

CheckList.propTypes = {
  data: PropTypes.array,
};

export default CheckList;
