import React from "react";
import PropTypes from "prop-types";
import { NavList } from "components/Nav";
import { PublicHeader } from "components/Public";

/**
 * Header
 *
 * @param {Array} pages
 */
const Header = ({ pages }) => {
  return (
    <>
      <PublicHeader pages={pages}>
        <div>
          <NavList pages={pages} hideOnMobile routerEnabled />
        </div>
      </PublicHeader>
    </>
  );
};

Header.propTypes = {
  pages: PropTypes.array.isRequired,
};

export default Header;
