import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
import { useProfile, useAuth } from "hooks";
import DisplayBox from "components/DisplayBox";
import RenderIconWithText, { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import Icon, { ICON_SIZE, ICON_TYPE } from "components/Icon";
import { LINKS } from "constants/index";
import { TagComplete, TagDuration } from "components/Tags";
import { colors, margins } from "style";

/**
 * CandidateProfileStatus
 */
const CandidateProfileStatus = ({ ...props }) => {
  const { profileId } = useAuth();
  const { data, isProfileComplete } = useProfile({ profileId });
  const navigate = useNavigate();

  return (
    <DisplayBox
      title={<FormattedMessage id="Candidate.CandidateProfileStatus.Title" />}
      subtitle={!isProfileComplete ? <TagDuration text={"~10 mins"} /> : <TagComplete />}
      description={<FormattedMessage id="Candidate.CandidateProfileStatus.Description" />}
      numColumns={1}
      rowGap={0}
      marginSize={margins.normal}
      data={[data]}
      itemRenderer={(data, index) => (
        <RenderIconWithText
          key={index}
          size={RENDER_SIZE.large}
          icon={<Icon type={ICON_TYPE.file} size={ICON_SIZE.xxlarge} color={colors.purpleRainDark2} />}
          text={{
            title: <FormattedMessage id={`Candidate.CandidateProfileStatus.LineItem`} />,
            link: <FormattedMessage id={`Candidate.CandidateProfileStatus.EditLink`} />,
            message: <FormattedMessage id={`Candidate.CandidateProfileStatus.StatusComplete`} />,
          }}
          onClick={() => navigate(LINKS.candidate_profile)}
          status={{
            showIcon: isProfileComplete, // Only show icon if profile is complete
            success: isProfileComplete,
          }}
        />
      )}
      {...props}
    />
  );
};

export default CandidateProfileStatus;
