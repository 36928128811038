import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import DisplayBox from "components/DisplayBox";
import { css } from "@emotion/react";
import { colors, fontSize } from "style";

/**
 * ApplicationQuestionnaireResponses
 *
 * @param {Object} data
 * @param {String} loading
 * @param {Object} assessment
 */
const ApplicationQuestionnaireResponses = ({ data, loading, assessment, ...props }) => {
  const { questions, answers } = assessment || {};

  return (
    <DisplayBox
      numColumns={1}
      loading={loading}
      data={[
        ...STATIC_QUESTIONS(data),
        ...(questions?.map(({ wording, id, properties }, index) => ({
          title: (
            <>
              Q{index + 3}: {wording} {properties?.required ? "" : <OptionalText />}
            </>
          ),
          body: (
            <div css={styles.body}>
              {answers[id] ? <>A: {answers[id]}</> : <FormattedMessage id="Global.NotProvided" />}
            </div>
          ),
        })) || []),
      ]}
      hideHeader
      {...props}
    />
  );
};

const OptionalText = () => (
  <span css={styles.optionalText}>
    (<FormattedMessage id="Global.Optional" />)
  </span>
);

const STATIC_QUESTIONS = ({ noticePeriod, availablePartTimeDuringNotice } = {}) => [
  {
    title: <FormattedMessage id="Missions.MissionRole.ApplyForm.TimeBeforeStarting" />,
    body: (
      <div css={styles.body}>
        A:
        <FormattedMessage id={`MissionRoleApplicant.NoticePeriod.${noticePeriod}`} />
      </div>
    ),
  },
  {
    title: <FormattedMessage id="Missions.MissionRole.ApplyForm.AvailablePartTimeDuringNotice" />,
    body: (
      <div css={styles.body}>
        A:
        <FormattedMessage
          id={`MissionRoleApplicant.QuestionnaireResponseBox.PartTimeDuringNotice.${availablePartTimeDuringNotice}`}
        />
      </div>
    ),
  },
];

const styles = {
  body: css`
    font-size: ${fontSize.small};
    font-weight: 400;
  `,
  optionalText: css`
    font-style: italic;
    color: ${colors.grayAnatomyBase2};
    font-size: ${fontSize.xsmall};
    margin-left: 0.25rem;
  `,
};

ApplicationQuestionnaireResponses.propTypes = {
  data: PropTypes.object.isRequired,
  assessment: PropTypes.object,
  loading: PropTypes.bool,
};

export default ApplicationQuestionnaireResponses;
