import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DisplayBox from "components/DisplayBox";
import RenderIconWithText, { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import Icon, { ICON_TYPE, ICON_SIZE } from "components/Icon";
import { TagComplete, TagDuration } from "components/Tags";
import { useCandidateProfileInterview, useProfile } from "hooks";
import { MEMBER_STATUS } from "constants/index";
import { colors, margins } from "style";
import { Spinner } from "components/Loader";

/**
 * CandidateInterview
 *
 * @param {String}   profileId
 * @param {Boolean}  isAdmin
 */
const CandidateInterview = ({ profileId, isAdmin, ...props }) => {
  const { data: profileData, isInterviewComplete } = useProfile({ profileId });
  const { data, loading, handleInterviewLink, STATUS_COMPLETE, loadingInterviewUrl } = useCandidateProfileInterview({
    profileId: profileData?.profile?.id, // Need to pass numerical profile id
    isAdmin,
  });
  const noResults = !loading && (!data || data?.length === 0);
  const isRejected = !isAdmin && profileData?.profile?.status === MEMBER_STATUS.rejected;
  const isEmpty = noResults || isRejected;

  return (
    <DisplayBox
      id="VideoInterview"
      title={<FormattedMessage id="Candidate.CandidateInterview.Title" />}
      subtitle={!isInterviewComplete ? <TagDuration text={"~15 mins"} /> : <TagComplete />}
      description={<FormattedMessage id="Candidate.CandidateInterview.Description" />}
      emptyMsg={<FormattedMessage id="Candidate.CandidateInterview.Empty" />}
      loading={loading}
      isEmpty={isEmpty}
      rowGap={0}
      numColumns={1}
      marginSize={margins.normal}
      data={data}
      itemRenderer={(data, index) => {
        const interviewCompleted = data?.interviewResponse?.status === STATUS_COMPLETE;

        return (
          <RenderIconWithText
            key={index}
            loading={loading}
            size={RENDER_SIZE.large}
            icon={<Icon type={ICON_TYPE.video} size={ICON_SIZE.xxlarge} color={colors.purpleRainDark2} />}
            text={{
              title: data?.name,
              link: loadingInterviewUrl ? (
                <Spinner size="2rem" />
              ) : (
                <FormattedMessage id={`Candidate.CandidateInterview.${isAdmin ? `AdminIncompleteLink` : `Link`}`} />
              ),
              message: (
                <FormattedMessage id={isAdmin ? `Candidate.CandidateInterview.AdminLink` : `Global.Completed`} />
              ),
            }}
            hasLink={isAdmin || !interviewCompleted}
            onClick={(event) => {
              event.stopPropagation();
              handleInterviewLink(data);
            }}
            status={{
              showIcon: interviewCompleted,
              success: interviewCompleted,
            }}
          />
        );
      }}
      {...props}
    />
  );
};

CandidateInterview.propTypes = {
  profileId: PropTypes.string,
  isAdmin: PropTypes.bool,
};

export default CandidateInterview;
