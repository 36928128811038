import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage, useIntl } from "react-intl";
import { ADD_MISSION_ROLE_APPLICANT } from "graphql/mutations";
import Modal, { ModalForm, MODAL_SIZE } from "components/Modal";
import { RoleApplyError, RoleEarningPotential } from "components/Missions/Roles";
import { SIZE, TYPE, DECORATORS } from "components/Form";
import Icon, { ICON_TYPE, ICON_SIZE, ICON_POSITION } from "components/Icon";
import {
  APPLICANT_TYPES,
  AVAILABILITIES,
  CAPACITY_STATUS,
  CURRENCIES,
  LINKS,
  PAYMENT_FREQUENCIES,
} from "constants/index";
import {
  MUTATION_TYPE,
  useToast,
  useMissionRoleEligibility,
  useAuth,
  useProfile,
  useMissionRoleRecommendation,
} from "hooks";
import { colors } from "style";
import { formatDecimal } from "utils";

/**
 * Apply Button
 *
 * @param {Object}    role
 * @param {Object}    modalFormProps
 * @param {String}    profileId
 * @param {Boolean}   show
 * @param {Function}  onClose
 * @param {Function}  onLoading
 */
const ApplyButton = ({ role, modalFormProps, profileId, show, onClose, onLoading, ...props }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { canViewEditRate } = useAuth();
  const [profileRateData, setProfileRateData] = useState({});
  const [missionRoleId, setMissionRoleId] = useState(role?.uuid);
  const [roleRequirements, setRoleRequirements] = useState([]);
  const { isEligible, reason, loading } = useMissionRoleEligibility({
    profileId,
    missionRoleId,
    skip: !show,
  });
  const {
    rate,
    currency,
    averageExperience,
    applicantsCount,
    loading: recommendationLoading,
  } = useMissionRoleRecommendation({ profileId, missionRoleId, skip: !show || !canViewEditRate });
  const { loading: loadingProfile, data } = useProfile({ profileId });

  const initialValues = {
    ...(canViewEditRate ? { rate: profileRateData } : {}),
    profileId,
    state: APPLICANT_TYPES.pending,
  };

  const handleComplete = (data) => {
    toast.success(<FormattedMessage id={"Missions.MissionRole.ApplyForm.Success"} />);

    const missionRoleApplicant = data?.addMissionRoleApplicant?.missionRoleApplicant;
    navigate(LINKS.mission_application(missionRoleApplicant?.uuid));
  };

  const handleError = () => {
    onClose();
    toast.error(<FormattedMessage id="Missions.MissionRole.ApplyForm.Error" />);
  };

  useEffect(() => {
    setProfileRateData({
      frequency: PAYMENT_FREQUENCIES.hourly,
      currency: data?.profile?.rate?.currency,
    });
  }, [data?.profile?.rate]);

  useEffect(() => {
    setMissionRoleId(role?.uuid);
    setRoleRequirements(role?.mission?.missionRequirements ? role?.mission?.missionRequirements : []);
  }, [role?.uuid, role?.mission?.missionRequirements]);

  useEffect(() => {
    onLoading(loadingProfile || loading);
  }, [loading, loadingProfile]);

  // If member is not eligible to apply then show an error prompt
  if (!loading && !isEligible) {
    return (
      <Modal
        show={!loadingProfile && !loading && show}
        title={<FormattedMessage id="Missions.MissionRole.ApplyForm.TitleError" />}
        subTitle={role?.name}
        showSave={false}
        showCancel={true}
        secondaryButtonMessageId={"Global.Close"}
        size={MODAL_SIZE.medium}
        onClose={onClose}
      >
        <RoleApplyError isEligible={isEligible} loading={loading} reason={reason} role={role} />
      </Modal>
    );

    // ...otherwise show the apply form
  } else {
    return (
      <>
        <ModalForm
          loading={recommendationLoading}
          show={!loadingProfile && !loading && show}
          subTitle={role?.name}
          size={MODAL_SIZE.medium}
          onClose={onClose}
          data={FORM_DATA(
            [
              ...roleRequirements,
              ...[
                {
                  description: intl.formatMessage(
                    { id: "Missions.MissionRole.ApplyForm.Engagement" },
                    { engagement: role?.engagementTime }
                  ),
                },
              ],
            ],
            canViewEditRate,
            { rate, currency, averageExperience, applicantsCount, targetHours: role?.engagementTime }
          )}
          title={<FormattedMessage id="Missions.MissionRole.ApplyForm.Title" />}
          description={<FormattedMessage id="Missions.MissionRole.ApplyForm.Description" />}
          initialValues={initialValues}
          numColumns={4}
          parentKeyValue={missionRoleId}
          parentKeyName={"missionRoleId"}
          mutationType={MUTATION_TYPE.add}
          onCompleted={handleComplete}
          onError={handleError}
          disabled={loading || !isEligible}
          primaryButtonMessageId="Global.Continue"
          footer={<FormattedMessage id="Missions.MissionRole.ApplyForm.Footer" />}
          disclaimer={
            data?.profile?.capacityStatus === CAPACITY_STATUS.offDuty && (
              <div css={styles.disclaimerContainer}>
                <Icon type={ICON_TYPE.info} size={ICON_SIZE.medium} />
                <div css={styles.disclaimerText}>
                  <strong>
                    <FormattedMessage id="Missions.MissionRole.ApplyForm.Disclaimer.Strong" />
                  </strong>
                  <FormattedMessage id="Missions.MissionRole.ApplyForm.Disclaimer.Text" />
                </div>
              </div>
            )
          }
          mutationData={{
            mutationName: {
              add: ADD_MISSION_ROLE_APPLICANT,
            },
          }}
          {...props}
          {...modalFormProps}
        />
      </>
    );
  }
};

const RATE_RECOMMENDATION = (rateRecommendation) =>
  rateRecommendation?.rate
    ? [
        {
          description: <RoleEarningPotential {...rateRecommendation} />,
          type: TYPE.p,
        },
      ]
    : [];

const OPTIONS_RATE = (rateRecommendation) => [
  ...RATE_RECOMMENDATION(rateRecommendation),
  {
    label: "Hourly Rate",
    type: TYPE.input,
    size: SIZE.medium,
    parentObjectKey: "rate",
    valueFunc: (val) => formatDecimal(val),
    properties: {
      name: "amount",
      type: "number",
      required: true,
      min: 1,
      step: 0.01,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.dollarBill,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
  {
    label: "Currency",
    type: TYPE.select,
    size: SIZE.small,
    parentObjectKey: "rate",
    properties: {
      name: "currency",
      required: true,
      options: CURRENCIES,
    },
    decorator: {
      type: DECORATORS.icon,
      props: {
        type: ICON_TYPE.coin,
        size: ICON_SIZE.medium,
        position: ICON_POSITION.overlay,
      },
    },
  },
];

const OPTION_APPLICATION_TITLE = [
  {
    label: <FormattedMessage id="Missions.MissionRole.ApplyForm.ApplicationLabel" />,
    type: TYPE.h4,
    size: SIZE.xlarge,
  },
];

const OPTION_AVAILABILITIES_AND_MESSAGE = () => [
  {
    label: <FormattedMessage id="Missions.MissionRole.ApplyForm.TimeBeforeStarting" />,
    type: TYPE.select,
    size: SIZE.xlarge,
    properties: {
      name: "noticePeriod",
      required: true,
      options: AVAILABILITIES,
    },
  },
  {
    label: <FormattedMessage id="Missions.MissionRole.ApplyForm.AvailablePartTimeDuringNotice" />,
    type: TYPE.select,
    size: SIZE.xlarge,
    properties: {
      name: "availablePartTimeDuringNotice",
      required: true,
      options: [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ],
    },
  },
  {
    type: TYPE.checkbox,
    properties: {
      name: "terms",
      label: (
        <span css={styles.terms}>
          <FormattedMessage id="Missions.MissionRole.ApplyForm.Terms" />
        </span>
      ),
      required: true,
    },
  },
  {
    type: TYPE.hidden,
    properties: {
      name: "profileId",
    },
  },
];

const OPTION_REQUIREMENTS = (options) => [
  {
    label: <FormattedMessage id="Missions.MissionRole.ApplyForm.FormLabel" />,
    type: TYPE.h4,
    size: SIZE.xlarge,
  },
  {
    type: TYPE.checkBoxGroup,
    size: SIZE.xlarge,
    properties: {
      name: "requirements",
      options,
      valueKey: "id",
      labelKey: "description",
      requiredAll: true,
    },
  },
];

const FORM_DATA = (options, canViewEditRate, rateRecommendation) => [
  {
    style: css`
      width: 100%;
    `,
    items:
      options?.length > 0
        ? [
            ...OPTION_REQUIREMENTS(options),
            ...OPTION_APPLICATION_TITLE,
            ...(canViewEditRate ? OPTIONS_RATE(rateRecommendation) : []),
            ...OPTION_AVAILABILITIES_AND_MESSAGE(),
          ]
        : [
            ...OPTION_APPLICATION_TITLE,
            ...(canViewEditRate ? OPTIONS_RATE(rateRecommendation) : []),
            ...OPTION_AVAILABILITIES_AND_MESSAGE(),
          ],
  },
];

const styles = {
  terms: css`
    font-weight: 500;
    color: ${colors.red};
  `,
  disclaimerContainer: css`
    margin-top: 16px;
    padding: 12px;
    border-radius: 8px;
    background-color: ${colors.grayAnatomyLight5};
    display: flex;
    align-items: flex-start;
    gap: 8px;
  `,
  disclaimerText: css`
    font-size: 14px;
    line-height: 1.6;

    strong {
      color: ${colors.red};
    }
  `,
};

ApplyButton.propTypes = {
  role: PropTypes.object,
  modalFormProps: PropTypes.string,
  profileId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onLoading: PropTypes.func,
};

export default ApplyButton;
