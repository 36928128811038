import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { breakpoints, colors, fontSize, margins } from "style";
import { PrimaryLink } from "components/Links";
import { withIcon, ICON_TYPE, ICON_SIZE, EditIcon } from "components/Icon";
import { FormattedMessage } from "react-intl";
import { TextLoader } from "components/Loader";
import { getLegacyCountryCode } from "utils";
import { withModalForm, MODAL_SIZE } from "components/Modal";
import { Editor, SIZE, TYPE } from "components/Form";
import { UPDATE_PROFILE } from "graphql/mutations";
import { EditButton } from "components/Buttons";
import { Paragraph } from "components/Text";
import { LINKS, PAGES } from "constants/index";
import { useAuth } from "hooks";
import { convertFromPlainText, getPlainText } from "components/Form/Editor";

/**
 * ProfileDescription
 *
 * @param {Object}   data
 * @param {Boolean}  loading
 * @param {Boolean}  isEditable
 * @param {Function} onEdit
 * @param {Boolean}  isEmpty
 * @param {String}   marginSize
 * @param {Boolean}  hideProfileLink
 */
const ProfileDescription = ({ data, loading, isEditable, onEdit, isEmpty, marginSize, hideProfileLink }) => {
  const { isAdmin } = useAuth();

  const handleOnEdit = () =>
    onEdit(
      {
        firstName: data?.firstName,
        lastName: data?.lastName,
        description: data?.description,
        descriptionJson: data?.descriptionJson ?? convertFromPlainText(data?.description),
        location: {
          city: data?.location?.city || data?.oldLocation.city,
          state: data?.location?.state || data?.oldLocation.state,
          country: data?.location?.country || data?.oldLocation.country,
          country_code: data?.location?.country_code || getLegacyCountryCode(data?.oldLocation.country),
          country_flag: data?.location?.country_flag || data?.oldLocation.countryFlag,
          latitude: data?.location?.latitude,
          longitude: data?.location?.longitude,
          utc_offset: data?.location?.utc_offset,
          label: data?.location?.label || `${data?.oldLocation.city}, ${data?.oldLocation.country}`,
          place_id: data?.location?.place_id,
        },
      },
      data.id
    );

  const PublicLink = () => {
    if ((!loading && !isEditable && !isAdmin) || hideProfileLink) return null;

    if (!loading && isEditable) {
      return (
        <LinkProfile css={styles.primary_link} href={LINKS.public_profile(data.uuid)} target="_blank" withMoreIcon>
          <FormattedMessage id="Profile.ProfileBio.ViewProfileLink" />
        </LinkProfile>
      );
    } else if (!loading && !isEditable && isAdmin) {
      return (
        <LinkProfile css={styles.primary_link} href={LINKS.private_profile(data.slug)} withMoreIcon routerEnabled>
          <FormattedMessage id="Profile.ProfileBio.ViewPrivateLink" />
        </LinkProfile>
      );
    }
  };

  if (loading) {
    return <PlaceHolder />;
  } else if (isEmpty && isEditable) {
    return (
      <>
        <EditButton onClick={handleOnEdit}>
          <FormattedMessage id="Profile.ProfileBio.AddDescription" />
        </EditButton>
        <PublicLink />
      </>
    );
  }

  const Edit = () => isEditable && <EditIcon css={styles.edit_icon(marginSize)} onClick={handleOnEdit} />;

  return (
    <div css={styles.description}>
      <Edit />
      {data?.descriptionJson ? (
        <Editor value={data?.descriptionJson} css={styles.body} readOnly={true} />
      ) : (
        <Paragraph data={data?.description} />
      )}
      <PublicLink />
    </div>
  );
};

const LinkProfile = withIcon(PrimaryLink, {
  type: ICON_TYPE.eye,
  size: ICON_SIZE.medium,
  color: "inherit",
});

const PlaceHolder = () => (
  <div css={styles.description}>
    <TextLoader
      css={css`
        width: 30rem;
      `}
    />
    <TextLoader
      css={css`
        width: 35rem;
      `}
    />
    <TextLoader
      css={css`
        width: 32rem;
      `}
    />
    <TextLoader
      css={css`
        width: 10rem;
      `}
    />
  </div>
);

const styles = {
  description: () => css`
    font-size: ${fontSize.normal};
    line-height: 1.5;
    color: ${colors.purpleRainDark2};
    position: relative;
    padding-left: 3rem;

    &::before {
      content: "";
      width: 0.8rem;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-image: radial-gradient(circle at 7% 5%, ${colors.purpleRainBase}, ${colors.orangeCountyBase} 100%);
      clip-path: polygon(100% 0, 100% calc(100% - 7px), 0 100%, 0 7px);
    }
  `,
  primary_link: css`
    font-size: ${fontSize.small};
    padding-top: 1rem;
    line-height: 1.3;
  `,
  icon: css`
    color: inherit;
    font-size: ${fontSize.xlarge};
  `,
  edit_icon: (marginSize) => css`
    position: absolute;
    left: -${marginSize};
    margin-left: -2rem;

    @media (max-width: ${breakpoints.mobile}) {
      margin-left: -0.5rem;
      width: 3rem;
      height: 3rem;
      box-shadow: none;
    }
  `,
  body: css`
    margin: 0;
    padding: 0;
    border: 0;
    box-shadow: none;
  `,
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "First Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "firstName",
          type: "text",
          maxLength: 255,
          required: true,
        },
      },
      {
        label: "Last Name",
        type: TYPE.input,
        size: SIZE.medium,
        properties: {
          name: "lastName",
          type: "text",
          maxLength: 255,
          required: true,
        },
      },
      {
        type: TYPE.geoLocator,
        size: SIZE.xlarge,
        properties: {
          name: "location",
          type: "text",
        },
      },
      {
        label: "Short bio",
        type: TYPE.editor,
        size: SIZE.xlarge,
        onChange: (name, value, handleOnChange) => {
          handleOnChange("description", getPlainText(value));
          handleOnChange(name, value);
        },
        properties: {
          name: "descriptionJson",
          maxLength: 3000,
          required: true,
          placeholder: "Describe this mission for prospective squad members",
          showFormattingOptions: true,
        },
      },
    ],
  },
];

const FORM_SETTINGS = {
  size: MODAL_SIZE.medium,
  data: FORM_DATA,
  title: <FormattedMessage id="Profile.ProfileDescription.FormTitle" />,
  description: <FormattedMessage id="Profile.ProfileDescription.FormDescription" />,
  mutationData: {
    mutationName: {
      add: UPDATE_PROFILE,
      update: UPDATE_PROFILE,
    },
  },
  page: PAGES.addShortBio,
};

ProfileDescription.defaultProps = {
  marginSize: margins.normal,
};

ProfileDescription.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
  isEmpty: PropTypes.bool,
  marginSize: PropTypes.string,
  hideProfileLink: PropTypes.bool,
};

export default withModalForm(ProfileDescription, FORM_SETTINGS);
