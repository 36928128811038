import { gql } from "@apollo/client";
import { CORE_MISSION_ROLE_FIELDS } from "graphql/fragments";

const ADD_MISSION_ROLE = gql`
  ${CORE_MISSION_ROLE_FIELDS}
  mutation AddMissionRole($input: AddMissionRoleInput!) {
    addMissionRole(input: $input) {
      missionRole {
        ...MissionRoleFields
      }
    }
  }
`;

const UPDATE_MISSION_ROLE = gql`
  ${CORE_MISSION_ROLE_FIELDS}
  mutation UpdateMissionRole($input: UpdateMissionRoleInput!) {
    updateMissionRole(input: $input) {
      missionRole {
        ...MissionRoleFields
      }
    }
  }
`;

const CLOSE_MISSION_ROLE = gql`
  ${CORE_MISSION_ROLE_FIELDS}
  mutation CloseMissionRole($input: CloseMissionRoleInput!) {
    closeMissionRole(input: $input) {
      missionRole {
        ...MissionRoleFields
      }
    }
  }
`;

export { ADD_MISSION_ROLE, UPDATE_MISSION_ROLE, CLOSE_MISSION_ROLE };
