import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { SIZE, TYPE } from "components/Form";
import { MUTATION_TYPE, useToast } from "hooks";
import { MODAL_SIZE, ModalForm } from "components/Modal";
import { UPDATE_MISSION_ROLE } from "graphql/mutations";
import { FormattedMessage } from "react-intl";
import { PAGES } from "constants/index";

/**
 * PublicViewSettingForm
 *
 * @param {Object}    data
 * @param {Boolean}   show
 * @param {Function}  onClose
 */
const PublicViewSettingForm = ({ data, show, onClose }) => {
  const { toast } = useToast();
  const initialValues = {
    publicViewSettings: {
      videoLink: data?.publicViewSettings?.videoLink,
      videoLinkEnabled: !!data?.publicViewSettings?.videoLinkEnabled,
    },
  };
  const formProps = {
    initialValues,
    updateId: data?.id,
    mutationType: MUTATION_TYPE.update,
    mutationData: {
      mutationName: {
        update: UPDATE_MISSION_ROLE,
      },
    },
  };

  const handleComplete = () => toast.success(<FormattedMessage id="Mission.PublicViewSettingsForm.EditSuccess" />);

  return (
    <ModalForm
      data={FORM_DATA}
      show={show}
      size={MODAL_SIZE.large}
      onClose={onClose}
      title={<FormattedMessage id="Mission.PublicViewSettingsForm.FormTitle" />}
      description={<FormattedMessage id="Mission.PublicViewSettingsForm.FormDescription" />}
      page={PAGES.editMissionRolePublicViewSettings}
      onCompleted={handleComplete}
      {...formProps}
    />
  );
};

const FORM_DATA = [
  {
    style: css`
      width: 100%;
    `,
    items: [
      {
        label: "Featured Video",
        type: TYPE.checkbox,
        size: SIZE.xlarge,
        parentObjectKey: "publicViewSettings",
        properties: {
          required: true,
          name: "videoLinkEnabled",
          label: "Enabled",
        },
      },
      {
        type: TYPE.input,
        size: SIZE.medium,
        disabledCondition: {
          fieldParentKey: "publicViewSettings",
          field: "videoLinkEnabled",
          value: false,
        },
        parentObjectKey: "publicViewSettings",
        properties: {
          required: true,
          name: "videoLink",
          type: "url",
          placeholder: "Link",
        },
      },
    ],
  },
];

PublicViewSettingForm.propTypes = {
  data: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PublicViewSettingForm;
