const ENGAGEMENT_TYPES = {
  dedicated: "DEDICATED",
  shared: "SHARED",
  on_demand: "ON_DEMAND",
  full_time_employment: "FULL_TIME_EMPLOYMENT",
  part_time_employment: "PART_TIME_EMPLOYMENT",
  contract_to_hire: "CONTRACT_TO_HIRE",
};

const ENGAGEMENT_TYPES_OPTIONS = [
  { value: ENGAGEMENT_TYPES.dedicated, label: "Full-Time Contract" },
  { value: ENGAGEMENT_TYPES.shared, label: "Part-Time Contract" },
  { value: ENGAGEMENT_TYPES.on_demand, label: "On-Demand Contract" },
  { value: ENGAGEMENT_TYPES.full_time_employment, label: "Full-Time Employment" },
  { value: ENGAGEMENT_TYPES.part_time_employment, label: "Part-Time Employment" },
  { value: ENGAGEMENT_TYPES.contract_to_hire, label: "Contract to Hire" },
];

export { ENGAGEMENT_TYPES };

export default ENGAGEMENT_TYPES_OPTIONS;
