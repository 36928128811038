import React from "react";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import {
  ProfileBox,
  ProfileProgress,
  ProfileAdmin,
  ProfileAdminBar,
  ProfileCodeChallenge,
  ProfileNav,
  ProfilePreferences,
  useRenderProfileAdmin,
} from "components/Profile";
import {
  CandidateApprovedBy,
  useRenderCandidateApprovedBy,
  CandidateProgress,
  CandidateInterviewList,
} from "components/Candidate";
import { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import { SquadsHighlight } from "components/Squads";
import { PageWrapper, Grid } from "components/Containers";
import Card from "components/Card";
import { useAuth, useProfile } from "hooks";
import { MEMBER_STATUS } from "constants";

/**
 * Profile Page
 */
const Profile = () => {
  const { id } = useParams();
  const { data } = useProfile({ id });
  const { isAdmin, isPartner, isRecruiter } = useAuth();
  const intl = useIntl();
  const shouldRenderCandidateApprovedBy = useRenderCandidateApprovedBy(id);
  const shouldRenderProfileAdmin = useRenderProfileAdmin(id);
  const isActiveMember = MEMBER_STATUS.active === data?.profile?.status;
  const isRejectedCandidate = MEMBER_STATUS.rejected === data?.profile?.status;
  const isArchived = MEMBER_STATUS.archived === data?.profile?.status;

  return (
    <>
      <ProfileNav id={id} />
      <PageWrapper>
        <Helmet>
          <title>{intl.formatMessage({ id: "Meta.Profiles.Title" })}</title>
        </Helmet>
        <Grid topMargin>
          <Grid.col start={1} end={9}>
            <ProfileBox id={id} isEditable={true} />
          </Grid.col>
          <Grid.col start={9} end={13}>
            <Grid>
              {shouldRenderCandidateApprovedBy && (
                <Grid.col start={1} end={13}>
                  <CandidateApprovedBy profileId={id} />
                </Grid.col>
              )}
              {shouldRenderProfileAdmin && (
                <Grid.col start={1} end={13}>
                  <ProfileAdmin profileId={id} />
                </Grid.col>
              )}
              {isRecruiter && (
                <>
                  <Grid.col start={1} end={13}>
                    <Card isAdmin>
                      <CandidateProgress
                        profileId={id}
                        isActiveMember={isActiveMember}
                        isArchived={isArchived}
                        isRejected={isRejectedCandidate}
                        title={"Profile.ProfileAdmin.CandidateProgressTitle"}
                      />
                    </Card>
                  </Grid.col>
                  <Grid.col start={1} end={13}>
                    <Card>
                      <ProfileCodeChallenge
                        description={false}
                        size={RENDER_SIZE.small}
                        profileId={id}
                        canView={isRecruiter}
                        isEditable={true}
                        canAdd={true}
                      />
                    </Card>
                  </Grid.col>
                  <Grid.col start={1} end={13}>
                    <Card>
                      <CandidateInterviewList description={false} subtitle={false} profileId={id} />
                    </Card>
                  </Grid.col>
                </>
              )}
              {isPartner && (
                <Grid.col start={1} end={13}>
                  <Card>
                    <CandidateProgress profileId={id} />
                  </Card>
                </Grid.col>
              )}
              <Grid.col start={1} end={13}>
                <Card>
                  <ProfileProgress id={id} />
                </Card>
              </Grid.col>
              <Grid.col start={1} end={13}>
                <Card>
                  <ProfilePreferences id={id} />
                </Card>
              </Grid.col>
              <Grid.col start={1} end={13}>
                <Card>
                  <SquadsHighlight profileId={id} />
                </Card>
              </Grid.col>
            </Grid>
          </Grid.col>
        </Grid>
        {isAdmin && <ProfileAdminBar profileId={id} />}
      </PageWrapper>
    </>
  );
};

export default Profile;
