import { gql } from "@apollo/client";
import {
  APPLICANT_RATE_FIELDS,
  CHALLENGE_RESPONSES_FIELDS,
  MINIMAL_MISSION_ROLE_FIELDS,
  MINIMAL_PROFILE_FIELDS,
} from "graphql/fragments";

const GET_MISSION_ROLE_APPLICANT = gql`
  ${APPLICANT_RATE_FIELDS}
  ${MINIMAL_PROFILE_FIELDS}
  ${MINIMAL_MISSION_ROLE_FIELDS}
  ${CHALLENGE_RESPONSES_FIELDS}
  query missionRoleApplicant(
    $id: ID!
    $profileChallengesStatus: ChallengeResponseStatus
    $profileChallengesOrderBy: ChallengeResponseOrderBy
  ) {
    missionRoleApplicant(id: $id) {
      id
      state
      appliedAt
      noticePeriod
      availablePartTimeDuringNotice
      applicationChecklist
      negotiatedRate {
        amount
        currency
        frequency
      }
      recommendedRate {
        amount
        currency
        frequency
      }
      rateScore
      matchScore
      subScores
      privateNotes
      privateNotesJson
      challengesCompletedByProfile {
        ...ChallengeResponseFields
      }
      profile {
        ...MinimalProfileFields
        completedInterviewsCount
        id
        slug
        archetype
        memberSince
        email
        allTimeAssignmentsCount
        disciplines {
          id
          name
        }
        skills {
          id
          name
        }
        challengeResponses(status: $profileChallengesStatus, orderBy: $profileChallengesOrderBy) {
          ...ChallengeResponseFields
        }
      }
      missionRole {
        ...MinimalMissionRoleFields
        id
        assessmentId
        archetype
        disciplines {
          id
          name
        }
        primarySkills {
          id
          name
        }
        secondarySkills {
          id
          name
        }
        locations {
          id
          name
        }
      }
      ...ApplicantRateFields
    }
  }
`;

const GET_MISSION_ROLE_APPLICANT_FOR_MEMBER = gql`
  query missionRoleApplicant($id: ID!) {
    missionRoleApplicant(id: $id) {
      id
      state
      appliedAt
      noticePeriod
      availablePartTimeDuringNotice
      applicationChecklist
      rate {
        amount
        currency
        frequency
      }
      recommendedRate {
        amount
        currency
        frequency
      }
      profile {
        id
        slug
      }
      missionRole {
        slug
        name
        id
        assessmentId
        workArrangement
        archetype
        engagementTime
        challenges {
          id
        }
        timezone {
          name
          value
        }
        mission {
          uuid
          name
          companyDescription
          companyDescriptionJson
          isOrganizationConfidential
          organization {
            name
          }
        }
        disciplines {
          id
          name
        }
        primarySkills {
          id
          name
        }
        secondarySkills {
          id
          name
        }
        locations {
          id
          name
        }
      }
    }
  }
`;

export { GET_MISSION_ROLE_APPLICANT, GET_MISSION_ROLE_APPLICANT_FOR_MEMBER };
