import React from "react";
import { Helmet } from "react-helmet";
import { useOutletContext } from "react-router";
import { useIntl } from "react-intl";
import { Grid } from "components/Containers";
import Card from "components/Card";
import {
  CandidateProfileStatus,
  CandidateTitle,
  CandidateCodeChallenge,
  CandidateInterview,
} from "components/Candidate";
import { ApplicationForm } from "components/Missions/Applicants";

/**
 * Home
 */
const Home = () => {
  const intl = useIntl();
  const { application, loadingApplication, profileId, hasCodeChallengeRequirement } = useOutletContext();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Candidate.Home.Title" })}</title>
      </Helmet>
      <Grid>
        <Grid.col start={1} end={13}>
          <CandidateTitle
            title={`Candidate.Home.Title${application ? "Application" : ""}`}
            description="Candidate.Home.Body"
            profileId={profileId}
          />
        </Grid.col>
        {application && (
          <>
            <Grid.col start={1} end={13}>
              <ApplicationForm
                selected={application}
                loading={loadingApplication}
                hideProgress
                hideRequirements
                hideDetails
              />
            </Grid.col>
          </>
        )}
        {!hasCodeChallengeRequirement && (
          <Grid.col start={1} end={13}>
            <Card>
              <CandidateCodeChallenge profileId={profileId} />
            </Card>
          </Grid.col>
        )}
        <Grid.col start={1} end={13}>
          <Card>
            <CandidateInterview profileId={profileId} />
          </Card>
        </Grid.col>
        <Grid.col start={1} end={13}>
          <Card>
            <CandidateProfileStatus />
          </Card>
        </Grid.col>
      </Grid>
    </>
  );
};

export default Home;
