/** @jsx jsx */
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { breakpoints } from "style";

/**
 * A grid styled component that defines 12 equal width columns
 *
 * @param {Number} cols - number of cols, defaults to 12
 * @param {Number} rows - number of rows, defaults to 1
 * @param {String} gap - gap size
 */
const Grid = styled.div`
  display: grid;
  grid-column-gap: ${({ gap }) => gap};
  grid-row-gap: ${({ rowGap }) => rowGap};
  grid-template-columns: repeat(${({ cols }) => cols}, minmax(0, 1fr));
  margin-top: ${({ topMargin }) => (topMargin ? `2rem` : `0`)};
  margin-bottom: ${({ bottomMargin }) => (bottomMargin ? `2rem` : `0`)};

  @media (max-width: ${breakpoints.portrait}) {
    display: flex;
    flex-direction: column;
  }
`;

Grid.defaultProps = {
  rows: 1,
  cols: 12,
  gap: "3rem",
  rowGap: "2rem",
};

/**
 * Columns for the grid
 *
 * @param {number} start     grid-column-start
 * @param {number} end       grid-column-end
 * @param {number} row       display on which row, defaults to 1
 * @param {number} rowSpan   span how many rows, defaults to 1
 */
const Col = styled.div`
  flex: 1 0 0%;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  ${({ alignCenter }) =>
    alignCenter &&
    css`
      display: flex;
      align-items: center;
    `}

  ${({ auto }) =>
    auto &&
    css`
      flex: 0 0 auto;
      width: auto;
    `}

  ${({ start, end }) =>
    (start || end) &&
    css`
      grid-column: ${start} / ${end};
    `}

  ${({ row, rowSpan }) =>
    row &&
    rowSpan &&
    css`
      grid-row: ${row} / span ${rowSpan};
    `}
    
  ${({ colSpan }) =>
    colSpan &&
    css`
      grid-column: span ${colSpan};
    `}
`;

Grid.col = Col;

export default Grid;
