import React from "react";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import DisplayBox from "components/DisplayBox";
import RenderIconWithText, { RENDER_SIZE } from "components/DisplayBox/Renderers/RenderIconWithText";
import { ICON_SIZE, ICON_TYPE, StatusIcon } from "components/Icon";
import { colors, margins } from "style";

/**
 * Progress
 *
 * @param {Array} steps
 * @param {String} title
 */
const Progress = ({ steps, title, size, ...props }) => (
  <DisplayBox
    title={<FormattedMessage id={title} />}
    numColumns={1}
    rowGap={0}
    marginSize={margins.none}
    data={steps?.map(({ title, label, isComplete, ...rest }) => ({
      title: title || (
        <span css={styles.label(isComplete)}>
          <FormattedMessage id={label} />
        </span>
      ),
      isComplete,
      ...rest,
    }))}
    itemRenderer={({ isComplete, onClick, children, selected, ...text }) => (
      <>
        <RenderIconWithText
          size={size}
          icon={
            <StatusIcon
              type={ICON_TYPE.line}
              size={ICON_SIZE.large}
              color={colors.grayAnatomyLight2}
              isComplete={isComplete}
            />
          }
          onClick={onClick}
          text={text}
        />
        {children && selected && <div css={styles.children}>{children}</div>}
      </>
    )}
    {...props}
  />
);

const styles = {
  label: (isComplete) => css`
    font-weight: ${isComplete ? `500` : `400`};
    color: ${colors.purpleRainDark2};
  `,
  children: css`
    margin-left: 4.2rem;
    border-bottom: 1px ${colors.grayAnatomyLight4} solid;
  `,
};

Progress.propTypes = {
  size: PropTypes.oneOf(Object.values(RENDER_SIZE)),
  steps: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

Progress.defaultProps = {
  size: RENDER_SIZE.small,
};

export default Progress;
