import React from "react";
import { css } from "@emotion/react";
import { useAuth, useModalConfirmation, useQueryData, useToast } from "hooks";
import { FormattedMessage } from "react-intl";
import {
  GET_MISSION_ROLE_APPLICANT,
  GET_MISSION_ROLE_APPLICANT_FOR_MEMBER,
  GET_MISSION_ROLE_APPLICANTS_COUNT,
} from "graphql/queries";
import { useMutation } from "@apollo/client";
import { SUBMIT_APPLICATION, UPDATE_APPLICANT, UPDATE_APPLICANT_STATE } from "graphql/mutations";
import { APPLICATION_STEPS, CODE_CHALLENGE_STATUS, SORT } from "constants/index";

export default function useMissionRoleApplicant({ id }) {
  const { toast } = useToast();
  const { isAdmin, isCandidate } = useAuth();
  const { show } = useModalConfirmation();

  const { loading, data, error } = useQueryData({
    queryName: isAdmin ? GET_MISSION_ROLE_APPLICANT : GET_MISSION_ROLE_APPLICANT_FOR_MEMBER,
    skip: !id,
    variables: {
      id,
      profileChallengesStatus: CODE_CHALLENGE_STATUS.completed,
      profileChallengesOrderBy: { completedAt: SORT.desc },
    },
  });

  const [handleUpdateApplicant, { loading: updating }] = useMutation(UPDATE_APPLICANT);
  const [handleSubmitApplication, { loading: submitting }] = useMutation(SUBMIT_APPLICATION);
  const [handleUpdateApplicantState, { loading: updatingState }] = useMutation(UPDATE_APPLICANT_STATE);

  const updateApplicant = (id, params) => {
    handleUpdateApplicant({
      variables: {
        input: {
          id,
          params,
        },
      },
      optimisticResponse: () => ({
        updateMissionRoleApplicant: {
          missionRoleApplicant: {
            id: data?.missionRoleApplicant?.id,
            ...params,
            __typename: "MissionRoleApplicant",
          },
          __typename: "UpdateMissionRoleApplicantPayload",
        },
      }),
    });
  };

  const updateApplicantState = (id, state) => {
    handleUpdateApplicantState({
      variables: {
        input: {
          id,
          state,
        },
      },
    });
  };

  const submitApplication = async (id) => {
    const response = await show(
      <FormattedMessage id="Missions.Applicants.ApplicationSubmitButton.CTA" />,
      <>
        <FormattedMessage id="Missions.Applicants.ApplicationSubmitModal.Description1" />
        <div css={styles.confirm}>
          <FormattedMessage id="Missions.Applicants.ApplicationSubmitModal.Description2" />
        </div>
      </>,
      {
        primaryButtonMessageId: "Global.Submit",
      }
    );

    if (!response) return;

    handleSubmitApplication({
      variables: {
        input: {
          id,
          submitProfile: isCandidate,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="Missions.Applicants.ApplicationSubmitToast.Success" />),
      onError: () => toast.error(<FormattedMessage id="Missions.Applicants.ApplicationSubmitToast.Error" />),
    });
  };

  const getApplicationSteps = (checklist) => {
    return isCandidate
      ? checklist?.filter(({ name }) =>
          [
            APPLICATION_STEPS.application_rate,
            APPLICATION_STEPS.questionnaire,
            APPLICATION_STEPS.code_challenge,
            APPLICATION_STEPS.video_interview,
          ].includes(name)
        )
      : checklist;
  };

  const {
    loading: loadingApplicants,
    data: dataApplicants,
    error: errorApplicants,
  } = useQueryData({
    queryName: GET_MISSION_ROLE_APPLICANTS_COUNT,
    skip: !data?.missionRoleApplicant?.profile?.id,
    variables: {
      filters: {
        profileId: data?.missionRoleApplicant?.profile?.id,
        excludingMissionRoleId: data?.missionRoleApplicant?.missionRole?.id,
      },
    },
  });

  const styles = {
    confirm: css`
      margin-top: 1rem;
      font-weight: 600;
    `,
  };

  return {
    loading,
    data: data?.missionRoleApplicant,
    updateApplicant,
    updating,
    submitApplication,
    submitting,
    getApplicationSteps,
    updateApplicantState,
    updatingState,
    list: {
      loading: loadingApplicants,
      data: dataApplicants,
      error: errorApplicants,
    },
    error,
  };
}
