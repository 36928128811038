import React from "react";
import { FormattedMessage } from "react-intl";
import { useQueryData, useToast } from "hooks";
import { GET_ASSESSMENT } from "graphql/queries";
import { useMutation } from "@apollo/client";
import { DETACH_MISSION_ROLE_ASSESSMENT_QUESTION } from "graphql/mutations";

export default function useAssessment({ id, resourceType, resourceId, allowedId, skip, skipAnswers = false }) {
  const keyName = "assessment";
  const { toast } = useToast();
  const { loading, data, error, refetch } = useQueryData({
    queryName: GET_ASSESSMENT,
    keyName,
    skip: !id || skip,
    variables: {
      id,
      allowedId,
      resourceType,
      resourceId,
      skipAnswers,
    },
  });

  const [removeQuestion, { loading: removingQuestion }] = useMutation(DETACH_MISSION_ROLE_ASSESSMENT_QUESTION);

  const handleRemoveQuestion = (assessmentId, questionId) => {
    removeQuestion({
      variables: {
        input: {
          assessmentId,
          questionId,
        },
      },
      onCompleted: () => toast.success(<FormattedMessage id="useAssessment.RemoveQuestionSuccess" />),
      onError: () => toast.error(<FormattedMessage id="useAssessment.RemoveQuestionError" />),
    });
  };

  return {
    data: data?.[keyName],
    loading,
    error,
    refetch,
    handleRemoveQuestion,
    removingQuestion,
  };
}
