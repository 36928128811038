const WORK_ARRANGEMENTS = {
  remote: "REMOTE",
  onsite: "ONSITE",
  hybrid: "HYBRID",
};

const WORK_ARRANGEMENT_OPTIONS = [
  { value: WORK_ARRANGEMENTS.remote, label: "Remote" },
  { value: WORK_ARRANGEMENTS.onsite, label: "On-Site" },
  { value: WORK_ARRANGEMENTS.hybrid, label: "Hybrid" },
];

export { WORK_ARRANGEMENTS };

export default WORK_ARRANGEMENT_OPTIONS;
