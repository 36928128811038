import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { FormattedMessage } from "react-intl";
import { formatCurrency } from "utils";
import { colors, fontSize } from "style";
import { Banner } from "components/Banner";
import { BANNER_TYPE } from "constants";

/**
 * RoleEarningPotential
 *
 * @param {Number}    applicantsCount
 * @param {Element}   parentComponent
 * @param {Number}    averageExperience
 * @param {String}    currency
 * @param {Number}    rate
 * @param {Number}    targetHours
 */
const RoleEarningPotential = ({ parentComponent, applicantsCount, averageExperience, currency, rate, targetHours }) => {
  const ParentComponent = parentComponent;

  return (
    <ParentComponent isClosable={false} type={BANNER_TYPE.warning}>
      <span css={styles.text}>
        <FormattedMessage
          id="Missions.MissionRole.ApplyForm.Disclaimer"
          values={{
            applicantsCount: (
              <b>
                <FormattedMessage
                  id="Missions.MissionRole.ApplyForm.Disclaimer.ApplicantsCount"
                  values={{ applicantsCount }}
                />
              </b>
            ),
            experience: (
              <b>
                <FormattedMessage
                  id="Missions.MissionRole.ApplyForm.Disclaimer.ExperienceYears"
                  values={{ averageExperience }}
                />
              </b>
            ),
            title: (
              <span css={styles.recommendationTitle}>
                <FormattedMessage id="Missions.MissionRole.ApplyForm.Disclaimer.Title" />
              </span>
            ),
            rate: (
              <FormattedMessage
                id="Missions.MissionRole.ApplyForm.Disclaimer.HourlyRate"
                values={{ rate: formatCurrency(Math.round(rate), currency, "code", false, 0) }}
              />
            ),
            // Multiplying by 52 to convert the weekly amount to yearly (1 year ~ 52 weeks)
            estimatedEarnings: formatCurrency(Math.round(rate * targetHours * 52), currency, "code", false, 0),
          }}
        />
      </span>
      <span css={styles.recommendationDescription}>
        <FormattedMessage id="Missions.MissionRole.ApplyForm.Disclaimer.Description" />
      </span>
    </ParentComponent>
  );
};

const styles = {
  text: css`
    color: ${colors.green};
  `,
  recommendationTitle: css`
    color: ${colors.black};
    font-weight: 500;
    font-size: ${fontSize.small};
  `,
  recommendationDescription: css`
    font-size: ${fontSize.small};
    line-height: normal;
  `,
};

RoleEarningPotential.defaultProps = {
  parentComponent: Banner,
};

RoleEarningPotential.propTypes = {
  parentComponent: PropTypes.element,
  applicantsCount: PropTypes.number,
  averageExperience: PropTypes.number,
  currency: PropTypes.string,
  rate: PropTypes.number,
  targetHours: PropTypes.number,
};

export default RoleEarningPotential;
