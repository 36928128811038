import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Helmet } from "react-helmet-async";
import { Grid, PageWrapper } from "components/Containers";
import { Banner, BannerShowcaseSignup } from "components/Banner";
import { useAuth, useMissionRoles, useTracking } from "hooks";
import { css } from "@emotion/react";
import { APPLICANT_LIST_COLUMNS, PAGES, TRACKING_PAGE_VIEWS } from "constants/index";
import { ApplicantListLayout } from "components/Missions/Applicants";
import PageTitle from "components/PageTitle";
import Card from "components/Card";
import NoResults from "components/Messages/NoResults";
import { Spinner } from "components/Loader";

/**
 * Applicants Page
 */
const Applicants = () => {
  const { isShowcase, isClient, canViewUnpublishedRoles } = useAuth();
  const { trackPage } = useTracking();
  const intl = useIntl();
  const [defaults, setDefaults] = useState({});
  const { data, loading } = useMissionRoles({ filters: { published: !canViewUnpublishedRoles } });

  /**
   * @description For showcase users, we need to set a default missionId, missionRoleId, and organizationId because
   * the filters are hidden
   */
  useEffect(() => {
    if (data?.length === 0 || loading) return;

    setDefaults({
      missionId: data?.[0].mission.id,
      missionRoleId: data?.[0].id,
      organizationId: data?.[0].mission.organization.id,
    });
  }, [data, loading]);

  useEffect(() => {
    trackPage(TRACKING_PAGE_VIEWS[PAGES.showcaseApplicants].page_name, TRACKING_PAGE_VIEWS[PAGES.showcaseApplicants]);
  }, []);

  return (
    <PageWrapper>
      <Helmet>
        <title>{intl.formatMessage({ id: "Meta.Missions.Applicants.Title" })}</title>
      </Helmet>
      <BannerShowcaseSignup
        css={css`
          margin: 4rem 0 1rem 0;
        `}
      />
      {isShowcase && (
        <Banner keyName="showClientApplicantsBanner">
          <FormattedMessage
            id="Applicants.Banner.Title1"
            values={{
              intro: (
                <b>
                  <FormattedMessage id="Applicants.Banner.Title2" />
                </b>
              ),
            }}
          />
        </Banner>
      )}
      <Grid>
        <Grid.col start={1} end={13}>
          <PageTitle title={<FormattedMessage id={`Missions.Applicants.H1`} />} />
        </Grid.col>
        <Grid.col start={1} end={13}>
          {defaults?.missionId && defaults?.missionRoleId ? (
            <ApplicantListLayout
              defaultSelected={defaults}
              showOnlyUnpublishedRoles={isClient || isShowcase}
              showAdvancedOptions={false}
              showAdminOnlyPages={false}
              initialProgressMenu={0}
              hideOrganizationFilter={isClient}
              hideMissionFilter={isClient}
              hideFilters={isShowcase}
              hideMemberFilter={true}
              columns={[
                APPLICANT_LIST_COLUMNS.member,
                APPLICANT_LIST_COLUMNS.mission,
                APPLICANT_LIST_COLUMNS.profileRate,
                APPLICANT_LIST_COLUMNS.hourlyRateRange,
                APPLICANT_LIST_COLUMNS.noticePeriod,
                APPLICANT_LIST_COLUMNS.profileAvailability,
                APPLICANT_LIST_COLUMNS.invited,
                APPLICANT_LIST_COLUMNS.applied,
                APPLICANT_LIST_COLUMNS.matchScore,
                APPLICANT_LIST_COLUMNS.matchScoreWithoutTooltip,
                APPLICANT_LIST_COLUMNS.rateScore,
              ]}
            />
          ) : !loading ? (
            <Card>
              <NoResults
                title={"Missions.Applicants.NoResultsTitle"}
                description={"Missions.Applicants.NoResultsDescription"}
              />
            </Card>
          ) : (
            <Card>
              <Spinner />
            </Card>
          )}
        </Grid.col>
      </Grid>
    </PageWrapper>
  );
};

export default Applicants;
