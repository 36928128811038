import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import { useAssessment, useFormData } from "hooks";
import { APPLICATION_STEPS } from "constants/index";
import { Grid } from "components/Containers";
import Card from "components/Card";
import DisplayBox from "components/DisplayBox";
import ApplicationQuestionnaireForm from "./ApplicationQuestionnaireForm";
import { TagComplete, TagIncomplete } from "components/Tags";

/**
 * ApplicationEditableQuestionnaire
 *
 * @param {Object}    missionRoleApplicant
 * @param {Boolean}   questionnaireEditing
 * @param {Function}  setQuestionnaireEditing
 */
const ApplicationEditableQuestionnaire = ({
  missionRoleApplicant,
  questionnaireEditing,
  setQuestionnaireEditing,
  ...props
}) => {
  const RESOURCE_TYPE = "open";
  const sectionCompleted = missionRoleApplicant?.applicationChecklist?.find(
    (step) => step.name === APPLICATION_STEPS.questionnaire
  )?.fulfilled;

  const missionRole = missionRoleApplicant?.missionRole;

  const { data, loading } = useFormData(
    {
      variables: {
        resourceType: RESOURCE_TYPE,
        id: missionRole?.assessmentId,
        allowedId: missionRoleApplicant?.profile?.id,
      },
    },
    "questions"
  );

  const {
    data: answersData,
    loading: answersLoading,
    refetch,
  } = useAssessment({
    id: missionRole?.assessmentId,
    allowedId: missionRoleApplicant?.profile?.id,
    resourceType: "member",
    resourceId: missionRoleApplicant?.profile?.id,
  });

  if (loading || answersLoading) return null;

  return (
    <>
      <Grid.col start={1} end={13}>
        <Card>
          <DisplayBox
            title={<FormattedMessage id="Missions.Applications.ApplicationEditableQuestionnaire.Title" />}
            description={<FormattedMessage id="Missions.Applications.ApplicationEditableQuestionnaire.Description" />}
            subtitle={!sectionCompleted ? <TagIncomplete /> : <TagComplete />}
            editButtonText={<FormattedMessage id="Missions.Applications.ApplicationEditableQuestionnaire.Button" />}
            isEmpty={!sectionCompleted}
            numColumns={1}
            data={DATA(missionRoleApplicant, answersData, data[0]?.items || [])}
            onEdit={() => setQuestionnaireEditing(true)}
            onAdd={() => setQuestionnaireEditing(true)}
            {...props}
          />
        </Card>
      </Grid.col>

      {questionnaireEditing && (
        <ApplicationQuestionnaireForm
          questionnaire={data}
          missionRoleApplicant={missionRoleApplicant}
          answers={answersData?.answers}
          refetch={refetch}
          show={questionnaireEditing}
          onClose={() => setQuestionnaireEditing(false)}
        />
      )}
    </>
  );
};

const DATA = (missionRoleApplicant, answersData, questionnaire) => [
  {
    title: <FormattedMessage id="Missions.MissionRole.ApplyForm.TimeBeforeStarting" />,
    body: <FormattedMessage id={`MissionRoleApplicant.NoticePeriod.${missionRoleApplicant.noticePeriod}`} />,
  },
  {
    title: <FormattedMessage id="Missions.MissionRole.ApplyForm.AvailablePartTimeDuringNotice" />,
    body: (
      <FormattedMessage
        id={`MissionRoleApplicant.QuestionnaireResponseBox.PartTimeDuringNotice.${missionRoleApplicant.availablePartTimeDuringNotice}`}
      />
    ),
  },
  ...questionnaire.map((item, index) => ({
    title: `Q${index + 3}: ${item.label}`,
    body: answersData?.answers[item.properties.name],
  })),
];

ApplicationEditableQuestionnaire.propTypes = {
  missionRoleApplicant: PropTypes.object.isRequired,
  questionnaireEditing: PropTypes.bool,
  setQuestionnaireEditing: PropTypes.func,
};

export default ApplicationEditableQuestionnaire;
